import React from "react";
import { Route, Redirect } from "react-router-dom";

import routes from "./routes.json";
import Routes from "./Routes";

import { allowedLanguages } from "../translations/locales";

const RootRoutes = (props: any) => {
  return (
    <>
      <Route
        path="/"
        exact
        render={() => {
          return <Redirect to={`/fi${routes.login}`} />;
        }}
      />
      <Route
        path={routes.root}
        render={(routeProps) => {
          const locale = routeProps.match?.params?.locale;
          if (!allowedLanguages.includes(locale)) {
            return <Redirect to={`/fi${routes.products}`} />;
          }
          return <Routes locale={locale} {...routeProps} />;
        }}
      />
    </>
  );
};

export default RootRoutes;
