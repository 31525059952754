import styled, { css } from "styled-components/macro";
import { Input, InputProps } from "@chakra-ui/core";

export type PrimaryInputProps = InputProps & {
  isHighlighted?: boolean;
  isOverlined?: boolean;
  hasError?: boolean;
};

const PrimaryInput = styled(Input)<PrimaryInputProps>`

  ${({ isReadOnly, theme }) =>
    isReadOnly &&
    css`
      &&,
      &&:focus,
      &&:hover,
      &&:read-only,
      &&:disabled {
        background-color: ${theme.colors.gray[50]};
      }
    `}
  ${({ isHighlighted, theme }) =>
    isHighlighted &&
    css`
      &&,
      &&:focus,
      &&:hover,
      &&:read-only,
      &&:disabled {
        background: ${theme.colors.green[50]};
        border-color: ${theme.colors.primary.regular};
        border-width: 1px;
        &:focus {
          border-width: 2px;
        }
      }
    `}
  ${({ isOverlined, theme }) =>
    isOverlined &&
    css`
      &&,
      &&:focus,
      &&:hover,
      &&:read-only,
      &&:disabled {
        color: ${theme.colors.red[600]};
        text-decoration: line-through;
      }
    `}
  
  ${({ hasError, theme }) =>
    hasError &&
    css`
      &&,
      &&:focus,
      &&:hover,
      &&:read-only,
      &&:disabled {
        color: ${theme.colors.black};
        border-color: ${theme.colors.red[600]};
        background-color: ${theme.colors.red[50]};
        text-decoration: none;
        border-width: 1px;
        &:focus {
          border-width: 2px;
        }
      }
    `}
`;
const FlushedInput = styled(Input)<InputProps>``;

const baseInputProps: InputProps = {
  fontSize: "sm",
  borderRadius: "sm",
};

PrimaryInput.defaultProps = {
  ...baseInputProps,
  _focus: {
    bg: "green.50",
    borderColor: "primary.regular",
    borderWidth: "2px",
  },
  _readOnly: {
    opacity: 1,
    pointerEvents: "none",
  },
};

FlushedInput.defaultProps = {
  ...baseInputProps,
  borderColor: "primary.regular",
  borderRadius: "none",
  borderLeft: "none",
  borderRight: "none",
  borderTop: "none",
  _focus: {
    borderColor: "primary.regular",
    borderWidth: "2px",
  },
};

export { PrimaryInput, FlushedInput };
