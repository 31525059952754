import React from "react";
import { Text, Spinner, Flex, FlexProps, SpinnerProps } from "@chakra-ui/core";
import { useIntl } from "react-intl";
import messages from "translations/commonMessages";

export type LoadingProps = FlexProps & {
  customLabel?: string;
  spinnerProps?: SpinnerProps;
};

//

const Loading = ({ customLabel, spinnerProps, ...rest }: LoadingProps) => {
  const intl = useIntl();
  return (
    <Flex align="center" color="primary.dark" fontSize="lg" {...rest}>
      <Text mr="3" color="inherit">
        {customLabel ? customLabel : intl.formatMessage(messages.loading)}
      </Text>
      <Spinner color="inherit" speed="0.7s" thickness="3px" {...spinnerProps} />
    </Flex>
  );
};

export default Loading;
