import React, { Fragment } from "react";
import { ApolloError } from "@apollo/client";
import { Text } from "@chakra-ui/core";
import { useIntl } from "react-intl";

import messages from "translations/commonMessages";
import Loading from "./primitives/Loading";

export interface HandleQueryProps {
  error?: ApolloError | string;
  loading?: boolean;
  handleLoading?: (loading: boolean) => React.ReactNode;
  handleError?: (error: any) => React.ReactNode;
  children?: React.ReactNode;
}

const HandleQuery = ({
  loading,
  handleLoading,
  handleError,
  error,
  children,
}: HandleQueryProps) => {
  const intl = useIntl();
  if (error) {
    if (handleError) {
      return <Fragment>{handleError(error)}</Fragment>;
    }
    return (
      <Text color="red.500" p="3">
        {intl.formatMessage(messages.errorOccurred, {
          error: typeof error === "string" ? error : error.message,
        })}
      </Text>
    );
  }
  if (loading) {
    if (handleLoading) {
      return <Fragment>{handleLoading(loading)}</Fragment>;
    }
    return <Loading p="4" />;
  }
  if (children) return <Fragment>{children}</Fragment>;
  return <Fragment />;
};

export default HandleQuery;
