import React, { useContext } from "react";
import { Switch, Route, Redirect, RouteComponentProps } from "react-router-dom";
import { IntlProvider, FormattedMessage } from "react-intl";

import GlobalStateContext from "../globalStateContext";
import Products from "../views/products/Products";
import NotFound from "../views/NotFound";
import Login from "views/Login";
import ViewContainer from "views/ViewContainer";
import NetworkErrorContext from "../networkErrorContext";
import Modal from "../components/Modal";

import routes from "./routes.json";
import translations, { AllowedLanguagesType } from "../translations/locales";
import FileImport from "views/file-import/FileImport";
import { Text } from "@chakra-ui/core";

type RoutesProps = {
  locale?: AllowedLanguagesType;
} & RouteComponentProps<any>;

const Routes = ({ locale = "fi", ...props }: RoutesProps) => {
  const globalState = useContext(GlobalStateContext);
  const { path } = props.match;
  const { networkError, resetError } = useContext(NetworkErrorContext);

  const isUserLoggedIn = () => {
    return !!localStorage.getItem("token");
  };

  return (
    // @ts-ignore
    <IntlProvider locale={locale} messages={translations[locale]}>
      <Modal
        isOpen={!!networkError}
        onClose={resetError}
        closeButtonLabel={
          <FormattedMessage id="modal.errorModalClose" defaultMessage="Close" />
        }
        title={
          <FormattedMessage
            id="modal.errorModalTitle"
            defaultMessage="Error occurred:"
          />
        }
      >
        {networkError && <Text color="red.500">{networkError.message}</Text>}
      </Modal>
      <Switch>
        <Route
          exact
          path={`/${locale}`}
          render={() => <Redirect to={`/${locale}${routes.products}`} />}
        />
        <Route
          path={`${path}${routes.login}`}
          render={() => {
            if (!isUserLoggedIn()) {
              return <Login />;
            } else return <Redirect to={`/${locale}${routes.products}`} />;
          }}
        />
        <Route
          path={`${path}${routes.logout}`}
          render={() => {
            globalState.logout();
            return <Redirect to={`/${locale}${routes.login}`} />;
          }}
        />
        <Route
          render={() => {
            if (!isUserLoggedIn()) {
              return <Redirect to={`/${locale}${routes.login}`} />;
            } else
              return (
                <ViewContainer>
                  <Switch>
                    <Route
                      path={`${path}${routes.product}`}
                      render={() => <Products />}
                    />
                    <Route
                      path={`${path}${routes.products}`}
                      render={() => <Products />}
                    />
                    <Route
                      path={`${path}${routes["file-import"]}`}
                      render={() => <FileImport />}
                    />
                    <Route path="*" render={() => <NotFound />} />
                  </Switch>
                </ViewContainer>
              );
          }}
        />
      </Switch>
    </IntlProvider>
  );
};

export default Routes;
