import React, { useEffect, useState } from "react";
import { AuthProvider } from "authProvider";
import ReactDOM from "react-dom";
import { ThemeProvider } from "@chakra-ui/core";
import { ThemeProvider as StyledThemeProvider } from "styled-components/macro";

import "./index.css";
import App from "./App";
import theme from "./theme";
import * as serviceWorker from "./serviceWorker";
import GlobalStateContext from "./globalStateContext";
import PravdaApolloProvider from "./apollo/PravdaApolloProvider";
import AzureAD, {
  IAzureADFunctionProps,
  AuthenticationState,
  MsalAuthProvider,
} from "react-aad-msal";

const Root = () => {
  const [globalState, setState] = useState<{
    currentUser?: string;
    loginError?: string;
    showSidebar: boolean;
  }>({
    // Uncomment this if you want to skip login view in development
    // currentUser: "mikko.mallikas@wihuri.com",
    showSidebar: true,
  });

  const [authInProgress, setAuthInProgress] = useState(false);
  const [authProvider, updateAuthProvider] = useState<MsalAuthProvider | null>(null);

  useEffect(() => {
    async function loadAuthConfig() {
      const authProviderFactory = new AuthProvider();
      authProviderFactory.initialize()
        .then(provider => updateAuthProvider(provider))
        .catch(err => console.error('Could not load config'));
    }
    loadAuthConfig();
  }, []);

  const skipLogin = () => {
    // Dont allow skipping login in production
    if (process.env.NODE_ENV !== "production") {
      globalState.currentUser = "mikko.mallikas@wihuri.com";
      localStorage.setItem("token", "123");
    }
  };

  // Uncomment this to skip login in development. NOTE: You also need to set requireAuth to false in backend/src/graphql/server.ts to skip auth in graphlql api
   //skipLogin();

  return authProvider && (
    <ThemeProvider theme={theme}>
      <StyledThemeProvider theme={theme}>
        <AzureAD provider={authProvider}>
          {(azureProps: IAzureADFunctionProps) => {
            const user = azureProps.accountInfo?.account.userName;

            if (
              azureProps.authenticationState ===
              AuthenticationState.Authenticated
            ) {
              if (!user) {
                globalState.loginError =
                  "Error in login: Azure did not return account email. Try logging in with different account";
              }
              // Update app user if it doesn't match with one returned from azure (= is different or is null)
              if (
                !authInProgress &&
                (user !== globalState.currentUser ||
                  !localStorage.getItem("token"))
              ) {
                // Set app auth state to be in progress, so differing azure auth state doesn't mess with app login
                setAuthInProgress(true);
                authProvider
                  .getAccessToken()
                  .then((token) => {
                    globalState.currentUser = user;
                    globalState.loginError = undefined;
                    localStorage.setItem("token", token.accessToken);
                  })
                  .catch((e) => {
                    globalState.loginError = `Something went wrong when fetching AzureAD token: ${e.message}`;
                  })
                  .finally(() => setAuthInProgress(false));
              }
            } else if (
              // This case means that either auth is not in progress and azure token has expired or azure returned an error
              !authInProgress &&
              azureProps.authenticationState ===
                AuthenticationState.Unauthenticated &&
              (!!user || !!localStorage.getItem("token"))
            ) {
              globalState.currentUser = undefined;
              localStorage.clear();
            }

            if (azureProps.error?.errorCode === "null_or_empty_id_token") {
              globalState.loginError =
                "Your login token has expired, please login again";
            }

            return (
              <GlobalStateContext.Provider
                value={{
                  ...globalState,
                  login: () => {
                    azureProps.login();
                  },
                  logout: () => {
                    localStorage.removeItem("token");
                    azureProps.logout();
                  },
                  setGlobalState: (newState: any) => {
                    setState({ ...globalState, ...newState });
                  },
                }}
              >
                <PravdaApolloProvider>
                  <App showSidebar={globalState.showSidebar} />
                </PravdaApolloProvider>
              </GlobalStateContext.Provider>
            );
          }}
        </AzureAD>
      </StyledThemeProvider>
    </ThemeProvider>
  );
};

ReactDOM.render(<Root />, document.getElementById("root"));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
