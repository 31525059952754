import React from "react";

interface INetworkErrorState {
  networkError: Error | undefined;
  readonly resetError: () => void;
}

const errorState: INetworkErrorState = {
  networkError: undefined,
  resetError: () => {},
};

const NetworkErrorContext = React.createContext(errorState);
export default NetworkErrorContext;
