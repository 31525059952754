import { MsalAuthProvider, LoginType } from "react-aad-msal";
import { Configuration } from "msal";

interface AzureConfigResponse {
  azureTenantId: string;
  azureClientId: string;
}

export class AuthProvider {
  private isProduction = process.env.NODE_ENV === "production";
  private createConfig(azureConfig: AzureConfigResponse): Configuration {
    return {
      auth: {
        authority: `https://login.microsoftonline.com/${azureConfig.azureTenantId}`,
        clientId: azureConfig.azureClientId,
        redirectUri: window.location.origin,
        postLogoutRedirectUri: window.location.origin + "/logout",
      },
      cache: {
        cacheLocation: "localStorage",
        storeAuthStateInCookie: false,
      }
    }
  };

  private createAuthenticationParameters(azureConfig: AzureConfigResponse) {
    return {
      scopes: [`api://${azureConfig.azureClientId}/gdsn.admin.auth`],
    }
  };

  private options = {
    loginType: LoginType.Redirect,
    tokenRefreshUri: window.location.origin,
  };

  initialize = async (): Promise<MsalAuthProvider> => {
    const uri = this.isProduction ? "/frontendconfig" : "http://localhost:4000/frontendconfig";
    const azureConfig: AzureConfigResponse = await (await fetch(uri)).json();
    const config = this.createConfig(azureConfig);
    const authenticationParameters = this.createAuthenticationParameters(azureConfig);
    return new MsalAuthProvider(
      config,
      authenticationParameters,
      this.options
    );
  }
}

