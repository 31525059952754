import React from "react";
import { Heading } from "@chakra-ui/core";
import { Link } from "react-router-dom";
import { FormattedMessage } from "react-intl";

const NotFound = () => (
  <div>
    <Heading as="h1" size="2xl">
      {"404"}
    </Heading>
    <Link to="/">
      <FormattedMessage
        id="notFound.backToDashboard"
        defaultMessage="Back to dashboard"
      />
    </Link>
  </div>
);

export default NotFound;
