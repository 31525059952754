import styled from "styled-components/macro";
import { Box, BoxProps } from "@chakra-ui/core";

const quantitySize = "20px";
const CircleTag = styled(Box)<BoxProps>``;

CircleTag.defaultProps = {
  bg: "primary.regular",
  color: "white",
  fontWeight: "normal",
  fontSize: "sm",
  lineHeight: quantitySize,
  width: quantitySize,
  height: quantitySize,
  borderRadius: "full",
  textAlign: "center",
};

export { CircleTag };
