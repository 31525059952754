import React from "react";
import { Box, BoxProps, Text, Flex, Icon } from "@chakra-ui/core";
import { useDropzone, FileRejection } from "react-dropzone";
import { FormattedMessage, useIntl } from "react-intl";
import { SecondaryButton } from "./primitives/Button";
import messages from "translations/commonMessages";
import Loading from "./primitives/Loading";

export type FileUploadProps = BoxProps & {
  onUpload: (files: File[]) => void;
  loading?: boolean;
  onFilesRejected?: (files: FileRejection[]) => void;
  multiple?: boolean;
  accept?: string | string[];
};

const FileUpload = ({
  onUpload,
  onFilesRejected,
  accept,
  multiple = false,
  loading,
  ...rest
}: FileUploadProps) => {
  const intl = useIntl();

  const { getRootProps, getInputProps, isDragActive, open } = useDropzone({
    accept,
    multiple,
    noClick: true,
    noKeyboard: true,
    disabled: loading,
    onDrop: (acceptedFiles) => {
      onUpload(acceptedFiles);
    },
    onDropRejected: (fileRejections, event) => {
      if (onFilesRejected) onFilesRejected(fileRejections);
      else console.log(fileRejections);
    },
  });
  return (
    <Box
      {...getRootProps()}
      {...rest}
      minHeight={{ base: "300px", md: "400px" }}
      width="100%"
      position="relative"
      bg="background"
      borderWidth="1px"
      borderColor={isDragActive ? "gray.700" : "gray.300"}
      borderStyle={isDragActive ? "solid" : "dashed"}
      borderRadius="10px"
      opacity={isDragActive ? 0.7 : 1}
      transition="border-style 0.3s, border-colors 0.3s"
    >
      <input {...getInputProps()} />
      <Flex
        direction="column"
        align="center"
        position="absolute"
        top="50%"
        left="50%"
        transform="translate(-50%, -50%)"
        opacity={loading ? 0.7 : 1}
      >
        <Icon
          name="upload"
          width={{ base: "100px", md: "150px" }}
          height={{ base: "100px", md: "150px" }}
          color="gray.200"
        />
        <FormattedMessage
          tagName="span"
          id="fileUpload.dragFileHere"
          defaultMessage="Drag file here"
        />
        <Text color="gray.500" fontSize="xs" mb="2">
          {intl.formatMessage(messages.or).toLowerCase()}
        </Text>
        <SecondaryButton onClick={open} isDisabled={loading}>
          <FormattedMessage
            tagName="span"
            id="fileUpload.chooseFileToSend"
            defaultMessage="Choose file"
          />
        </SecondaryButton>
      </Flex>
      {loading && (
        <Loading
          position="absolute"
          top="50%"
          left="50%"
          transform="translate(-50%, -50%)"
          color="blue.600"
        />
      )}
    </Box>
  );
};

export default FileUpload;
