import styled from "styled-components/macro";
import {
  FormControl as ChakraFormControl,
  FormLabel as ChakraFormLabel,
  FormControlProps,
  // @ts-ignore
  FormLabelProps,
} from "@chakra-ui/core";

const FormControl = styled(ChakraFormControl)<FormControlProps>``;
const FormLabel = styled(ChakraFormLabel)<FormLabelProps>``;

FormControl.defaultProps = {
  my: "4",
};
FormLabel.defaultProps = {
  fontSize: "xs",
};

export { FormControl, FormLabel };
