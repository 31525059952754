import React, { useState } from "react";
import { Box, Heading, Text } from "@chakra-ui/core";
import FileUpload from "components/FileUpload";
import { Card, CardContent, CardHeader } from "components/primitives/Card";
import { FormattedMessage, useIntl } from "react-intl";
import Notification, { GeneralMessage } from "components/Notification";
import messages from "translations/commonMessages";
import { useMutation } from "@apollo/client";
import { UPLOAD_FILE, UploadFileMutationResult } from "./fileImportQueries";
import { FileRejection } from "react-dropzone";

const FileImport = () => {
  const [generalMessage, setGeneralMessage] = useState<GeneralMessage>();
  const intl = useIntl();
  const [uploadFile, { loading }] = useMutation<UploadFileMutationResult>(
    UPLOAD_FILE,
    {
      onError: (error) => {
        setGeneralMessage({
          type: "error",
          message: intl.formatMessage(messages.errorOccurred, {
            error: error.message,
          }),
        });
      },
      onCompleted: () => {
        setGeneralMessage({
          type: "success",
          message: intl.formatMessage({
            id: "fileImport.fileUploadSuccess",
            defaultMessage: "File uploaded succesfully",
          }),
        });
      },
    }
  );

  const onFileUpload = (files: File[]) => {
    if (files[0]) {
      setGeneralMessage(undefined);
      uploadFile({
        variables: { upload: files[0] },
      });
    } else {
      console.error("No file found");
    }
  };

  const onFilesRejected = (fileRejections: FileRejection[]) => {
    if (fileRejections.length > 0) {
      setGeneralMessage({
        type: "error",
        message: intl.formatMessage(messages.errorOccurred, {
          error: fileRejections
            .map((rejection) =>
              intl.formatMessage(
                {
                  id: "fileImport.errorUploadingFile",
                  defaultMessage: 'Error uploading file "{fileName}": {error}',
                },
                {
                  fileName: rejection.file.name,
                  error: rejection.errors
                    .map((error) => error.message)
                    .join(", "),
                }
              )
            )
            .join(", "),
        }),
      });
    }
  };

  return (
    <>
      {generalMessage && <Notification notification={generalMessage} />}
      <Box p={3}>
        <Card m="4">
          <CardHeader active={true} p="4">
            <Heading
              as="h1"
              fontSize="md"
              color="white"
              textTransform="uppercase"
            >
              <FormattedMessage
                tagName="span"
                id="fileImport.bringFromFile"
                defaultMessage="Bring from file"
              />
            </Heading>
          </CardHeader>
          <CardContent p={{ base: "6", lg: "8" }}>
            <Text fontSize="sm" mb={{ base: "6", lg: "8" }}>
              <FormattedMessage
                tagName="span"
                id="fileImport.uploadHelpText"
                defaultMessage="Load the generated CIN-message to GDSN Admin to processing"
              />
            </Text>
            <FileUpload
              onUpload={onFileUpload}
              onFilesRejected={onFilesRejected}
              accept="text/xml"
              loading={loading}
            />
          </CardContent>
        </Card>
      </Box>
    </>
  );
};

export default FileImport;
