import React from "react";
import styled from "styled-components/macro";
import { Button, ButtonProps } from "@chakra-ui/core";
interface FilterButtonProps extends ButtonProps {
  active?: boolean;
}

const FilterButton = (props: FilterButtonProps) => {
  let someProps = {};
  if (props.active) {
    someProps = {
      bg: "green.50",
      _hover: {},
    };
  }
  const changedProps = { ...props, ...someProps };
  return <StyledFilterButton {...changedProps} />;
};

const StyledFilterButton = styled(Button)<
  FilterButtonProps & { active?: boolean }
>`
  & svg {
    transform: scale(1.5);
    fill: ${({ theme }) => theme.colors.primary.regular};
  }
  & {
    border-left: 0;
    ${({ active }) =>
      active &&
      `
    svg {
      fill: currentColor
    }
  `}
  }
`;

FilterButton.defaultProps = {
  color: "primary.regular",
  borderRadius: "sm",
  variant: "outline",
  borderColor: "primary.regular",
  size: "sm",
  py: "4",
  fontWeight: "semiold",
  px: "4",
  fontSize: "sm",
  display: "flex",

  _hover: {
    color: "primary.dark",
  },
};

export { FilterButton };
