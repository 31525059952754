import React from "react";
import { Flex, FlexProps, Icon, Text } from "@chakra-ui/core";

export type GeneralMessage = {
  type: "error" | "success";
  message: string;
};

export type NotificationProps = FlexProps & {
  notification: GeneralMessage;
};

const Notification = ({ notification, ...rest }: NotificationProps) => {
  return (
    <Flex
      bg={notification.type === "error" ? "red.600" : "primary.regular"}
      py="1"
      minHeight="3.22rem"
      justifyItems="center"
      alignItems="center"
      px="4"
      overflow="hidden"
      {...rest}
    >
      <Icon
        name={notification.type === "error" ? "warning-2" : "check"}
        color="white"
        size="20px"
        float="left"
        mr="4"
        ml={[0, 0, 2]}
      />
      <Text color="white" fontSize="sm" lineHeight="shorter">
        {notification.message}
      </Text>
    </Flex>
  );
};

export default Notification;
