import { gql } from "@apollo/client";

export const UPLOAD_FILE = gql`
  mutation uploadFile($upload: Upload!) {
    uploadFile(upload: $upload) {
      id
    }
  }
`;

export type UploadFileMutationResult = {
  uploadFile: string;
};
