import { gql } from "@apollo/client";
import { CodeListResult } from "@pravda/gdsn-admin-backend/lib/types/code-list";

export const GET_CODE_LIST_ITEMS = gql`
  query getCodeListItems(
    $codeListId: String!
    $filters: CodeListFilters
    $limit: Int
  ) {
    getCodeListItems(
      codeListId: $codeListId
      filters: $filters
      limit: $limit
    ) {
      result {
        code
        label {
          fi
          en
        }
        emailAddress
        SecondaryMeasurementUnit
      }
      limit
      total
    }
  }
`;

export type GetCodeListItemsResult = {
  getCodeListItems: CodeListResult;
};
