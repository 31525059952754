import React from "react";
import {
  Grid,
  Box,
  Heading,
  Text,
  BoxProps,
  Tooltip,
  Icon,
} from "@chakra-ui/core";
import { Product, Status } from "@pravda/gdsn-admin-backend/lib/types/product";
import { CircleTag } from "components/primitives/Tag";
import { Deadline } from "components/primitives/Deadline";
import { useIntl } from "react-intl";
import styled from "styled-components/macro";

export type ProductListItemProps = {
  product: Product;
  containerProps?: BoxProps;
};

const ProductListItem = ({ product, containerProps }: ProductListItemProps) => {
  const intl = useIntl();

  const getQuantitityOrStatus = () => {
    switch (product.status) {
      case Status.DataMissingFromForm:
        return "L";
      case Status.DataMissingFromSynkka:
        return "S";
      case Status.Cancelled:
        return "C";
      case Status.Done:
        return <Icon name="check" size="12px" />;
      default:
        return product.amountOfChanges.toString();
    }
  };

  const hasMissingData = () => {
    return (
      product.status === Status.DataMissingFromForm ||
      product.status === Status.DataMissingFromSynkka
    );
  };

  const hasComment = () => {
    return product.customtuoteryhmapaallikonkommentti !== null &&
      product.customtuoteryhmapaallikonkommentti !== undefined
      ? product.customtuoteryhmapaallikonkommentti.length > 0
      : false;
  };

  const getToolTipMessage = (): string => {
    if (product.status === Status.DataMissingFromSynkka) {
      return intl.formatMessage({
        id: "products.missingDataFromSynkkaTooltip",
        defaultMessage: "Data missing from Synkka",
      });
    }
    if (product.status === Status.DataMissingFromForm) {
      return intl.formatMessage({
        id: "products.missingDataFromFormTooltip",
        defaultMessage: "Data missing from form",
      });
    }
    return "No special information"; // Should not render
  };

  const getCircleTagColor = () => {
    switch (product.status) {
      case Status.DataMissingFromForm:
      case Status.DataMissingFromSynkka:
        return "gray.700";
      case Status.Cancelled:
        return "gray.600";
      case Status.ErrorInErp:
        return "red.600";
      default:
        return "primary.regular";
    }
  };

  return (
    <Box
      m="0"
      py="4"
      px="8"
      borderBottomWidth="1px"
      borderBottomColor="gray.200"
      {...containerProps}
      fontSize="xs"
    >
      <Grid
        templateColumns="repeat(11, 1fr)"
        gap={1}
        alignItems="center"
        opacity={hasMissingData() ? 0.6 : 1}
      >
        <Heading
          gridColumn="1/5"
          as="h3"
          color="primary.dark"
          overflow="hidden"
          fontSize="inherit"
        >
          {product.name}
        </Heading>
        <Text
          gridColumn="5/8"
          color="black"
          fontWeight="normal"
          overflow="hidden"
        >
          {product.gtin}
        </Text>
        {product.deadline && (
          <Deadline
            gridColumn="8/11"
            textAlign="center"
            date={new Date(product.deadline)}
            fontSize="inherit"
          />
        )}
        {hasComment() ? (
          <Tooltip
            label={product.customtuoteryhmapaallikonkommentti!}
            aria-label={product.customtuoteryhmapaallikonkommentti!}
            placement="top"
            zIndex={100}
            p="2"
            hasArrow
          >
            <CircleTag
              bg="green.500"
              mr="1"
              justifySelf="right"
              gridColumn="11/12"
              pointerEvents="auto"
              cursor="default"
            >
              {"!"}
            </CircleTag>
          </Tooltip>
        ) : (
          <></>
        )}
        {hasMissingData() ? (
          <Tooltip
            label={getToolTipMessage()}
            aria-label={getToolTipMessage()}
            placement="top"
            zIndex={100}
            p="2"
            hasArrow
          >
            <ProductStatus
              bg={getCircleTagColor()}
              justifySelf="right"
              gridColumn="12/13"
              pointerEvents="auto"
              cursor="default"
            >
              {getQuantitityOrStatus()}
            </ProductStatus>
          </Tooltip>
        ) : (
          <CircleTag
            bg={getCircleTagColor()}
            justifySelf="right"
            gridColumn="12/13"
          >
            {getQuantitityOrStatus()}
          </CircleTag>
        )}
      </Grid>
    </Box>
  );
};

const ProductStatus = styled(CircleTag)`
  &:active {
    pointer-events: none;
  }
`;

export default ProductListItem;
