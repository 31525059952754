import { gql } from "@apollo/client";
import { Product } from "@pravda/gdsn-admin-backend/lib/types/product";
import { ProductGroup } from "@pravda/gdsn-admin-backend/lib/types/product-group";
import { PurchaseGroup } from "@pravda/gdsn-admin-backend/lib/types/purchase-group";

const productQueryFragments = {
  fieldInformation: gql`
    fragment FieldInformation on Field {
      path
      label {
        fi
        en
      }
      type
      editable
      requiresApproval
      approved
      error
      helpText {
        fi
        en
      }
    }
  `,
  generalFieldData: gql`
    fragment GeneralFieldData on FieldData {
      ... on StringFieldData {
        stringOldValue
        stringNewValue
      }
      ... on TextFieldData {
        textNewValue
        textOldValue
      }
      ... on IntFieldData {
        intOldValue
        intNewValue
      }
      ... on DecimalFieldData {
        decimalNewValue
        decimalOldValue
      }
      ... on BoolFieldData {
        boolNewValue
        boolOldValue
      }
      ... on DateFieldData {
        dateNewValue
        dateOldValue
      }
      ... on SelectFieldData {
        selectNewValue
        selectOldValue
        codeListId
      }
      ... on MultiSelectFieldData {
        multiselectNewValue
        multiselectOldValue
        codeListId
      }
      ... on MetricInputFieldData {
        metricInputNewValue
        metricInputOldValue
        factor
      }
    }
  `,
};

export const GET_PRODUCT = gql`
  query getProduct($id: ID!) {
    getProduct(id: $id) {
      id
      customtuoteryhmapaallikonkommentti
      gtin
      author
      status
      name
      deadline
      error
      amountOfChanges
      isUpdate
      fields {
        ...FieldInformation
        data {
          ...GeneralFieldData
          ... on PackageHierarchiesData {
            hierarchies {
              hierarchy {
                gtin
                type
                fields {
                  ...FieldInformation
                  data {
                    ...GeneralFieldData
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  ${productQueryFragments.fieldInformation}
  ${productQueryFragments.generalFieldData}
`;

export type GetProductQueryResult = {
  getProduct: Product;
};

export const GET_PRODUCTS = gql`
  query getProducts(
    $keywords: [String!]
    $includedProductGroupIds: [String!]
    $includedPurchaseGroupIds: [String!]
    $excludedStatuses: [Status!]
  ) {
    getProducts(
      args: {
        keywords: $keywords
        includedProductGroupIds: $includedProductGroupIds
        includedPurchaseGroupIds: $includedPurchaseGroupIds
        excludedStatuses: $excludedStatuses
      }
    ) {
      id
      gtin
      status
      name
      deadline
      customtuoteryhmapaallikonkommentti
      amountOfChanges
    }
  }
`;

export type GetProductsQueryResult = {
  getProducts: Product[];
};

export const UPDATE_PRODUCT_AUTHOR = gql`
  mutation updateAuthor($id: ID!, $author: String) {
    updateAuthor(id: $id, author: $author) {
      id
    }
  }
`;

export type UpdateProductAuthorResult = {
  updateAuthor: Product;
};

export const UPDATE_PRODUCT_DRAFT = gql`
  mutation updateProductDraft($id: ID!, $fields: [FieldInput!]!, $customtuoteryhmapaallikonkommentti: String!) {
    updateProductDraft(id: $id, fields: $fields, customtuoteryhmapaallikonkommentti: $customtuoteryhmapaallikonkommentti) {
      id
    }
  }
`;

export type UpdateProductDraftResult = {
  updateProductDraft: Product;
};

export const SAVE_PRODUCT = gql`
  mutation saveProduct($id: ID!, $fields: [FieldInput!]!, $customtuoteryhmapaallikonkommentti: String!) {
    saveProduct(id: $id, fields: $fields, customtuoteryhmapaallikonkommentti: $customtuoteryhmapaallikonkommentti) {
      id
      gtin
      author
      status
      name
      deadline
      customtuoteryhmapaallikonkommentti
      error
      amountOfChanges
      fields {
        ...FieldInformation
        data {
          ...GeneralFieldData
          ... on PackageHierarchiesData {
            hierarchies {
              hierarchy {
                gtin
                type
                fields {
                  ...FieldInformation
                  data {
                    ...GeneralFieldData
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  ${productQueryFragments.fieldInformation}
  ${productQueryFragments.generalFieldData}
`;

export type SaveProductResult = {
  saveProduct: Product;
};

export const DELETE_PRODUCT = gql`
  mutation deleteProduct($id: ID!) {
    deleteProduct(id: $id) {
      id
    }
  }
`;

export type DeleteProductResult = {
  deleteProduct: Product;
};

export const UPDATE_PRODUCT_STATUS = gql`
  mutation updateProductStatus($id: ID!, $status: Status!, $handled: DateTime) {
    updateProductStatus(id: $id, status: $status, handled: $handled) {
      id
    }
  }
`;

export type UpdateProductStatusResult = {
  updateProductStatus: Product;
};

export const UPDATE_PRODUCT_COMMENT = gql`
  mutation updateProductComment($id: ID!, $customtuoteryhmapaallikonkommentti: String!) {
    updateProductComment(id: $id, customtuoteryhmapaallikonkommentti: $customtuoteryhmapaallikonkommentti) {
      id
    }
  }
`;

export type UpdateProductCommentResult = {
  updateProductComment: Product;
};

export const GET_PRODUCT_GROUPS = gql`
  query getProductGroups($keyword: String) {
    getProductGroups(keyword: $keyword) {
      id
      name
    }
  }
`;

export type GetProductGroupsQueryResult = {
  getProductGroups: ProductGroup[];
};

export const GET_PURCHASE_GROUPS = gql`
  query getPurchaseGroups {
    getPurchaseGroups {
      id
      name
    }
  }
`;

export type GetPurchaseGroupsQueryResult = {
  getPurchaseGroups: PurchaseGroup[];
};
