"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.PackageType = exports.FieldType = exports.Status = void 0;
var Status;
(function (Status) {
    Status["DataMissingFromForm"] = "DATA_MISSING_FROM_FORM";
    Status["DataMissingFromSynkka"] = "DATA_MISSING_FROM_SYNKKA";
    Status["Todo"] = "TODO";
    Status["InProgress"] = "IN_PROGRESS";
    Status["DataSent"] = "DATA_SENT";
    Status["Cancelled"] = "CANCELLED";
    Status["Outdated"] = "OUTDATED";
    Status["ErrorInErp"] = "ERROR_IN_ERP";
    Status["Done"] = "DONE";
})(Status = exports.Status || (exports.Status = {}));
var FieldType;
(function (FieldType) {
    FieldType["String"] = "STRING";
    FieldType["Text"] = "TEXT";
    FieldType["Int"] = "INT";
    FieldType["Decimal"] = "DECIMAL";
    FieldType["Date"] = "DATE";
    FieldType["Bool"] = "BOOL";
    FieldType["Select"] = "SELECT";
    FieldType["MultiSelect"] = "MULTI_SELECT";
    FieldType["Metric"] = "METRIC";
    FieldType["PackageHierarchies"] = "PACKAGE_HIERARCHIES";
})(FieldType = exports.FieldType || (exports.FieldType = {}));
var PackageType;
(function (PackageType) {
    PackageType["Pallet"] = "PALLET";
    PackageType["PackOrInnerPack"] = "PACK_OR_INNER_PACK";
    PackageType["Case"] = "CASE";
    PackageType["BaseUnit"] = "BASE_UNIT";
    PackageType["BaseUnitOrEach"] = "BASE_UNIT_OR_EACH";
    PackageType["MixedModule"] = "MIXED_MODULE";
    PackageType["DisplayShipper"] = "DISPLAY_SHIPPER";
    PackageType["NonCodePallet"] = "NON_CODE_PALLET";
    PackageType["Unknown"] = "UNKNOWN";
    PackageType["TransportLoad"] = "TRANSPORT_LOAD";
})(PackageType = exports.PackageType || (exports.PackageType = {}));
