import React, { useState, useEffect, useContext, useMemo } from "react";
import {
  Box,
  Flex,
  Heading,
  Stack,
  Text,
  Collapse,
  useTheme,
  CloseButton,
  Tag,
  TagLabel,
  TagCloseButton,
  Icon,
  Grid
} from "@chakra-ui/core";
import { useQuery, QueryResult } from "@apollo/client";
import { useDebouncedCallback } from "use-debounce";
import { Link, LinkProps, useParams } from "react-router-dom";
import { FormattedMessage, useIntl } from "react-intl";

import { GET_PRODUCTS, GetProductsQueryResult } from "./productQueries";
import ProductListItem from "./ProductListItem";
import { PrimaryIconButton } from "components/primitives/IconButton";
import { Sidebar, SidebarHeader, SidebarInner } from "components/Sidebar";
import HandleQuery from "components/HandleQuery";
import { FlushedInput } from "components/primitives/Input";
import ProductFilters, { SelectedFilters } from "./ProductFilters";
import { FilterButton } from "components/primitives/FilterButton";
import ProductModification from "./ProductModification";

import { Status, Product } from "@pravda/gdsn-admin-backend/lib/types/product";

import routes from "../../routes/routes.json";
import styled, { css } from "styled-components/macro";
import { CircleTag } from "components/primitives/Tag";
import GlobalStateContext from "globalStateContext";
import { isMobileViewport } from "utils/viewportCalculator";
import { CodeListItem } from "@pravda/gdsn-admin-backend/lib/types/code-list";

type ProductLinkParams = {
  id?: string;
};

enum SortMode {
  Ascending = 'asc',
  Descending = 'desc'
}

const Products = () => {
  const intl = useIntl();
  const [inputValue, setInputValue] = useState("");
  const [searchKeywords, setSearchKeywords] = useState<string[]>([]);
  const [selectedFilters, setSelectedFilters] = useState<SelectedFilters>();
  const [products, setProducts] = useState<Product[]>([]);
  const [hideDataMissingProducts, setHideDataMissingProducts] = useState<boolean>(false);
  const [sortByProperty, setSortByProperty] = useState<{ key: keyof Product, mode: SortMode, type: 'string' | 'date' }>({ key: 'deadline', mode: SortMode.Ascending, type: 'date' });
  const [debouncedSearchChange, cancelDebouncedSearch] = useDebouncedCallback(
    (value: string[]) => {
      setSearchKeywords(value);
    },
    250
  );
  const params = useParams<ProductLinkParams>();
  const id = useMemo(() => params.id, [params.id]);

  const theme = useTheme();
  const globalState = useContext(GlobalStateContext);

  const {
    loading,
    error,
    data,
  }: QueryResult<GetProductsQueryResult> = useQuery(GET_PRODUCTS, {
    variables: {
      keywords: searchKeywords,
      excludedStatuses: [Status.Outdated],
      includedProductGroupIds: selectedFilters?.productGroups.map(
        (productGroup) => productGroup.code
      ),
      includedPurchaseGroupIds: selectedFilters?.purchaseGroups.map(
        (purchaseGroup) => purchaseGroup.code
      ),
    },
    fetchPolicy: "no-cache",
  });


  const hasMissingData = (product: Product) => product.status === Status.DataMissingFromForm || product.status === Status.DataMissingFromSynkka;

  useEffect(() => {
    if (isMobileViewport(window)) {
      if (id && globalState.showSidebar) {
        globalState.setGlobalState({
          showSidebar: false,
        });
      } else if (!id && !globalState.showSidebar) {
        globalState.setGlobalState({
          showSidebar: true,
        });
      }
    }
    if (!selectedFilters) {
      const currentUserEmail = globalState.currentUser || "Local-user";
      const savedFiltersJSON = localStorage.getItem(
        `filters.${currentUserEmail}`
      );
      if (currentUserEmail && (savedFiltersJSON && savedFiltersJSON !== "undefined")) { // Crashes and burns when value stored thru stringify is "undefined"
        const savedFilters: SelectedFilters = JSON.parse(
          savedFiltersJSON
        ) as SelectedFilters;
        setSelectedFilters(savedFilters);
      }
    }

    if (!data?.getProducts) {
      return;
    }

    const filterMissingData = (product: Product) => {
      if (hideDataMissingProducts && hasMissingData(product)) {
        return false;
      }
      return true;
    }

    const delayedSort = setTimeout(() => {
      const products = data.getProducts.filter(filterMissingData).sort((p1, p2) => {
        const sortProp = sortByProperty.key;
        // Default to string comparison
        let comparer = (v1: Product, v2: Product) => v1[sortProp].toString().localeCompare(v2[sortProp]);
        // Use time to compare dates
        if (sortByProperty.type === 'date') {
          comparer = (v1: Product, v2: Product) =>
            new Date(v1[sortProp]).getTime() > new Date(v2[sortProp]).getTime() ? 1 : -1;
        }
        return sortByProperty.mode === SortMode.Ascending ? comparer(p1, p2) : comparer(p2, p1);
      }) ?? [];
      setProducts(products);
    }, 250);

    return () => clearTimeout(delayedSort);

  }, [
    data,
    globalState,
    id,
    loading,
    selectedFilters,
    theme.breakpoints.lg,
    theme.fontSizes.baseFontSize,
    searchKeywords,
    sortByProperty,
    hideDataMissingProducts
  ]);

  const forceProductListUpdate = () => {
    setSearchKeywords([Math.random().toString()]);
    setSearchKeywords([inputValue]);
  };

  const updateSearch = () => {
    cancelDebouncedSearch();
    setSearchKeywords([inputValue]);
  };

  const handleInputChange = (e: React.FormEvent<HTMLInputElement>) => {
    const inputValueChange = e.currentTarget.value;
    setInputValue(inputValueChange);
    const searchVals = inputValueChange.split('&&').map(x => x.trim());
    debouncedSearchChange(searchVals);
  };

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Enter") {
      cancelDebouncedSearch();
      setSearchKeywords([inputValue]);
    }
  };

  const handleFiltersChange = (filters: SelectedFilters | undefined, hideGrayProducts: boolean) => {
    setHideDataMissingProducts(hideGrayProducts);
    setSelectedFilters(filters);
    const currentUserEmail = globalState.currentUser || "Local-user";
    if (currentUserEmail && typeof filters !== "undefined") {
      localStorage.setItem(
        `filters.${currentUserEmail}`,
        JSON.stringify(filters)
      );
    }
  };

  const removeProductGroupFilter = (productGroup: CodeListItem) => {
    const newProductGroups = selectedFilters?.productGroups.filter(
      (it) => it.code !== productGroup.code
    );
    handleFiltersChange({
      purchaseGroups: selectedFilters?.purchaseGroups || [],
      productGroups: newProductGroups || []
    }, hideDataMissingProducts);
  };

  const removePurchaseGroupFilter = (purchaseGroup: CodeListItem) => {
    const newPurchaseGroups = selectedFilters?.purchaseGroups.filter(
      (it) => it.code !== purchaseGroup.code
    );
    handleFiltersChange({
      productGroups: selectedFilters?.productGroups || [],
      purchaseGroups: newPurchaseGroups || [],
    }, hideDataMissingProducts);
  };

  const areFiltersEmpty = () => {
    if (selectedFilters) {
      return (
        selectedFilters.productGroups.length === 0 &&
        selectedFilters.purchaseGroups.length === 0
      );
    }
    return true;
  };

  const [showFilters, setShow] = useState(false);

  const handleToggle = () => setShow(!showFilters);

  return (
    <Flex width="100%" flex={1}>
      <Sidebar>
        <SidebarInner>
          <SidebarHeader>
            <Heading as="h3" size="sm" fontWeight="semibold">
              <FormattedMessage
                id="products.processingQueue"
                defaultMessage="Processing queue"
              />
            </Heading>
          </SidebarHeader>
          <Stack
            justifyContent="space-between"
            alignItems="center"
            isInline
            spacing="6"
            px={["4", "6"]}
            py="4"
          >
            <Flex direction="row" align="center">
              <FilterButton
                leftIcon="filter"
                onClick={handleToggle}
                active={showFilters}
              >
                <FormattedMessage
                  id="products.filter"
                  defaultMessage="Filter"
                />
              </FilterButton>
              {!areFiltersEmpty() && (
                <CloseButton
                  color="primary.dark"
                  onClick={() =>
                    handleFiltersChange({
                      productGroups: [],
                      purchaseGroups: [],
                    }, false)
                  }
                />
              )}
            </Flex>

            <Flex width="100%">
              <FlushedInput
                value={inputValue}
                onChange={handleInputChange}
                onKeyDown={handleKeyDown}
                placeholder={intl.formatMessage({
                  id: "products.searchplaceholder",
                  defaultMessage: "Search by GTIN or name",
                })}
                size="md"
                mr="2"
              />
              <PrimaryIconButton
                aria-label={intl.formatMessage({
                  id: "products.searchButtonAria",
                  defaultMessage: "Search products",
                })}
                icon="search"
                size="md"
                p="0"
                onClick={updateSearch}
              />
            </Flex>
          </Stack>

          <Collapse startingHeight={0} isOpen={showFilters}>
            <ProductFilters
              initialFilters={selectedFilters}
              onChange={handleFiltersChange}
            />
          </Collapse>
          {!areFiltersEmpty() && (
            <Flex wrap="wrap" fontSize="xs" p="3">
              {selectedFilters?.productGroups.map((productGroup) => (
                <Tag
                  key={productGroup.code}
                  variantColor="green"
                  color="regular.dark"
                  borderRadius="10px"
                  size="sm"
                  fontSize="xs"
                  mr="2"
                  mb="2"
                  py="0"
                  px="2"
                >
                  <TagLabel>
                    <FormattedMessage
                      tagName="span"
                      id="products.productGroupTag"
                      defaultMessage="Product group: {productGroup}"
                      values={{ productGroup: productGroup.label }}
                    />
                  </TagLabel>
                  <TagCloseButton
                    onClick={() => removeProductGroupFilter(productGroup)}
                  />
                </Tag>
              ))}
              {selectedFilters?.purchaseGroups.map((purchaseGroup) => (
                <Tag
                  key={purchaseGroup.code}
                  variantColor="green"
                  color="regular.dark"
                  size="sm"
                  fontSize="xs"
                  mr="2"
                  mb="2"
                  py="0"
                  px="2"
                >
                  <TagLabel>
                    <FormattedMessage
                      tagName="span"
                      id="products.purchaseGroupTag"
                      defaultMessage="Purchase group: {purchaseGroup}"
                      values={{ purchaseGroup: purchaseGroup.label }}
                    />
                  </TagLabel>
                  <TagCloseButton
                    onClick={() => removePurchaseGroupFilter(purchaseGroup)}
                  />
                </Tag>
              ))}
            </Flex>
          )}

          <Box>
            <HandleQuery loading={loading} error={error}>
              <Box
                m="0"
                py="4"
                px="8"
                borderBottomWidth="1px"
                borderBottomColor="gray.200"
                paddingX="2rem"
              >
                <Grid
                  templateColumns="repeat(11, 1fr)"
                  gap={1}
                >
                  {
                    [
                      { gridColumn: '1/5', key: 'name', type: 'string' },
                      { gridColumn: '5/8', key: 'gtin', type: 'string' },
                      { gridColumn: '8/10', key: 'deadline', type: 'date', textAlign: 'center' },
                    ].map(sortElement => {
                      const textAlign = sortElement.textAlign as any;
                      const type = sortElement.type === 'string' ? 'string' : 'date';
                      return (<Text key={sortElement.key} textAlign={textAlign} gridColumn={sortElement.gridColumn}>
                        <Icon name="arrow-up" onClick={() => setSortByProperty({ key: sortElement.key as keyof Product, mode: SortMode.Ascending, type: type })}></Icon>
                        <Icon name="arrow-down" onClick={() => setSortByProperty({ key: sortElement.key as keyof Product, mode: SortMode.Descending, type: type })}></Icon>
                      </Text>)
                    })
                  }
                </Grid>
              </Box>
              {products.map((product: Product) => {
                if (hasMissingData(product)) {
                  return (
                    <StyledProductLink
                      to={{
                        pathname: `/${intl.locale}${routes.products}/${product.id}`,
                        state: { fromProductLink: true },
                      }}
                      key={product.id}
                      status={product.status}
                      selected={product.id === id}
                    >
                      <ProductListItem key={product.id} product={product} />
                    </StyledProductLink>
                  );
                } else {
                  return (
                    <StyledProductLink
                      to={{
                        pathname: `/${intl.locale}${routes.products}/${product.id}`,
                        state: { fromProductLink: true },
                      }}
                      key={product.id}
                      status={product.status}
                      selected={product.id === id}
                    >
                      <ProductListItem key={product.id} product={product} />
                    </StyledProductLink>
                  );
                }
              })}

              {products.length === 0 && (
                <Text ml={3} mt={2}>
                  <FormattedMessage
                    id="products.noProductsFound"
                    defaultMessage="No products found with given filters"
                  />
                </Text>
              )}
            </HandleQuery>
          </Box>
          <Box p="2">
            <Flex mb="1">
              <CircleTag bg="gray.600" mr="1">
                {"L"}
              </CircleTag>
              <Text fontWeight="normal">
                <FormattedMessage
                  id="products.missingDataFromFormExplanation"
                  defaultMessage="= Data missing from form"
                />
              </Text>
            </Flex>
            <Flex>
              <CircleTag bg="gray.600" mr="1">
                {"S"}
              </CircleTag>
              <Text fontWeight="normal">
                <FormattedMessage
                  id="products.missingDataFromSynkkaExplanation"
                  defaultMessage="= Data missing from Synkka"
                />
              </Text>
            </Flex>
          </Box>
        </SidebarInner>
      </Sidebar>
      {id && (
        <Flex
          flexDirection="column"
          minHeight="100vh"
          width="100%"
          as="main"
          mt="-2px"
        >
          <ProductModification
            productId={id}
            updateProductList={forceProductListUpdate}
          />
        </Flex>
      )}
    </Flex>
  );
};

type StyledProductLinkProps = LinkProps & {
  status: Status;
  selected: boolean;
};

const StyledProductLink = styled(Link)<StyledProductLinkProps>`
  display: block;
  position: relative;
  ${({ theme, status }) => {
    switch (status) {
      case Status.DataMissingFromForm:
      case Status.DataMissingFromSynkka:
        return css`
          opacity: 0.6;
        `;
      case Status.ErrorInErp:
        return css`
          background-color: ${theme.colors.red[50]};
        `;
      default:
        return null;
    }
  }}
  ${({ theme, selected }) =>
    // Make selected product have triangle in the left side
    selected &&
    css`
      &:before {
        content: "";
        position: absolute;
        top: 50%;
        left: 0;
        width: 0;
        height: 0;
        transform: translateY(-50%);
        border-left: 10px solid ${theme.colors.primary.dark};
        border-top: 10px solid transparent;
        border-bottom: 10px solid transparent;
      }
    `}
`;

export default Products;
