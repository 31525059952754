import { defineMessages } from "react-intl";

const messages = defineMessages({
  select: {
    id: "select",
    defaultMessage: "Select...",
  },
  selectOneOrMore: {
    id: "selectOneOrMore",
    defaultMessage: "Select one or more",
  },
  noOptionsMessage: {
    id: "noOptions",
    defaultMessage: "No options",
  },
  cancel: {
    id: "cancel",
    defaultMessage: "Cancel",
  },
  save: {
    id: "save",
    defaultMessage: "Save",
  },
  accept: {
    id: "accept",
    defaultMessage: "Accept",
  },
  deny: {
    id: "deny",
    defaultMessage: "Deny",
  },
  errorOccurred: {
    id: "errorOccurred",
    defaultMessage: "Something went wrong: {error}",
  },
  yes: {
    id: "yes",
    defaultMessage: "Yes",
  },
  no: {
    id: "no",
    defaultMessage: "No",
  },
  or: {
    id: "or",
    defaultMessage: "Or",
  },
  loading: {
    id: "loading",
    defaultMessage: "Loading",
  },

  field: {
    id: "field",
    defaultMessage: "Field",
  },

  oldValue: {
    id: "oldValue",
    defaultMessage: "Old Value",
  },

  newValue: {
    id: "newValue",
    defaultMessage: "New Value",
  },
  TRANSPORT_LOAD: {
    id: "TRANSPORT_LOAD",
    defaultMessage: "Transport_load",
  },
  PALLET: {
    id: "PALLET",
    defaultMessage: "PALLET",
  },

  PACK_OR_INNER_PACK: {
    id: "PACK_OR_INNER_PACK",
    defaultMessage: "Pack_or_inner_pack",
  },

  CASE: {
    id: "CASE",
    defaultMessage: "Case",
  },

  BASE_UNIT_OR_EACH: {
    id: "BASE_UNIT_OR_EACH",
    defaultMessage: "Base_unit_or_each",
  },
  MIXED_MODULE: {
    id: "MIXED_MODULE",
    defaultMessage: "Mixed_module",
  },

  DISPLAY_SHIPPER: {
    id: "DISPLAY_SHIPPER",
    defaultMessage: "Display Shipper",
  },

  NON_CODE_PALLET: {
    id: "NON_CODE_PALLET",
    defaultMessage: "Non Code Pallet",
  },
});

export default messages;
