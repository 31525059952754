import React from "react";
import styled from "styled-components/macro";
import { PseudoBox, PseudoBoxProps } from "@chakra-ui/core";
import messages from "translations/commonMessages";
import { useIntl } from "react-intl";

export type PackageHierarchyRowProps = PseudoBoxProps & {
  fieldLabel: string;
  oldValue?: string | JSX.Element;
  newValue: string | JSX.Element;
};

const StyledPackageHierarchyTable = styled(PseudoBox)<PseudoBoxProps>`
  border-collapse: separate;
  border-spacing: 0;
`;
const PackageHierarchyTableHeader = styled(PseudoBox)<PseudoBoxProps>``;
const StyledPackageHierarchyTableTr = styled(PseudoBox)<PseudoBoxProps>``;
const StyledPackageHierarchyTableTd = styled(PseudoBox)<PseudoBoxProps>`
  &:first-child {
    border-left: none;
  }
`;
const StyledPackageHierarchyTableTh = styled(PseudoBox)<PseudoBoxProps>`
  &:first-child {
    border-left: none;
  }
`;

PackageHierarchyTableHeader.defaultProps = {
  mb: "1",
  display: "block",
  fontSize: "xs",
  fontWeight: "bold",
};

StyledPackageHierarchyTable.defaultProps = {
  display: "table",
  lineHeight: "short",
  fontSize: "xs",
  textAlign: "left",
  borderColor: "gray.300",
  borderWidth: "1px",
  borderStyle: "solid",
  borderRadius: "sm",
  mb: { base: "4", lg: "6" },
  width: "100%",
  minWidth: { base: "100%", lg: "500px" },
};

StyledPackageHierarchyTableTr.defaultProps = {
  display: "table-row",
  py: "1",
  px: "4",
  lineHeight: "short",
  fontSize: "xs",
};

StyledPackageHierarchyTableTd.defaultProps = {
  display: "table-cell",
  borderColor: "gray.300",
  borderLeftWidth: "1px",
  borderTopWidth: "1px",
  borderStyle: "solid",
  py: "1",
  px: "2",
  lineHeight: "short",
  fontSize: "xs",
};

StyledPackageHierarchyTableTh.defaultProps = {
  display: "table-cell",
  borderColor: "gray.300",
  bg: "gray.100",
  borderLeftWidth: "1px",
  borderStyle: "solid",
  py: "2",
  px: "2",
  fontWeight: "semibold",
  lineHeight: "short",
  fontSize: "xs",
};

const PackageHierarchyTableTr = (props: PackageHierarchyRowProps) => {
  return (
    <StyledPackageHierarchyTableTr {...props}>
      <StyledPackageHierarchyTableTd bg="gray.100">
        {props.fieldLabel}
      </StyledPackageHierarchyTableTd>
      <StyledPackageHierarchyTableTd bg="gray.50">
        {props.oldValue}
      </StyledPackageHierarchyTableTd>
      <StyledPackageHierarchyTableTd>
        {props.newValue}
      </StyledPackageHierarchyTableTd>
    </StyledPackageHierarchyTableTr>
  );
};

const PackageHierarchyTable = (props: PseudoBoxProps) => {
  const intl = useIntl();
  return (
    <StyledPackageHierarchyTable {...props}>
      <thead>
        <tr>
          <StyledPackageHierarchyTableTh>
            {intl.formatMessage(messages.field)}
          </StyledPackageHierarchyTableTh>
          <StyledPackageHierarchyTableTh>
            {intl.formatMessage(messages.oldValue)}
          </StyledPackageHierarchyTableTh>
          <StyledPackageHierarchyTableTh>
            {intl.formatMessage(messages.newValue)}
          </StyledPackageHierarchyTableTh>
        </tr>
      </thead>
      <tbody>{props.children}</tbody>
    </StyledPackageHierarchyTable>
  );
};

export {
  PackageHierarchyTableHeader,
  PackageHierarchyTable,
  PackageHierarchyTableTr,
};
