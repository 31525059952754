import styled from "styled-components/macro";
import { Button, ButtonProps } from "@chakra-ui/core";

const PrimaryButton = styled(Button)<ButtonProps>`
  & svg {
    transform: scale(1.5);
    color: ${({ theme }) => theme.colors.primary.regular} !important;
    fill: ${({ theme }) => theme.colors.primary.regular} !important;
  }
`;
const SecondaryButton = styled(Button)<ButtonProps>`
  & svg {
    transform: scale(1.5);
    color: ${({ theme }) => theme.colors.secondary.dark} !important;
    fill: ${({ theme }) => theme.colors.secondary.dark} !important;
  }
`;
const TertiaryButton = styled(Button)<ButtonProps>`
  & svg {
    transform: scale(1.5);
    color: ${({ theme }) => theme.colors.primary.regular} !important;
    fill: ${({ theme }) => theme.colors.primary.regular} !important;
  }
`;

const AlertButton = styled(Button)<ButtonProps>`
  & svg {
    transform: scale(1.5);
  }
`;

const BaseButton: any = {
  textTransform: "uppercase",
  borderRadius: "sm",
  fontWeight: "bold",
  size: "md",
  fontSize: "xs",
  fontFamily: "body",
  _hover: {
    opacity: 1,
  },
  _disabled: {
    bg: "gray.200",
    color: "white",
    cursor: "not-allowed",
    opacity: 1,
    border: "none",
  },
};

PrimaryButton.defaultProps = {
  ...BaseButton,
  color: "white",
  bg: "primary.regular",
  variant: "solid",
  _hover: {
    bg: "green.500",
  },
};

SecondaryButton.defaultProps = {
  ...BaseButton,
  color: "blue.800",
  borderColor: "blue.800",
  bg: "blue.50",
  variant: "outline",
  _hover: {
    color: "blue.600",
    borderColor: "blue.600",
  },
};

TertiaryButton.defaultProps = {
  ...BaseButton,
  color: "gray.500",
  variant: "ghost",
  _hover: {
    color: "primary.dark",
  },
  _disabled: {
    cursor: "not-allowed",
    color: "gray.200",
  },
};

AlertButton.defaultProps = {
  ...BaseButton,
  color: "red.600",
  borderColor: "red.600",
  variant: "outline",
  _hover: {
    color: "red.400",
    borderColor: "red.400",
  },
};

export { PrimaryButton, SecondaryButton, TertiaryButton, AlertButton };
