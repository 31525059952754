import React, { useState } from "react";
import { ApolloProvider } from "@apollo/client";
import { ApolloClient, InMemoryCache, ApolloLink } from "@apollo/client";
import { onError } from "@apollo/link-error";
import { createUploadLink } from "apollo-upload-client";
import { setContext } from "apollo-link-context";

import NetworkErrorContext from "../networkErrorContext";

const isProduction = process.env.NODE_ENV === "production";

const EAMAprolloProvider = ({ children }: { children: React.ReactNode }) => {
  const [networkError, handleNetworkError] = useState<Error | undefined>(
    undefined
  );

  // Authentication headers for graphql server azuread auth
  const authLink = setContext((_, { headers, context }) => {
    let token = localStorage.getItem("token");
    return {
      headers: {
        ...headers,
        ...(token ? { authorization: `Bearer ${token}` } : {}),
      },
      ...context,
    };
  });

  const errorLink = onError(({ graphQLErrors, networkError, response }) => {
    if (graphQLErrors) {
      graphQLErrors.forEach(({ message, locations, path }) =>
        console.error(
          `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
        )
      );
      if (response) {
      }
    } else if (networkError) {
      // @ts-ignore
      if (networkError.statusCode && networkError.statusCode === 401) {
        handleNetworkError(
          new Error("Your login session has expired, please log in again")
        );
        // If user is unauthorized, remove token from localStorage which redirects to login page
        localStorage.removeItem("token");
      } else {
        handleNetworkError(networkError);
      }
      console.error(`[Network error]: ${networkError}`);
    }
  });

  const uploadLink = createUploadLink({
    uri: isProduction ? "/graphql" : "http://localhost:4000/graphql",
    credentials: "include",
  });

  // @ts-ignore
  const link = ApolloLink.from([authLink, errorLink, uploadLink]);
  const client = new ApolloClient({
    link,
    cache: new InMemoryCache(),
  });

  return (
    <ApolloProvider client={client}>
      <NetworkErrorContext.Provider
        value={{
          networkError,
          resetError: () => handleNetworkError(undefined),
        }}
      >
        {children}
      </NetworkErrorContext.Provider>
    </ApolloProvider>
  );
};

export default EAMAprolloProvider;
