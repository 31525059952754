import React, { Ref } from "react";
import ReactAsyncSelect, {
  Props as AsyncProps,
  Async,
} from "react-select/async";
import ReactSelect, {
  Props as SelectProps,
  Styles,
  components,
} from "react-select";

import messages from "../../translations/commonMessages";
import customTheme from "../../theme";
import { Box, BoxProps, Text, useTheme } from "@chakra-ui/core";
import { useIntl, IntlShape } from "react-intl";
import Loading from "./Loading";
import { Option } from "react-select/src/filters";
import StateManager from "react-select";

export type SelectOption = {
  value: string;
  label: string;
  emailAddress?: string;
  SecondaryMeasurementUnit?: string | null | undefined;
};

const formatStyles = (
  theme: "primary" | "secondary",
  isDisabled?: boolean,
  isHighlighted?: boolean
): Styles => {
  const primaryRegular =
    theme === "primary"
      ? customTheme.colors.gray[300]
      : customTheme.colors.primary.regular;
  const primaryDark =
    theme === "primary"
      ? customTheme.colors.black
      : customTheme.colors.primary.dark;
  const primaryLight =
    theme === "primary"
      ? customTheme.colors.gray[200]
      : customTheme.colors.green[200];
  const primaryVeryLight =
    theme === "primary"
      ? customTheme.colors.gray[100]
      : customTheme.colors.green[100];
  return {
    option: (baseStyles, state) => ({
      ...baseStyles,
      fontWeight: "normal",
      fontSize: customTheme.fontSizes.sm,
      color:
        state.isFocused && theme !== "primary"
          ? customTheme.colors.white
          : "black",
      backgroundColor: state.isFocused ? primaryRegular : "white",
      ":hover": {
        color: theme === "primary" ? "black" : "white",
        backgroundColor: primaryRegular,
      },
    }),
    singleValue: (baseStyles) => ({
      ...baseStyles,
      color: isDisabled
        ? isHighlighted
          ? customTheme.colors.black
          : customTheme.colors.gray[500]
        : customTheme.colors.black,
      fontSize: customTheme.fontSizes.sm,
    }),
    multiValue: (baseStyles) => ({
      ...baseStyles,
      backgroundColor: primaryVeryLight,
      borderRadius: customTheme.radii.md,
      px: customTheme.space["4"],
      color: primaryDark,
    }),
    multiValueLabel: (baseStyles) => ({
      ...baseStyles,
      color: primaryDark,
      fontWeight: "normal",
    }),
    multiValueRemove: (baseStyles) => ({
      ...baseStyles,
      ":hover": {
        backgroundColor: primaryLight,
        borderTopRightRadius: customTheme.radii.md,
        borderBottomRightRadius: customTheme.radii.md,
      },
      display: isDisabled ? "none" : "block",
    }),
    dropdownIndicator: (baseStyles) => ({
      ...baseStyles,
      color: primaryRegular,
      ":hover": {
        color: primaryDark,
      },
    }),
    control: (baseStyles) => ({
      ...baseStyles,
      borderColor: isHighlighted
        ? customTheme.colors.primary.regular
        : primaryRegular,
      borderRadius: customTheme.radii.sm,
      borderWidth: "1px",
      boxShadow: "none",
      backgroundColor: isHighlighted
        ? customTheme.colors.green[50]
        : isDisabled
        ? customTheme.colors.gray[50]
        : customTheme.colors.white,

      ":hover": {
        borderColor: isHighlighted
          ? customTheme.colors.primary.regular
          : primaryRegular,
      },
    }),
    input: (baseStyles) => ({
      ...baseStyles,
    }),
    menu: (baseStyles, state) => ({
      ...baseStyles,
      marginTop: "-2px",
      borderTopLeftRadius: "0",
      borderTopRightRadius: "0",
      borderColor: isHighlighted
        ? customTheme.colors.primary.regular
        : primaryRegular,
      borderWidth: "1px",
    }),
    menuList: (baseStyles, state) => ({
      ...baseStyles,
      paddingTop: "0",
      paddingBottom: "0",
      marginTop: "0",
      backgroundColor: isDisabled
        ? customTheme.colors.gray[100]
        : "transparent",
      borderColor: primaryRegular,
      opacity: isDisabled ? "0.7" : "1",
    }),
    indicatorSeparator: (baseStyles) => ({
      ...baseStyles,
      backgroundColor: customTheme.colors.gray[50],
    }),
    clearIndicator: (baseStyles, state) => ({
      ...baseStyles,
      color: state.isFocused ? primaryRegular : primaryRegular,
      ":hover": {
        color: primaryDark,
      },
    }),
    placeholder: (baseStyles) => ({
      ...baseStyles,
      marginLeft: customTheme.space[2],
      fontWeight: "normal",
      color: customTheme.colors.gray[300],
    }),
    noOptionsMessage: (baseStyles) => ({
      ...baseStyles,
      fontWeight: "normal",
      backgroundColor: customTheme.colors.white,
      color: customTheme.colors.black,
    }),
  };
};

const formatBaseSelectProps = (
  {
    selectRef,
    colorTheme = "primary",
    listLabel,
    components,
    ...rest
  }: CustomSelectProps,
  intl: IntlShape
): CustomSelectProps => {
  return {
    isSearchable: true,
    isClearable: true,
    closeMenuOnSelect: false,
    theme: (theme) => ({
      ...theme,
      colors: {
        ...theme.colors,
        primary: customTheme.colors.primary.regular,
        primary25: customTheme.colors.green[300] || theme.colors.primary25,
        neutral0: customTheme.colors.white,
      },
    }),
    styles: formatStyles(
      colorTheme || "primary",
      rest.isDisabled,
      rest.isHighlighted
    ),
    noOptionsMessage: rest.noOptionsMessage
      ? rest.noOptionsMessage
      : () => intl.formatMessage(messages.noOptionsMessage),
    placeholder: rest.placeholder
      ? rest.placeholder
      : intl.formatMessage(messages.select),
    ref: selectRef,
    components: {
      MenuList: (props: any) => (
        <MenuList listLabel={listLabel} colorTheme={colorTheme} {...props} />
      ),
      ...components,
    },
    ...rest,
  };
};

export type CustomSelectProps = SelectProps<SelectOption> & {
  containerProps?: BoxProps;
  selectRef?: Ref<Async<SelectOption>> | Ref<StateManager<SelectOption>>;
  colorTheme?: "primary" | "secondary";
  listLabel?: string;
  isHighlighted?: boolean;
};

export type MergedAsyncProps = AsyncProps<SelectOption> & CustomSelectProps;

const AsyncSelect = ({
  containerProps,
  loadOptions,
  ...rest
}: MergedAsyncProps) => {
  const intl = useIntl();
  return (
    <Box {...containerProps}>
      <ReactAsyncSelect
        loadOptions={loadOptions}
        {...formatBaseSelectProps(rest, intl)}
      />
    </Box>
  );
};

AsyncSelect.defaultProps = {
  defaultOptions: true,
};

const Select = ({ containerProps, ...rest }: CustomSelectProps) => {
  const intl = useIntl();

  // Custom filter for single select, default not working for some reason
  const filterSingleOption = (option: Option, rawInput: string) => {
    if (!option.label.toLowerCase().includes(rawInput.toLowerCase()))
      return false;
    if (rest.value) {
      return !((rest.value as SelectOption).value === option.value);
    }
    return true;
  };

  return (
    <Box {...containerProps}>
      <ReactSelect
        {...formatBaseSelectProps(rest, intl)}
        filterOption={rest.isMulti ? undefined : filterSingleOption}
      />
    </Box>
  );
};

const MenuList = (props: any) => {
  const theme = useTheme();
  const loadingColor =
    props.colorTheme === "primary"
      ? theme.colors.black
      : theme.colors.primary.regular;
  if (props.isLoading)
    return (
      <Loading
        color={loadingColor}
        fontSize="sm"
        p="3"
        justifyContent="center"
        spinnerProps={{ size: "md" }}
        fontWeight="normal"
      />
    );
  return (
    <components.MenuList {...props}>
      {props.listLabel && (
        <Box
          p="2"
          bg="white"
          borderBottomWidth="1px"
          borderBottomColor="gray.300"
        >
          <Text fontSize="sm" fontWeight="normal">
            {props.listLabel}
          </Text>
        </Box>
      )}
      {props.children}
    </components.MenuList>
  );
};

export { AsyncSelect, Select };
