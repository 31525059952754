import React, { useState, useEffect, useCallback, useRef } from "react";
import { SelectOption, MergedAsyncProps, Select } from "./primitives/Select";
import StateManager, {
  ValueType,
  InputActionMeta,
  ActionMeta,
} from "react-select";
import { ApolloQueryResult, useApolloClient } from "@apollo/client";
import { GetCodeListItemsResult, GET_CODE_LIST_ITEMS } from "../generalQueries";
import { Modify } from "../types/UtilTypes";
import { useIntl } from "react-intl";
import { Text } from "@chakra-ui/core";
import { CodeListResult } from "@pravda/gdsn-admin-backend/lib/types/code-list";

export type CodeListSelectProps = Modify<
  MergedAsyncProps,
  {
    codeListId: string;
    initialValue?: string | string[] | null;
    onChange?: (value: ValueType<SelectOption>) => void;
    hasError?: boolean;
  }
>;

export type CodeListSelectMeta = {
  listLabel?: string;
  total: number;
  limit: number;
};

const CodeListSelect = ({
  codeListId,
  onChange,
  initialValue,
  hasError,
  ...rest
}: CodeListSelectProps) => {
  const [selected, setSelected] = useState<ValueType<SelectOption>>();
  const [options, setOptions] = useState<SelectOption[]>([]);
  const [loading, setLoading] = useState(false);
  const [meta, setMeta] = useState<CodeListSelectMeta>({ total: 0, limit: 0 });
  const selectRef = useRef<StateManager>(null);
  // To fix setting state on unmounted component error
  const [isMounted, setIsMounted] = useState(false);
  const [error, setError] = useState<string>();
  const client = useApolloClient();
  const intl = useIntl();

  const queryCodeListItems = useCallback(
    async (
      keyword?: string,
      includedCodes?: string[],
      excludedCodes?: string[],
      limit?: number
    ): Promise<CodeListResult> => {
      const filters = {
        keyword,
        includedCodes,
        excludedCodes,
        locale: intl.locale,
      };
      try {
        if (isMounted) {
          setLoading(true);
        }
        const result: ApolloQueryResult<GetCodeListItemsResult> = await client.query(
          {
            query: GET_CODE_LIST_ITEMS,
            variables: { codeListId, filters, limit },
          }
        );
        const data = result.data?.getCodeListItems;
        if (data) {
          if (isMounted) {
            setLoading(false);
            setError(undefined);
          }
          return data;
        } else throw new Error("Something went wrong with apollo query");
      } catch (error) {
        if (isMounted) {
          setLoading(false);
        }
        return Promise.reject(error);
      }
    },
    [client, codeListId, isMounted]
  );

  const setSelectMeta = useCallback(
    (newMeta: CodeListSelectMeta) => {
      const mergedMeta: CodeListSelectMeta = {
        ...meta,
        ...newMeta,
      };

      if (mergedMeta.total > mergedMeta.limit) {
        mergedMeta.listLabel = intl.formatMessage(
          {
            id: "codeListSelect.missingResultsTitle",
            defaultMessage:
              "There are {amount} results in total with given keyword of which {limit} are showing. Specify keyword to see the rest of the options",
          },
          { amount: mergedMeta.total, limit: mergedMeta.limit }
        );
      } else {
        mergedMeta.listLabel = undefined;
      }
      setMeta(mergedMeta);
    },
    [intl, meta]
  );

  const mapCodeListItems = (data: CodeListResult) => {
    const codeListItems: SelectOption[] = data.result.map<SelectOption>(
      (item) => ({
        value: item.code,
        // @ts-ignore
        label: item.label[intl.locale] || item.label.fi,
      })
    );
    return codeListItems;
  };

  const setFetchError = (error: Error | string) => {
    setError(
      intl.formatMessage(
        {
          id: "codeListSelect.errorInFetch",
          defaultMessage:
            "Something went wrong in fetching code list items: {error}",
        },
        {
          error: typeof error === "string" ? error : error.message,
        }
      )
    );
  };

  const loadOptions = useCallback(
    async (keyword: string, excludedCodes?: string[]) => {
      const selectedCodes = selected
        ? Array.isArray(selected)
          ? selected.map((item) => item.value)
          : [(selected as SelectOption).value]
        : [];
      const excluded = excludedCodes ? excludedCodes : selectedCodes;
      const defaultLimit = 50;
      try {
        const result = await queryCodeListItems(
          keyword,
          undefined,
          undefined,
          defaultLimit + excluded.length
        );
        setSelectMeta({ total: result.total, limit: defaultLimit });
        setOptions(mapCodeListItems(result));
      } catch (error) {
        console.log(error.message);

        if (isMounted) {
          setFetchError(error);
        }
      }
    },
    [intl, isMounted, queryCodeListItems, selected, setSelectMeta]
  );

  const checkInitialValueIsEqual = useCallback((): boolean => {
    if (Array.isArray(initialValue)) {
      if (Array.isArray(selected)) {
        return (
          initialValue.every((id) =>
            selected.some((item: SelectOption) => id === item.value)
          ) && initialValue.length === selected.length
        );
      }
      return false;
    } else if (initialValue != null) {
      if (initialValue.length === 0 && !selected) return true;
      if (selected && !Array.isArray(selected)) {
        return initialValue === (selected as SelectOption).value;
      }
    }
    return initialValue == null && selected == null;
  }, [initialValue, selected]);

  useEffect(() => {
    const loadSelectedItems = async () => {
      let items: SelectOption[] = [];
      // Get initial items
      if (initialValue) {
        try {
          const result = await queryCodeListItems(
            undefined,
            Array.isArray(initialValue) ? initialValue : [initialValue],
            undefined,
            9999
          );
          items = mapCodeListItems(result);
        } catch (error) {
          setFetchError(error);
        }
        loadOptions(
          "",
          items.map((item) => item.value)
        );
        setSelected(rest.isMulti ? items : items[0]);
      }
      // Filter out initial items from options and set selected
      else {
        loadOptions(
          "",
          items.map((item) => item.value)
        );
        setSelected(rest.isMulti ? items : items[0]);
      }
    };
    if (!checkInitialValueIsEqual() || initialValue === null) {
      loadSelectedItems();
    }

    if (!isMounted) setIsMounted(true);
  }, [initialValue, codeListId]);

  const onSelectChange = (
    option: ValueType<SelectOption>,
    actionMeta: ActionMeta<SelectOption>
  ) => {
    // Manually set new limit without loading new results
    switch (actionMeta.action) {
      case "select-option":
        setSelectMeta({ total: meta.total, limit: meta.limit - 1 });
        break;
      case "remove-value":
      case "pop-value":
        if (actionMeta.removedValue)
          setSelectMeta({ total: meta.total, limit: meta.limit + 1 });
        break;
      case "clear":
        // If select is single select, close menu on clear
        if (!rest.isMulti && selectRef.current) {
          selectRef.current.select.blur();
        }
        break;
    }

    setSelected(option);
    if (onChange) onChange(option);
  };

  const onInputChange = (newValue: string, actionMeta: InputActionMeta) => {
    // Reload options when input is changed or menu is closed
    if (
      actionMeta.action === "menu-close" ||
      actionMeta.action === "input-change"
    ) {
      loadOptions(newValue);
    }
  };

  return (
    <>
      <Select
        options={options}
        value={selected}
        onChange={onSelectChange}
        isLoading={loading}
        onInputChange={onInputChange}
        listLabel={meta.listLabel}
        // filterOption={filterOption}
        selectRef={selectRef}
        {...rest}
      />
      {error && (
        <Text color="red.600" mt="2" fontSize="sm" fontWeight="normal">
          {error}
        </Text>
      )}
    </>
  );
};

export default CodeListSelect;
