import React from "react";
import styled from "styled-components/macro";
import { Box, BoxProps, Icon } from "@chakra-ui/core";
import { differenceInDays } from "date-fns";
import { FormattedDate } from "react-intl";
interface DeadlineProps extends BoxProps {
  date: Date;
}
const StyledDeadline = styled(Box)<BoxProps>`
  svg {
    margin-left: 5px;
    margin-bottom: 4px;
    font-size: ${({ theme }) => theme.fontSizes.sm};
    line-height: ${({ theme }) => theme.lineHeights.short};
  }
`;

const DeadlineLabel = styled(Box)<BoxProps>``;

DeadlineLabel.defaultProps = {
  display: "inline-block",
  lineHeight: "short",
  padding: 0,
};

StyledDeadline.defaultProps = {
  display: "block",
  fontWeight: "normal",
  lineHeight: "short",
  fontSize: "xs",
};

const Deadline = (props: DeadlineProps) => {
  let someProps = {};
  let date = props.date;
  let iconVisible = false;
  const now = Date.now();

  if (differenceInDays(date, now) <= 2) {
    someProps = {
      color: "red.600",
      fontWeight: "bold",
    };
    iconVisible = true;
  }
  if (differenceInDays(date, now) > 2 && differenceInDays(date, now) <= 4) {
    someProps = {
      color: "orange.400",
      fontWeight: "bold",
    };
    iconVisible = true;
  }
  const changedProps = { ...props, ...someProps };
  return (
    <StyledDeadline {...changedProps}>
      <DeadlineLabel>
        <FormattedDate
          value={date}
          year="numeric"
          month="numeric"
          day="numeric"
        ></FormattedDate>
      </DeadlineLabel>
      {iconVisible && <Icon name="warning-2" />}
    </StyledDeadline>
  );
};

export { Deadline };
