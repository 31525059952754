import React from "react";
import Navigation from "components/Navigation";
import { Box } from "@chakra-ui/core";

const ViewContainer = ({
  children,
  ...rest
}: {
  children?: React.ReactNode;
}) => {
  return (
    <Box {...rest}>
      <Navigation />
      {children}
    </Box>
  );
};

export default ViewContainer;
