import React from "react";
import { PrimaryInputProps, PrimaryInput } from "./primitives/Input";
import { Modify } from "types/UtilTypes";
import { InputGroup, InputRightAddon } from "@chakra-ui/core";

type NumberInputProps = Modify<
  PrimaryInputProps,
  {
    onChange?: (value: string) => void;
    inputType?: "int" | "decimal";
    postFix?: string;
  }
>;

const NumberInput = ({
  onChange = () => {},
  inputType = "int",
  postFix,
  ...rest
}: NumberInputProps) => {
  let dynamicOnChange;
  switch (inputType) {
    case "decimal":
      dynamicOnChange = (event: React.FormEvent<HTMLInputElement>) => {
        const decimalRegex = new RegExp(
          /^-?((0(\.[0-9]*)?)|([0-9][0-9]*\.?[0-9]*))?$/
        );
        const value = event.currentTarget.value;
        // Convert -0x pattern to -x or 0x
        if (/^-?0[1-9]$/.test(value)) {
          onChange(parseFloat(value).toString());
        }
        if (decimalRegex.test(value)) {
          onChange(value);
        }
      };
      break;
    default:
      dynamicOnChange = (event: React.FormEvent<HTMLInputElement>) => {
        let value = event.currentTarget.value;
        const intRegex = new RegExp(/^-?[0-9]*$/);
        if (value === "-") {
          onChange(value);
        } else if (intRegex.test(value)) {
          const parsedValue = parseInt(value);
          onChange(!isNaN(parsedValue) ? parsedValue.toString() : "");
        }
      };
  }
  if (postFix)
    return (
      <InputGroup>
        <PrimaryInput
          type="text"
          inputMode="numeric"
          onChange={dynamicOnChange}
          borderTopRightRadius="0"
          borderBottomRightRadius="0"
          {...rest}
        />
        <InputRightAddon children={postFix} fontSize="sm" p="3" />
      </InputGroup>
    );
  return (
    <PrimaryInput
      type="text"
      inputMode="numeric"
      onChange={dynamicOnChange}
      {...rest}
    />
  );
};

export default NumberInput;
