import theme from "../theme";

const isMobileViewport = (window: Window) => {
  // Calculate lg breakpoint in em * base font size
  const lgBreakpoint =
    parseInt(theme.breakpoints.lg) * parseInt(theme.fontSizes.baseFontSize);
  return window.innerWidth < lgBreakpoint;
};

export { isMobileViewport };
