import React from "react";
import { createGlobalStyle } from "styled-components/macro";
import { CSSReset } from "@chakra-ui/core";
import { BrowserRouter } from "react-router-dom";
import RootRoutes from "./routes/RootRoutes";

type GlobalStyleProps = {
  showSidebar: boolean;
};

const App = ({ showSidebar }: { showSidebar: boolean }) => {
  const GlobalStyle = createGlobalStyle<GlobalStyleProps>`
    html, body {
      height: 100%;
    }
    body {
      hyphens: auto;
      ${({ theme, showSidebar }) =>
        showSidebar &&
        `
          font-size: ${theme.fontSizes.baseFontSize};
          overflow: hidden;
          
          @media screen and (min-width: ${theme.breakpoints.md}) {
            overflow: auto;
          }
        `}
    }
    #root {
      min-height: 100%;
      background: ${({ theme }) => theme.colors.background};
    }
  `;
  return (
    <BrowserRouter>
      <GlobalStyle showSidebar={showSidebar} />
      <CSSReset />
      <RootRoutes />
    </BrowserRouter>
  );
};

export default App;
