import React, { useState, useEffect } from "react";
import { useDebouncedCallback } from "use-debounce/lib";
import { format } from "date-fns";
import {
  Field,
  FieldType,
  StringFieldData,
  TextFieldData,
  IntFieldData,
  DecimalFieldData,
  DateFieldData,
  SelectFieldData,
  MultiSelectFieldData,
  BoolFieldData,
  MetricInputFieldData,
  PackageHierarchiesData,
  PackageHierarchy,
} from "@pravda/gdsn-admin-backend/lib/types/product";
import { PrimaryInput } from "components/primitives/Input";
import { PrimaryTextarea } from "components/primitives/Textarea";
import { RadioGroup, InputProps, Box } from "@chakra-ui/core";
import { PrimaryRadio } from "components/primitives/Radio";
import messages from "translations/commonMessages";
import { useIntl } from "react-intl";
import ProductUtil from "utils/ProductUtil";
import CodeListSelect from "components/CodeListSelect";
import { SelectOption } from "components/primitives/Select";
import NumberInput from "components/NumberInput";
import {
  PackageHierarchyTable,
  PackageHierarchyTableHeader,
  PackageHierarchyTableTr,
} from "components/PackageHierarchyTable";
import { AllowedLanguagesType } from "translations/locales";

export type ProductFormInputProps = InputProps & {
  field: Field;
  renderOldValue?: boolean;
  onDataChange?: (field: Field, newValue: any) => void;
};

const ProductFormInput = ({
  field,
  onDataChange = () => {},
  renderOldValue,
  value: restValue,
  onChange: restOnChange,
  ...rest
}: ProductFormInputProps): JSX.Element | null => {
  const intl = useIntl();
  const locale: AllowedLanguagesType = intl.locale as AllowedLanguagesType;
  const [inputValue, setInputValue] = useState<string>("");
  const [hasRendered, setHasRendered] = useState(false);
  const [debouncedDataChange] = useDebouncedCallback(
    (field: Field, newValue: any) => {
      onDataChange(field, newValue);
    },
    250
  );

  const onInputChange = (field: Field, newValue: string) => {
    setInputValue(newValue);
    debouncedDataChange(field, newValue);
  };

  useEffect(() => {
    if (!hasRendered) {
      // @ts-ignore
      const value = ProductUtil.getFieldDataValue(field);
      setInputValue(value != null ? value.toString() : "");
      setHasRendered(true);
    }
  }, [inputValue, field.data, field.type, hasRendered, field]);

  const isNewValueReadOnly = field.approved === false || !field.editable;
  switch (field.type) {
    case FieldType.String: {
      const fieldData: StringFieldData = field.data as StringFieldData;
      if (renderOldValue)
        return (
          <PrimaryInput
            type="text"
            id={`${field.path}-old`}
            value={fieldData.stringOldValue || ""}
            isHighlighted={field.approved === false}
            isReadOnly
            {...rest}
          />
        );
      return (
        <PrimaryInput
          type="text"
          id={field.path}
          value={inputValue ? inputValue : ""}
          maxLength={field.path.includes("ProductName") ? "40" : ""}
          onChange={(event: React.FormEvent<HTMLInputElement>) =>
            onInputChange(field, event.currentTarget.value)
          }
          isHighlighted={field.approved === true}
          isOverlined={field.approved === false}
          isReadOnly={isNewValueReadOnly}
          hasError={!!field.error && field.approved !== false}
          {...rest}
        />
      );
    }
    case FieldType.Text: {
      const fieldData: TextFieldData = field.data as TextFieldData;
      if (renderOldValue)
        return (
          <PrimaryTextarea
            type="text"
            id={`${field.path}-old`}
            value={fieldData.textOldValue || ""}
            isHighlighted={isNewValueReadOnly}
            isReadOnly
            {...rest}
          />
        );
      return (
        <PrimaryTextarea
          id={field.path}
          value={inputValue}
          onChange={(event: React.FormEvent<HTMLInputElement>) =>
            onInputChange(field, event.currentTarget.value)
          }
          isHighlighted={field.approved === true}
          isOverlined={field.approved === false}
          isReadOnly={isNewValueReadOnly}
          hasError={!!field.error && field.approved !== false}
          {...rest}
        />
      );
    }
    case FieldType.Int: {
      const fieldData: IntFieldData = field.data as IntFieldData;
      if (renderOldValue)
        return (
          <NumberInput
            id={`${field.path}-old`}
            value={fieldData.intOldValue || ""}
            isHighlighted={field.approved === false}
            isReadOnly
            {...rest}
          />
        );
      return (
        <NumberInput
          id={field.path}
          value={inputValue}
          onChange={(value: string) => {
            onInputChange(field, value);
          }}
          isHighlighted={field.approved === true}
          isOverlined={field.approved === false}
          isReadOnly={isNewValueReadOnly}
          hasError={!!field.error && field.approved !== false}
          {...rest}
        />
      );
    }

    case FieldType.Decimal: {
      const fieldData: DecimalFieldData = field.data as DecimalFieldData;
      if (renderOldValue)
        return (
          <NumberInput
            inputType="decimal"
            id={`${field.path}-old`}
            value={fieldData.decimalOldValue || ""}
            isHighlighted={isNewValueReadOnly}
            isReadOnly
            {...rest}
          />
        );
      return (
        <NumberInput
          inputType="decimal"
          id={field.path}
          value={inputValue}
          onChange={(value: string) => {
            onInputChange(field, value);
          }}
          isHighlighted={field.approved === true}
          isOverlined={field.approved === false}
          isReadOnly={isNewValueReadOnly}
          hasError={!!field.error && field.approved !== false}
          {...rest}
        />
      );
    }

    case FieldType.Date: {
      const fieldData: DateFieldData = field.data as DateFieldData;
      if (renderOldValue)
        return (
          <PrimaryInput
            type="date"
            id={field.path}
            value={
              fieldData.dateOldValue
                ? format(new Date(fieldData.dateOldValue), "yyyy-MM-dd")
                : ""
            }
            isHighlighted={field.approved === false}
            isReadOnly
            {...rest}
          />
        );
      return (
        <PrimaryInput
          type="date"
          id={field.path}
          min="1900-01-01"
          max="2100-12-31"
          value={inputValue ? format(new Date(inputValue), "yyyy-MM-dd") : ""}
          onChange={(event: React.FormEvent<HTMLInputElement>) => {
            onInputChange(field, event.currentTarget.value);
          }}
          isHighlighted={field.approved === true}
          isOverlined={field.approved === false}
          isReadOnly={isNewValueReadOnly}
          hasError={!!field.error && field.approved !== false}
          {...rest}
        />
      );
    }

    case FieldType.Bool: {
      const fieldData: BoolFieldData = field.data as BoolFieldData;
      const isOldValueDisabled =
        field.approved == null || field.approved === true;
      const isNewValueDisabled = field.approved === false;
      if (renderOldValue)
        return (
          <RadioGroup
            value={
              fieldData.boolOldValue != null
                ? fieldData.boolOldValue.toString()
                : undefined
            }
            isInline
            {...{ ...rest, defaultValue: undefined }}
          >
            <PrimaryRadio
              value="true"
              isDisabled={isOldValueDisabled}
              pointerEvents="none"
            >
              {intl.formatMessage(messages.yes)}
            </PrimaryRadio>
            <PrimaryRadio
              value="false"
              isDisabled={isOldValueDisabled}
              variantColor="red"
              pointerEvents="none"
            >
              {intl.formatMessage(messages.no)}
            </PrimaryRadio>
          </RadioGroup>
        );
      return (
        // This could be separated to own component
        <RadioGroup
          onChange={(event: React.FormEvent<HTMLInputElement>) => {
            onInputChange(field, event.currentTarget.value);
          }}
          value={inputValue}
          isInline
          {...{ ...rest, defaultValue: undefined }}
        >
          <PrimaryRadio
            value="true"
            isDisabled={isNewValueDisabled}
            pointerEvents={isNewValueReadOnly ? "none" : "auto"}
          >
            {intl.formatMessage(messages.yes)}
          </PrimaryRadio>
          <PrimaryRadio
            value="false"
            isDisabled={isNewValueDisabled}
            pointerEvents={isNewValueReadOnly ? "none" : "auto"}
            variantColor="red"
          >
            {intl.formatMessage(messages.no)}
          </PrimaryRadio>
        </RadioGroup>
      );
    }

    case FieldType.Select: {
      const fieldData: SelectFieldData = field.data as SelectFieldData;
      if (renderOldValue)
        return (
          <CodeListSelect
            codeListId={fieldData.codeListId}
            initialValue={fieldData.selectOldValue}
            isDisabled={true}
            isHighlighted={field.approved === false}
            closeMenuOnSelect
          />
        );
      return (
        <CodeListSelect
          codeListId={fieldData.codeListId}
          initialValue={fieldData.selectNewValue}
          onChange={(value) =>
            onInputChange(
              field,
              !Array.isArray(value) && value
                ? (value as SelectOption).value
                : ""
            )
          }
          isDisabled={isNewValueReadOnly}
          isHighlighted={field.approved === true}
          hasError={!!field.error && field.approved !== false}
          containerProps={rest}
          closeMenuOnSelect
        />
      );
    }

    case FieldType.MultiSelect: {
      const fieldData: MultiSelectFieldData = field.data as MultiSelectFieldData;
      if (renderOldValue) {
        return (
          <CodeListSelect
            codeListId={fieldData.codeListId}
            initialValue={fieldData.multiselectOldValue}
            isDisabled={true}
            isHighlighted={field.approved === false}
            containerProps={rest}
            isMulti
          />
        );
      }
      return (
        <CodeListSelect
          codeListId={fieldData.codeListId}
          initialValue={fieldData.multiselectNewValue}
          onChange={(value) =>
            debouncedDataChange(
              field,
              Array.isArray(value)
                ? value.map((option: SelectOption) => option.value)
                : []
            )
          }
          isDisabled={isNewValueReadOnly}
          isHighlighted={field.approved === true}
          containerProps={rest}
          hasError={!!field.error && field.approved !== false}
          isMulti
        />
      );
    }

    case FieldType.Metric: {
      const fieldData: MetricInputFieldData = field.data as MetricInputFieldData;
      if (renderOldValue) {
        return (
          <NumberInput
            id={`${field.path}-old`}
            value={fieldData.metricInputOldValue || ""}
            isHighlighted={field.approved === false}
            isReadOnly
            borderTopRightRadius="0"
            borderBottomRightRadius="0"
            postFix={fieldData.factor}
            {...rest}
          />
        );
      }
      return (
        <NumberInput
          id={field.path}
          value={inputValue}
          onChange={(value: string) => {
            onInputChange(field, value);
          }}
          isHighlighted={field.approved === true}
          isOverlined={field.approved === false}
          isReadOnly={isNewValueReadOnly}
          hasError={!!field.error && field.approved !== false}
          postFix={fieldData.factor}
          {...rest}
        />
      );
    }

    case FieldType.PackageHierarchies: {
      const fieldData: PackageHierarchiesData = field.data as PackageHierarchiesData;
      const firstHierarchy: PackageHierarchy | null = fieldData.hierarchies[0];
      if (firstHierarchy) {
        return (
          <>
            {firstHierarchy.hierarchy.map((hierarchyPackage, index) => (
              <Box key={`${hierarchyPackage.gtin}${index}`}>
                <PackageHierarchyTableHeader>
                  {`${intl.formatMessage({ id: hierarchyPackage.type })}${
                    hierarchyPackage.gtin
                      ? `, GTIN: ${hierarchyPackage.gtin}`
                      : ``
                  }`}
                </PackageHierarchyTableHeader>
                <PackageHierarchyTable>
                  {hierarchyPackage.fields.map((field) => (
                    <PackageHierarchyTableTr
                      key={field.path}
                      fieldLabel={field.label[locale] || field.path}
                      newValue={ProductUtil.getFieldDataValue(field)}
                      oldValue={ProductUtil.getFieldDataValue(field, "old")}
                    />
                  ))}
                </PackageHierarchyTable>
              </Box>
            ))}
          </>
        );
      } else return null;
    }

    default:
      return null;
  }
};

export default ProductFormInput;
