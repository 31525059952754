// This file was generated with Diez - https://diez.org
// Do not edit this file directly.

module.exports = {};

if (typeof process === 'undefined' || !process) {
  process = {env: {}};
} else if (!process.env) {
  Object.defineProperty(process, 'env', {
    value: {},
  });
}

const Environment = {
  serverUrl: process.env.DIEZ_SERVER_URL || '/diez',
  isHot: process.env.DIEZ_IS_HOT,
};

const diezHTMLExtensions = [];

class Diez {
  constructor (componentType) {
    if (typeof document !== 'undefined') {
      this.iframe = document.createElement('iframe');
    } else {
      this.iframe = {};
    }

    this.componentType = componentType;
    this.component = new this.componentType();
    this.subscribers = [];
  }

  static applyHTMLExtensions () {
    diezHTMLExtensions.forEach((extension) => {
      if (extension instanceof Function) {
        extension();
      }
    });
  }

  broadcast () {
    for (const subscriber of this.subscribers) {
      subscriber(this.component);
    }
  }

  subscribe (subscriber) {
    this.subscribers.push(subscriber);
  }

  attach (subscriber) {
    subscriber(this.component);
    if (!Environment.isHot) {
      return;
    }
    this.subscribe(subscriber);
    if (this.iframe.contentWindow) {
      return;
    }
    this.iframe.src = `${Environment.serverUrl}/components/${this.component.constructor.name}`;
    this.iframe.width = '0';
    this.iframe.height = '0';
    this.iframe.style.display = 'none';

    if (typeof document !== 'undefined') {
      document.body.appendChild(this.iframe);
      window.addEventListener('message', (event) => {
        if (event.source === this.iframe.contentWindow && event.origin.startsWith(Environment.serverUrl)) {
          this.component = new this.componentType(JSON.parse(event.data));
          this.broadcast();
        }
      });
    }
  }
}

module.exports.Diez = Diez;

/**
 * A component encapsulating color, including alpha transparency.
 * 
 * You can use the provided static constructors [[Color.rgb]], [[Color.rgba]], [[Color.hsl]], [[Color.hsla]], and
 * [[Color.hex]] to conveniently create color primitives using familiar patterns for color specification.
 *
 */
class Color {
  constructor({
    h,
    s,
    l,
    a
  }) {
    /**
     * Provides simple hue-saturation-lightness-alpha color data.
     *
     * 0
     */
    this.h = h;
    /**
     * Provides simple hue-saturation-lightness-alpha color data.
     *
     * 0
     */
    this.s = s;
    /**
     * Provides simple hue-saturation-lightness-alpha color data.
     *
     * 0.9647058823529412
     */
    this.l = l;
    /**
     * Provides simple hue-saturation-lightness-alpha color data.
     *
     * 1
     */
    this.a = a;
  }
}


module.exports.Color = Color;

const {colorToCss, colorToLowFidelityCss} = require('@diez/web-sdk-common');

Object.defineProperties(Color.prototype, {
  color: {
    get () {
      return colorToCss(this);
    },
  },
  lowFidelityColor: {
    get () {
      return colorToLowFidelityCss(this);
    },
  },
  colorStyle: {
    get () {
      return {
        color: this.color,
      };
    },
  },
  backgroundColorStyle: {
    get () {
      return {
        backgroundColor: this.color,
      };
    },
  },
  borderColorStyle: {
    get () {
      return {
        borderColor: this.color,
      };
    },
  },
  outlineColorStyle: {
    get () {
      return {
        outlineColor: this.color,
      };
    },
  },
});

class WihuriDesignSystemTokensColors {
  constructor({
    gray50 = {h: 0, s: 0, l: 0.9647058823529412, a: 1},
    green50 = {h: 0.2631578947368421, s: 0.575757575757576, l: 0.9352941176470588, a: 1},
    seaGreen50 = {h: 0.4035087719298245, s: 0.5135135135135143, l: 0.9274509803921569, a: 1},
    blue50 = {h: 0.5344827586206896, s: 0.6744186046511632, l: 0.9156862745098039, a: 1},
    red50 = {h: 0.96078431372549, s: 0.7391304347826096, l: 0.9549019607843137, a: 1},
    orange50 = {h: 0.1458333333333334, s: 1, l: 0.9529411764705882, a: 1},
    gray300 = {h: 0, s: 0, l: 0.7725490196078432, a: 1},
    green300 = {h: 0.26804123711340205, s: 0.5187165775401069, l: 0.6333333333333333, a: 1},
    seaGreen300 = {h: 0.4003436426116838, s: 0.4825870646766168, l: 0.6058823529411764, a: 1},
    blue300 = {h: 0.5405092592592592, s: 0.6545454545454545, l: 0.5686274509803921, a: 1},
    red300 = {h: 0.9913194444444445, s: 0.5454545454545455, l: 0.6549019607843137, a: 1},
    orange300 = {h: 0.14536340852130328, s: 1, l: 0.7392156862745098, a: 1},
    gray600 = {h: 0, s: 0, l: 0.403921568627451, a: 1},
    green600 = {h: 0.2764227642276423, s: 0.5616438356164384, l: 0.4294117647058823, a: 1},
    seaGreen600 = {h: 0.4083969465648855, s: 0.6787564766839378, l: 0.3784313725490196, a: 1},
    blue600 = {h: 0.5466321243523317, s: 1, l: 0.3784313725490196, a: 1},
    red600 = {h: 0.9988662131519274, s: 0.6475770925110133, l: 0.5549019607843138, a: 1},
    orange600 = {h: 0.13120567375886524, s: 0.9690721649484537, l: 0.6196078431372549, a: 1},
    gray900 = {h: 0, s: 0, l: 0.11372549019607843, a: 1},
    green900 = {h: 0.3354166666666667, s: 0.7017543859649124, l: 0.22352941176470587, a: 1},
    seaGreen900 = {h: 0.41935483870967744, s: 1, l: 0.18235294117647058, a: 1},
    blue900 = {h: 0.5809859154929577, s: 1, l: 0.2784313725490196, a: 1},
    red900 = {h: 0, s: 0.568075117370892, l: 0.4176470588235294, a: 1},
    orange900 = {h: 0.07260726072607261, s: 0.8706896551724138, l: 0.5450980392156862, a: 1},
    gray100 = {h: 0, s: 0, l: 0.9254901960784314, a: 1},
    green100 = {h: 0.262962962962963, s: 0.5294117647058819, l: 0.8333333333333333, a: 1},
    seaGreen100 = {h: 0.3983739837398374, s: 0.46067415730337063, l: 0.8254901960784313, a: 1},
    blue100 = {h: 0.5357142857142857, s: 0.6730769230769231, l: 0.7960784313725491, a: 1},
    red100 = {h: 0.9761904761904762, s: 0.7500000000000002, l: 0.8901960784313725, a: 1},
    orange100 = {h: 0.14655172413793102, s: 1, l: 0.8862745098039215, a: 1},
    gray400 = {h: 0.6000000000000004, s: 0.02923976608187141, l: 0.6647058823529413, a: 1},
    green400 = {h: 0.2692307692307692, s: 0.5154185022026431, l: 0.5549019607843138, a: 1},
    seaGreen400 = {h: 0.40373563218390807, s: 0.47154471544715454, l: 0.5176470588235295, a: 1},
    blue400 = {h: 0.5441176470588235, s: 0.6692913385826774, l: 0.5019607843137255, a: 1},
    red400 = {h: 0.9962962962962963, s: 0.6923076923076922, l: 0.6176470588235294, a: 1},
    orange400 = {h: 0.14492753623188406, s: 0.9877300613496933, l: 0.6803921568627451, a: 1},
    gray700 = {h: 0.6666666666666666, s: 0.006535947712418277, l: 0.3, a: 1},
    green700 = {h: 0.36350574712643674, s: 0.5576923076923076, l: 0.40784313725490196, a: 1},
    seaGreen700 = {h: 0.41036414565826335, s: 0.7212121212121212, l: 0.3235294117647059, a: 1},
    blue700 = {h: 0.5500963391136802, s: 1, l: 0.3392156862745098, a: 1},
    red700 = {h: 0.9951690821256038, s: 0.5433070866141733, l: 0.5019607843137255, a: 1},
    orange700 = {h: 0.11458333333333336, s: 0.9411764705882354, l: 0.6, a: 1},
    gray200 = {h: 0.16666666666666666, s: 0.013333333333333287, l: 0.8529411764705882, a: 1},
    green200 = {h: 0.2676056338028169, s: 0.5259259259259259, l: 0.7352941176470589, a: 1},
    seaGreen200 = {h: 0.3980099502487562, s: 0.4620689655172414, l: 0.7156862745098039, a: 1},
    blue200 = {h: 0.537878787878788, s: 0.6626506024096384, l: 0.6745098039215686, a: 1},
    red200 = {h: 0.9907407407407408, s: 0.5714285714285714, l: 0.7529411764705882, a: 1},
    orange200 = {h: 0.14583333333333331, s: 1, l: 0.8117647058823529, a: 1},
    gray500 = {h: 0, s: 0, l: 0.5843137254901961, a: 1},
    green500 = {h: 0.2699004975124378, s: 0.5447154471544715, l: 0.4823529411764706, a: 1},
    seaGreen500 = {h: 0.4084507042253522, s: 0.6574074074074073, l: 0.4235294117647059, a: 1},
    blue500 = {h: 0.5459183673469388, s: 0.8990825688073395, l: 0.42745098039215684, a: 1},
    red500 = {h: 0.004219409282700422, s: 0.7596153846153846, l: 0.592156862745098, a: 1},
    orange500 = {h: 0.1443850267379679, s: 0.9689119170984457, l: 0.6215686274509804, a: 1},
    gray800 = {h: 0, s: 0, l: 0.20392156862745098, a: 1},
    green800 = {h: 0.2962962962962963, s: 0.607361963190184, l: 0.3196078431372549, a: 1},
    seaGreen800 = {h: 0.41203703703703703, s: 0.7605633802816901, l: 0.2784313725490196, a: 1},
    blue800 = {h: 0.5698324022346369, s: 1, l: 0.3509803921568627, a: 1},
    red800 = {h: 0.9961538461538462, s: 0.5508474576271186, l: 0.4627450980392157, a: 1},
    orange800 = {h: 0.09778911564625851, s: 0.9158878504672898, l: 0.5803921568627451, a: 1},
    blackWhiteBlack = {h: 0, s: 0, l: 0, a: 1},
    brown50 = {h: 0.10752688172043008, s: 0.9393939393939396, l: 0.8705882352941177, a: 1},
    brown300 = {h: 0.09756097560975609, s: 0.3565217391304348, l: 0.4509803921568628, a: 1},
    brown600 = {h: 0.10486891385767788, s: 0.8989898989898991, l: 0.19411764705882353, a: 1},
    brown900 = {h: 0.06547619047619048, s: 1, l: 0.10980392156862745, a: 1},
    brown100 = {h: 0.09389671361502348, s: 0.5503875968992247, l: 0.7470588235294118, a: 1},
    brown400 = {h: 0.09883720930232559, s: 0.49425287356321834, l: 0.3411764705882353, a: 1},
    brown700 = {h: 0.10208333333333335, s: 1, l: 0.1568627450980392, a: 1},
    brown200 = {h: 0.09090909090909093, s: 0.37931034482758613, l: 0.6019607843137255, a: 1},
    brown500 = {h: 0.10573476702508962, s: 0.808695652173913, l: 0.22549019607843138, a: 1},
    brown800 = {h: 0.08695652173913043, s: 1, l: 0.13529411764705881, a: 1},
    brandGreenLight = {h: 0.3981481481481482, s: 0.4615384615384611, l: 0.8470588235294118, a: 1},
    brandGreenDark = {h: 0.4270833333333333, s: 0.4383561643835617, l: 0.28627450980392155, a: 1},
    brandBlueDark = {h: 0.5650623885918004, s: 1, l: 0.36666666666666664, a: 1},
    brandBrown = {h: 0.09090909090909093, s: 0.37931034482758613, l: 0.6019607843137255, a: 1},
    brandBlue = {h: 0.5466321243523317, s: 1, l: 0.3784313725490196, a: 1},
    brandBlueLight = {h: 0.5428571428571428, s: 0.6481481481481483, l: 0.5764705882352941, a: 1},
    red7001 = {h: 0, s: 0.568075117370892, l: 0.4176470588235294, a: 1},
    brandGreen = {h: 0.36350574712643674, s: 0.5576923076923076, l: 0.40784313725490196, a: 1},
    orange6001 = {h: 0.07260726072607261, s: 0.8706896551724138, l: 0.5450980392156862, a: 1},
    blackWhiteWhite = {h: 0, s: 0, l: 1, a: 1},
    blackWhiteWhite1 = {h: 0, s: 0, l: 1, a: 1},
    grayGray800 = {h: 0, s: 0, l: 0.20392156862745098, a: 1},
    blackWhiteBlack1 = {h: 0, s: 0, l: 0, a: 1},
    brandBrandGreen = {h: 0.36350574712643674, s: 0.5576923076923076, l: 0.40784313725490196, a: 1}
  } = {}) {
    /**
     * hsla(0, 0, 0.96, 1)
     */
    this.gray50 = new Color(gray50);
    /**
     * hsla(0.26, 0.58, 0.94, 1)
     */
    this.green50 = new Color(green50);
    /**
     * hsla(0.4, 0.51, 0.93, 1)
     */
    this.seaGreen50 = new Color(seaGreen50);
    /**
     * hsla(0.53, 0.67, 0.92, 1)
     */
    this.blue50 = new Color(blue50);
    /**
     * hsla(0.96, 0.74, 0.95, 1)
     */
    this.red50 = new Color(red50);
    /**
     * hsla(0.15, 1, 0.95, 1)
     */
    this.orange50 = new Color(orange50);
    /**
     * hsla(0, 0, 0.77, 1)
     */
    this.gray300 = new Color(gray300);
    /**
     * hsla(0.27, 0.52, 0.63, 1)
     */
    this.green300 = new Color(green300);
    /**
     * hsla(0.4, 0.48, 0.61, 1)
     */
    this.seaGreen300 = new Color(seaGreen300);
    /**
     * hsla(0.54, 0.65, 0.57, 1)
     */
    this.blue300 = new Color(blue300);
    /**
     * hsla(0.99, 0.55, 0.65, 1)
     */
    this.red300 = new Color(red300);
    /**
     * hsla(0.15, 1, 0.74, 1)
     */
    this.orange300 = new Color(orange300);
    /**
     * hsla(0, 0, 0.4, 1)
     */
    this.gray600 = new Color(gray600);
    /**
     * hsla(0.28, 0.56, 0.43, 1)
     */
    this.green600 = new Color(green600);
    /**
     * hsla(0.41, 0.68, 0.38, 1)
     */
    this.seaGreen600 = new Color(seaGreen600);
    /**
     * hsla(0.55, 1, 0.38, 1)
     */
    this.blue600 = new Color(blue600);
    /**
     * hsla(1, 0.65, 0.55, 1)
     */
    this.red600 = new Color(red600);
    /**
     * hsla(0.13, 0.97, 0.62, 1)
     */
    this.orange600 = new Color(orange600);
    /**
     * hsla(0, 0, 0.11, 1)
     */
    this.gray900 = new Color(gray900);
    /**
     * hsla(0.34, 0.7, 0.22, 1)
     */
    this.green900 = new Color(green900);
    /**
     * hsla(0.42, 1, 0.18, 1)
     */
    this.seaGreen900 = new Color(seaGreen900);
    /**
     * hsla(0.58, 1, 0.28, 1)
     */
    this.blue900 = new Color(blue900);
    /**
     * hsla(0, 0.57, 0.42, 1)
     */
    this.red900 = new Color(red900);
    /**
     * hsla(0.07, 0.87, 0.55, 1)
     */
    this.orange900 = new Color(orange900);
    /**
     * hsla(0, 0, 0.93, 1)
     */
    this.gray100 = new Color(gray100);
    /**
     * hsla(0.26, 0.53, 0.83, 1)
     */
    this.green100 = new Color(green100);
    /**
     * hsla(0.4, 0.46, 0.83, 1)
     */
    this.seaGreen100 = new Color(seaGreen100);
    /**
     * hsla(0.54, 0.67, 0.8, 1)
     */
    this.blue100 = new Color(blue100);
    /**
     * hsla(0.98, 0.75, 0.89, 1)
     */
    this.red100 = new Color(red100);
    /**
     * hsla(0.15, 1, 0.89, 1)
     */
    this.orange100 = new Color(orange100);
    /**
     * hsla(0.6, 0.03, 0.66, 1)
     */
    this.gray400 = new Color(gray400);
    /**
     * hsla(0.27, 0.52, 0.55, 1)
     */
    this.green400 = new Color(green400);
    /**
     * hsla(0.4, 0.47, 0.52, 1)
     */
    this.seaGreen400 = new Color(seaGreen400);
    /**
     * hsla(0.54, 0.67, 0.5, 1)
     */
    this.blue400 = new Color(blue400);
    /**
     * hsla(1, 0.69, 0.62, 1)
     */
    this.red400 = new Color(red400);
    /**
     * hsla(0.14, 0.99, 0.68, 1)
     */
    this.orange400 = new Color(orange400);
    /**
     * hsla(0.67, 0.01, 0.3, 1)
     */
    this.gray700 = new Color(gray700);
    /**
     * hsla(0.36, 0.56, 0.41, 1)
     */
    this.green700 = new Color(green700);
    /**
     * hsla(0.41, 0.72, 0.32, 1)
     */
    this.seaGreen700 = new Color(seaGreen700);
    /**
     * hsla(0.55, 1, 0.34, 1)
     */
    this.blue700 = new Color(blue700);
    /**
     * hsla(1, 0.54, 0.5, 1)
     */
    this.red700 = new Color(red700);
    /**
     * hsla(0.11, 0.94, 0.6, 1)
     */
    this.orange700 = new Color(orange700);
    /**
     * hsla(0.17, 0.01, 0.85, 1)
     */
    this.gray200 = new Color(gray200);
    /**
     * hsla(0.27, 0.53, 0.74, 1)
     */
    this.green200 = new Color(green200);
    /**
     * hsla(0.4, 0.46, 0.72, 1)
     */
    this.seaGreen200 = new Color(seaGreen200);
    /**
     * hsla(0.54, 0.66, 0.67, 1)
     */
    this.blue200 = new Color(blue200);
    /**
     * hsla(0.99, 0.57, 0.75, 1)
     */
    this.red200 = new Color(red200);
    /**
     * hsla(0.15, 1, 0.81, 1)
     */
    this.orange200 = new Color(orange200);
    /**
     * hsla(0, 0, 0.58, 1)
     */
    this.gray500 = new Color(gray500);
    /**
     * hsla(0.27, 0.54, 0.48, 1)
     */
    this.green500 = new Color(green500);
    /**
     * hsla(0.41, 0.66, 0.42, 1)
     */
    this.seaGreen500 = new Color(seaGreen500);
    /**
     * hsla(0.55, 0.9, 0.43, 1)
     */
    this.blue500 = new Color(blue500);
    /**
     * hsla(0, 0.76, 0.59, 1)
     */
    this.red500 = new Color(red500);
    /**
     * hsla(0.14, 0.97, 0.62, 1)
     */
    this.orange500 = new Color(orange500);
    /**
     * hsla(0, 0, 0.2, 1)
     */
    this.gray800 = new Color(gray800);
    /**
     * hsla(0.3, 0.61, 0.32, 1)
     */
    this.green800 = new Color(green800);
    /**
     * hsla(0.41, 0.76, 0.28, 1)
     */
    this.seaGreen800 = new Color(seaGreen800);
    /**
     * hsla(0.57, 1, 0.35, 1)
     */
    this.blue800 = new Color(blue800);
    /**
     * hsla(1, 0.55, 0.46, 1)
     */
    this.red800 = new Color(red800);
    /**
     * hsla(0.1, 0.92, 0.58, 1)
     */
    this.orange800 = new Color(orange800);
    /**
     * hsla(0, 0, 0, 1)
     */
    this.blackWhiteBlack = new Color(blackWhiteBlack);
    /**
     * hsla(0.11, 0.94, 0.87, 1)
     */
    this.brown50 = new Color(brown50);
    /**
     * hsla(0.1, 0.36, 0.45, 1)
     */
    this.brown300 = new Color(brown300);
    /**
     * hsla(0.1, 0.9, 0.19, 1)
     */
    this.brown600 = new Color(brown600);
    /**
     * hsla(0.07, 1, 0.11, 1)
     */
    this.brown900 = new Color(brown900);
    /**
     * hsla(0.09, 0.55, 0.75, 1)
     */
    this.brown100 = new Color(brown100);
    /**
     * hsla(0.1, 0.49, 0.34, 1)
     */
    this.brown400 = new Color(brown400);
    /**
     * hsla(0.1, 1, 0.16, 1)
     */
    this.brown700 = new Color(brown700);
    /**
     * hsla(0.09, 0.38, 0.6, 1)
     */
    this.brown200 = new Color(brown200);
    /**
     * hsla(0.11, 0.81, 0.23, 1)
     */
    this.brown500 = new Color(brown500);
    /**
     * hsla(0.09, 1, 0.14, 1)
     */
    this.brown800 = new Color(brown800);
    /**
     * hsla(0.4, 0.46, 0.85, 1)
     */
    this.brandGreenLight = new Color(brandGreenLight);
    /**
     * hsla(0.43, 0.44, 0.29, 1)
     */
    this.brandGreenDark = new Color(brandGreenDark);
    /**
     * hsla(0.57, 1, 0.37, 1)
     */
    this.brandBlueDark = new Color(brandBlueDark);
    /**
     * hsla(0.09, 0.38, 0.6, 1)
     */
    this.brandBrown = new Color(brandBrown);
    /**
     * hsla(0.55, 1, 0.38, 1)
     */
    this.brandBlue = new Color(brandBlue);
    /**
     * hsla(0.54, 0.65, 0.58, 1)
     */
    this.brandBlueLight = new Color(brandBlueLight);
    /**
     * hsla(0, 0.57, 0.42, 1)
     */
    this.red7001 = new Color(red7001);
    /**
     * hsla(0.36, 0.56, 0.41, 1)
     */
    this.brandGreen = new Color(brandGreen);
    /**
     * hsla(0.07, 0.87, 0.55, 1)
     */
    this.orange6001 = new Color(orange6001);
    /**
     * hsla(0, 0, 1, 1)
     */
    this.blackWhiteWhite = new Color(blackWhiteWhite);
    /**
     * hsla(0, 0, 1, 1)
     */
    this.blackWhiteWhite1 = new Color(blackWhiteWhite1);
    /**
     * hsla(0, 0, 0.2, 1)
     */
    this.grayGray800 = new Color(grayGray800);
    /**
     * hsla(0, 0, 0, 1)
     */
    this.blackWhiteBlack1 = new Color(blackWhiteBlack1);
    /**
     * hsla(0.36, 0.56, 0.41, 1)
     */
    this.brandBrandGreen = new Color(brandBrandGreen);
  }
}


module.exports.WihuriDesignSystemTokensColors = WihuriDesignSystemTokensColors;

/**
 * Color Aliases for enabling contextual usage of them
 *
 */
class Palette {
  constructor({
    white = {h: 0, s: 0, l: 1, a: 1},
    black = {h: 0, s: 0, l: 0, a: 1},
    primary = {h: 0.36350574712643674, s: 0.5576923076923076, l: 0.40784313725490196, a: 1},
    primaryLight = {h: 0.3981481481481482, s: 0.4615384615384611, l: 0.8470588235294118, a: 1},
    primaryDark = {h: 0.4270833333333333, s: 0.4383561643835617, l: 0.28627450980392155, a: 1},
    secondary = {h: 0.5466321243523317, s: 1, l: 0.3784313725490196, a: 1},
    secondaryDark = {h: 0.5650623885918004, s: 1, l: 0.36666666666666664, a: 1},
    secondaryLight = {h: 0.5428571428571428, s: 0.6481481481481483, l: 0.5764705882352941, a: 1},
    alert = {h: 0.9951690821256038, s: 0.5433070866141733, l: 0.5019607843137255, a: 1},
    warning = {h: 0.13120567375886524, s: 0.9690721649484537, l: 0.6196078431372549, a: 1},
    text = {h: 0, s: 0, l: 0, a: 1},
    bodyBackground = {h: 0, s: 0, l: 0.9647058823529412, a: 1}
  } = {}) {
    /**
     * `WihuriDesignSystemTokensColors.blackWhiteWhite` ( hsla(0, 0, 1, 1) )
     */
    this.white = new Color(white);
    /**
     * `WihuriDesignSystemTokensColors.blackWhiteBlack` ( hsla(0, 0, 0, 1) )
     */
    this.black = new Color(black);
    /**
     * `WihuriDesignSystemTokensColors.brandGreen` ( hsla(0.36, 0.56, 0.41, 1) )
     */
    this.primary = new Color(primary);
    /**
     * `WihuriDesignSystemTokensColors.brandGreenLight` ( hsla(0.4, 0.46, 0.85, 1) )
     */
    this.primaryLight = new Color(primaryLight);
    /**
     * `WihuriDesignSystemTokensColors.brandGreenDark` ( hsla(0.43, 0.44, 0.29, 1) )
     */
    this.primaryDark = new Color(primaryDark);
    /**
     * `WihuriDesignSystemTokensColors.brandBlue` ( hsla(0.55, 1, 0.38, 1) )
     */
    this.secondary = new Color(secondary);
    /**
     * `WihuriDesignSystemTokensColors.brandBlueDark` ( hsla(0.57, 1, 0.37, 1) )
     */
    this.secondaryDark = new Color(secondaryDark);
    /**
     * `WihuriDesignSystemTokensColors.brandBlueLight` ( hsla(0.54, 0.65, 0.58, 1) )
     */
    this.secondaryLight = new Color(secondaryLight);
    /**
     * `WihuriDesignSystemTokensColors.red700` ( hsla(1, 0.54, 0.5, 1) )
     */
    this.alert = new Color(alert);
    /**
     * `WihuriDesignSystemTokensColors.orange600` ( hsla(0.13, 0.97, 0.62, 1) )
     */
    this.warning = new Color(warning);
    /**
     * `WihuriDesignSystemTokensColors.blackWhiteBlack` ( hsla(0, 0, 0, 1) )
     */
    this.text = new Color(text);
    /**
     * `WihuriDesignSystemTokensColors.gray50` ( hsla(0, 0, 0.96, 1) )
     */
    this.bodyBackground = new Color(bodyBackground);
  }
}


module.exports.Palette = Palette;

class FontSizes {
  constructor({
    xxxs = 9,
    xxs = 10,
    xs = 12,
    sm = 14,
    md = 16,
    lg = 18,
    xl = 20,
    xxl = 24,
    xxxl = 28,
    xxxxl = 36,
    xxxxxl = 48,
    xxxxxxl = 64
  } = {}) {
    /**
     * 9
     */
    this.xxxs = xxxs;
    /**
     * 10
     */
    this.xxs = xxs;
    /**
     * 12
     */
    this.xs = xs;
    /**
     * 14
     */
    this.sm = sm;
    /**
     * 16
     */
    this.md = md;
    /**
     * 18
     */
    this.lg = lg;
    /**
     * 20
     */
    this.xl = xl;
    /**
     * 24
     */
    this.xxl = xxl;
    /**
     * 28
     */
    this.xxxl = xxxl;
    /**
     * 36
     */
    this.xxxxl = xxxxl;
    /**
     * 48
     */
    this.xxxxxl = xxxxxl;
    /**
     * 64
     */
    this.xxxxxxl = xxxxxxl;
  }
}


module.exports.FontSizes = FontSizes;

/**
 * Sizes
 *
 */
class Sizes {
  constructor({
    px = "1px",
    full = "100%",
    xxxs = "14rem",
    xxs = "16rem",
    xs = "20rem",
    sm = "24rem",
    md = "28rem",
    lg = "32rem",
    xl = "36rem",
    xxl = "42rem",
    xxxl = "48rem",
    xxxxl = "56rem",
    xxxxxl = "64rem",
    xxxxxxl = "72rem",
    headerHeight = "50px"
  } = {}) {
    /**
     * 1px
     */
    this.px = px;
    /**
     * 100%
     */
    this.full = full;
    /**
     * 14rem
     */
    this.xxxs = xxxs;
    /**
     * 16rem
     */
    this.xxs = xxs;
    /**
     * 20rem
     */
    this.xs = xs;
    /**
     * 24rem
     */
    this.sm = sm;
    /**
     * 28rem
     */
    this.md = md;
    /**
     * 32rem
     */
    this.lg = lg;
    /**
     * 36rem
     */
    this.xl = xl;
    /**
     * 42rem
     */
    this.xxl = xxl;
    /**
     * 48rem
     */
    this.xxxl = xxxl;
    /**
     * 56rem
     */
    this.xxxxl = xxxxl;
    /**
     * 64rem
     */
    this.xxxxxl = xxxxxl;
    /**
     * 72rem
     */
    this.xxxxxxl = xxxxxxl;
    /**
     * 50px
     */
    this.headerHeight = headerHeight;
  }
}


module.exports.Sizes = Sizes;

/**
 * All of rich language features of TypeScript are at your disposal; for example,
 * you can define an object to keep track of your fonts.
 *
 */
class Fonts {
  constructor({
  } = {}) {
  }
}


module.exports.Fonts = Fonts;

class Radius {
  constructor({
    none = "0",
    sm = "4px",
    md = "8px",
    lg = "22px",
    full = "50%"
  } = {}) {
    /**
     * 0
     */
    this.none = none;
    /**
     * 4px
     */
    this.sm = sm;
    /**
     * 8px
     */
    this.md = md;
    /**
     * 22px
     */
    this.lg = lg;
    /**
     * 50%
     */
    this.full = full;
  }
}


module.exports.Radius = Radius;

/**
 * Spacing
 *
 */
class Spacing {
  constructor({
    px = "1px",
    xxs = "0.25rem",
    xs = "0.5rem",
    sm = "0.75rem",
    md = "1rem",
    lg = "1.25rem",
    xl = "1.5rem",
    xxl = "2rem",
    xxxl = "3rem"
  } = {}) {
    /**
     * 1px
     */
    this.px = px;
    /**
     * 0.25rem
     */
    this.xxs = xxs;
    /**
     * 0.5rem
     */
    this.xs = xs;
    /**
     * 0.75rem
     */
    this.sm = sm;
    /**
     * 1rem
     */
    this.md = md;
    /**
     * 1.25rem
     */
    this.lg = lg;
    /**
     * 1.5rem
     */
    this.xl = xl;
    /**
     * 2rem
     */
    this.xxl = xxl;
    /**
     * 3rem
     */
    this.xxxl = xxxl;
  }
}


module.exports.Spacing = Spacing;

/**
 * Provides a container for referencing local assets, which can be bridged by compilers to embed images, SVGs,
 * and more. This component is used internally by [[Image]] and [[Font]].
 * 
 * The compiler may enforce certain restrictions on the `type` of a `File` instance.
 * 
 * Usage: `file = new File({src: 'assets/images/file.jpg', type: FileType.Image});`.
 *
 */
class File {
  constructor({
    src,
    type
  }) {
    /**
     * File data.
     *
     * assets/logo.svg
     */
    this.src = src;
    /**
     * File data.
     *
     * raw
     */
    this.type = type;
  }
}


module.exports.File = File;

Object.defineProperties(File.prototype, {
  url: {
    get () {
      return `${Environment.serverUrl}/${this.src}`;
    },
  },
});

/**
 * In addition to colors and typography, you can also collect other types of
 * design language primitives in components as well — such as images, icons &
 * animations.
 *
 */
class Images {
  constructor({
    logo = {src: "assets/logo.svg", type: "raw"}
  } = {}) {
    /**
     * assets/logo.svg
     */
    this.logo = new File(logo);
  }
}


module.exports.Images = Images;

/**
 * Provides a two dimensional size.
 * 
 * Usage: `size = Size2D.make(1920, 1080);`.
 *
 */
class Size2D {
  constructor({
    width,
    height
  }) {
    /**
     * Size data.
     *
     * 214
     */
    this.width = width;
    /**
     * Size data.
     *
     * 65
     */
    this.height = height;
  }
}


module.exports.Size2D = Size2D;

Object.defineProperties(Size2D.prototype, {
  style: {
    get () {
      return {
        width: `${this.width}px`,
        height: `${this.height}px`,
      };
    },
  },
  backgroundSizeStyle: {
    get () {
      return {
        backgroundSize: `${this.style.width} ${this.style.height}`,
      };
    },
  },
});

/**
 * Provides an abstraction for raster images. With bindings, this component can embed images in multiple platforms in
 * accordance with best practices. Images should provide pixel ratios for standard, @2x, @3x, and @4x with conventional
 * file naming. The availability of retina resolutions is expected to be a compile-time concern, and the "src" of the
 * image is expected to exist and provide an image with the specified dimensions.
 *
 */
class Image {
  constructor({
    file,
    file2x,
    file3x,
    size
  }) {
    /**
     * Responsive image data.
     *
     * assets/WihuriDesignSystemTokens.figma.contents/images/ColorDocumentation.png
     */
    this.file = new File(file);
    /**
     * Responsive image data.
     *
     * assets/WihuriDesignSystemTokens.figma.contents/images/ColorDocumentation@2x.png
     */
    this.file2x = new File(file2x);
    /**
     * Responsive image data.
     *
     * assets/WihuriDesignSystemTokens.figma.contents/images/ColorDocumentation@3x.png
     */
    this.file3x = new File(file3x);
    /**
     * Responsive image data.
     *
     * (214 x 65)
     */
    this.size = new Size2D(size);
  }
}


module.exports.Image = Image;

Object.defineProperties(Image.prototype, {
  url: {
    get () {
      switch (Math.ceil(window.devicePixelRatio)) {
        case 1:
          return this.file.url;
        case 2:
          return this.file2x.url;
        case 3:
          return this.file3x.url;
        default:
          return this.file2x.url;
      }
    },
  },
  backgroundImageStyle: {
    get () {
      return {
        backgroundImage: `url("${this.url}")`,
      };
    },
  },
});

class WihuriDesignSystemTokensImages {
  constructor({
    colorDocumentation = {file: {src: "assets/WihuriDesignSystemTokens.figma.contents/images/ColorDocumentation.png", type: "image"}, file2x: {src: "assets/WihuriDesignSystemTokens.figma.contents/images/ColorDocumentation@2x.png", type: "image"}, file3x: {src: "assets/WihuriDesignSystemTokens.figma.contents/images/ColorDocumentation@3x.png", type: "image"}, size: {width: 214, height: 65}},
    h1 = {file: {src: "assets/WihuriDesignSystemTokens.figma.contents/images/H1.png", type: "image"}, file2x: {src: "assets/WihuriDesignSystemTokens.figma.contents/images/H1@2x.png", type: "image"}, file3x: {src: "assets/WihuriDesignSystemTokens.figma.contents/images/H1@3x.png", type: "image"}, size: {width: 238, height: 67}},
    h2 = {file: {src: "assets/WihuriDesignSystemTokens.figma.contents/images/H2.png", type: "image"}, file2x: {src: "assets/WihuriDesignSystemTokens.figma.contents/images/H2@2x.png", type: "image"}, file3x: {src: "assets/WihuriDesignSystemTokens.figma.contents/images/H2@3x.png", type: "image"}, size: {width: 179, height: 50}},
    h3 = {file: {src: "assets/WihuriDesignSystemTokens.figma.contents/images/H3.png", type: "image"}, file2x: {src: "assets/WihuriDesignSystemTokens.figma.contents/images/H3@2x.png", type: "image"}, file3x: {src: "assets/WihuriDesignSystemTokens.figma.contents/images/H3@3x.png", type: "image"}, size: {width: 119, height: 36}},
    h4 = {file: {src: "assets/WihuriDesignSystemTokens.figma.contents/images/H4.png", type: "image"}, file2x: {src: "assets/WihuriDesignSystemTokens.figma.contents/images/H4@2x.png", type: "image"}, file3x: {src: "assets/WihuriDesignSystemTokens.figma.contents/images/H4@3x.png", type: "image"}, size: {width: 100, height: 30}},
    h5 = {file: {src: "assets/WihuriDesignSystemTokens.figma.contents/images/H5.png", type: "image"}, file2x: {src: "assets/WihuriDesignSystemTokens.figma.contents/images/H5@2x.png", type: "image"}, file3x: {src: "assets/WihuriDesignSystemTokens.figma.contents/images/H5@3x.png", type: "image"}, size: {width: 80, height: 24}},
    h6 = {file: {src: "assets/WihuriDesignSystemTokens.figma.contents/images/H6.png", type: "image"}, file2x: {src: "assets/WihuriDesignSystemTokens.figma.contents/images/H6@2x.png", type: "image"}, file3x: {src: "assets/WihuriDesignSystemTokens.figma.contents/images/H6@3x.png", type: "image"}, size: {width: 70, height: 21}},
    buttonsPrimaryDefault = {file: {src: "assets/WihuriDesignSystemTokens.figma.contents/images/ButtonsPrimaryDefault.png", type: "image"}, file2x: {src: "assets/WihuriDesignSystemTokens.figma.contents/images/ButtonsPrimaryDefault@2x.png", type: "image"}, file3x: {src: "assets/WihuriDesignSystemTokens.figma.contents/images/ButtonsPrimaryDefault@3x.png", type: "image"}, size: {width: 171, height: 49}},
    buttonsSecondaryDefault = {file: {src: "assets/WihuriDesignSystemTokens.figma.contents/images/ButtonsSecondaryDefault.png", type: "image"}, file2x: {src: "assets/WihuriDesignSystemTokens.figma.contents/images/ButtonsSecondaryDefault@2x.png", type: "image"}, file3x: {src: "assets/WihuriDesignSystemTokens.figma.contents/images/ButtonsSecondaryDefault@3x.png", type: "image"}, size: {width: 171, height: 49}},
    buttonsSecondaryDisabled = {file: {src: "assets/WihuriDesignSystemTokens.figma.contents/images/ButtonsSecondaryDisabled.png", type: "image"}, file2x: {src: "assets/WihuriDesignSystemTokens.figma.contents/images/ButtonsSecondaryDisabled@2x.png", type: "image"}, file3x: {src: "assets/WihuriDesignSystemTokens.figma.contents/images/ButtonsSecondaryDisabled@3x.png", type: "image"}, size: {width: 171, height: 49}},
    buttonsPrimaryHover = {file: {src: "assets/WihuriDesignSystemTokens.figma.contents/images/ButtonsPrimaryHover.png", type: "image"}, file2x: {src: "assets/WihuriDesignSystemTokens.figma.contents/images/ButtonsPrimaryHover@2x.png", type: "image"}, file3x: {src: "assets/WihuriDesignSystemTokens.figma.contents/images/ButtonsPrimaryHover@3x.png", type: "image"}, size: {width: 171, height: 49}},
    buttonsPrimaryDisabled = {file: {src: "assets/WihuriDesignSystemTokens.figma.contents/images/ButtonsPrimaryDisabled.png", type: "image"}, file2x: {src: "assets/WihuriDesignSystemTokens.figma.contents/images/ButtonsPrimaryDisabled@2x.png", type: "image"}, file3x: {src: "assets/WihuriDesignSystemTokens.figma.contents/images/ButtonsPrimaryDisabled@3x.png", type: "image"}, size: {width: 171, height: 49}},
    buttonsSecondaryDisabled1 = {file: {src: "assets/WihuriDesignSystemTokens.figma.contents/images/ButtonsSecondaryDisabled1.png", type: "image"}, file2x: {src: "assets/WihuriDesignSystemTokens.figma.contents/images/ButtonsSecondaryDisabled1@2x.png", type: "image"}, file3x: {src: "assets/WihuriDesignSystemTokens.figma.contents/images/ButtonsSecondaryDisabled1@3x.png", type: "image"}, size: {width: 171, height: 49}},
    inputTextDefault = {file: {src: "assets/WihuriDesignSystemTokens.figma.contents/images/InputTextDefault.png", type: "image"}, file2x: {src: "assets/WihuriDesignSystemTokens.figma.contents/images/InputTextDefault@2x.png", type: "image"}, file3x: {src: "assets/WihuriDesignSystemTokens.figma.contents/images/InputTextDefault@3x.png", type: "image"}, size: {width: 317, height: 37.0099983215332}},
    inputTextareaDefault = {file: {src: "assets/WihuriDesignSystemTokens.figma.contents/images/InputTextareaDefault.png", type: "image"}, file2x: {src: "assets/WihuriDesignSystemTokens.figma.contents/images/InputTextareaDefault@2x.png", type: "image"}, file3x: {src: "assets/WihuriDesignSystemTokens.figma.contents/images/InputTextareaDefault@3x.png", type: "image"}, size: {width: 320, height: 72.5}},
    ionIconRadioButtonOffSharp = {file: {src: "assets/WihuriDesignSystemTokens.figma.contents/images/IonIconRadioButtonOffSharp.png", type: "image"}, file2x: {src: "assets/WihuriDesignSystemTokens.figma.contents/images/IonIconRadioButtonOffSharp@2x.png", type: "image"}, file3x: {src: "assets/WihuriDesignSystemTokens.figma.contents/images/IonIconRadioButtonOffSharp@3x.png", type: "image"}, size: {width: 23.9999942779541, height: 23.9999942779541}},
    inputRadiobuttonDefault = {file: {src: "assets/WihuriDesignSystemTokens.figma.contents/images/InputRadiobuttonDefault.png", type: "image"}, file2x: {src: "assets/WihuriDesignSystemTokens.figma.contents/images/InputRadiobuttonDefault@2x.png", type: "image"}, file3x: {src: "assets/WihuriDesignSystemTokens.figma.contents/images/InputRadiobuttonDefault@3x.png", type: "image"}, size: {width: 79, height: 23.9999942779541}},
    inputRadiobuttonActive = {file: {src: "assets/WihuriDesignSystemTokens.figma.contents/images/InputRadiobuttonActive.png", type: "image"}, file2x: {src: "assets/WihuriDesignSystemTokens.figma.contents/images/InputRadiobuttonActive@2x.png", type: "image"}, file3x: {src: "assets/WihuriDesignSystemTokens.figma.contents/images/InputRadiobuttonActive@3x.png", type: "image"}, size: {width: 69, height: 23.9999942779541}},
    inputTextDisabled = {file: {src: "assets/WihuriDesignSystemTokens.figma.contents/images/InputTextDisabled.png", type: "image"}, file2x: {src: "assets/WihuriDesignSystemTokens.figma.contents/images/InputTextDisabled@2x.png", type: "image"}, file3x: {src: "assets/WihuriDesignSystemTokens.figma.contents/images/InputTextDisabled@3x.png", type: "image"}, size: {width: 303, height: 37.0099983215332}},
    inputTextareaDisabled = {file: {src: "assets/WihuriDesignSystemTokens.figma.contents/images/InputTextareaDisabled.png", type: "image"}, file2x: {src: "assets/WihuriDesignSystemTokens.figma.contents/images/InputTextareaDisabled@2x.png", type: "image"}, file3x: {src: "assets/WihuriDesignSystemTokens.figma.contents/images/InputTextareaDisabled@3x.png", type: "image"}, size: {width: 287, height: 72.5}},
    checkboxOnlyCheck = {file: {src: "assets/WihuriDesignSystemTokens.figma.contents/images/CheckboxOnlyCheck.png", type: "image"}, file2x: {src: "assets/WihuriDesignSystemTokens.figma.contents/images/CheckboxOnlyCheck@2x.png", type: "image"}, file3x: {src: "assets/WihuriDesignSystemTokens.figma.contents/images/CheckboxOnlyCheck@3x.png", type: "image"}, size: {width: 14, height: 14}},
    checkboxCheckLabel = {file: {src: "assets/WihuriDesignSystemTokens.figma.contents/images/CheckboxCheckLabel.png", type: "image"}, file2x: {src: "assets/WihuriDesignSystemTokens.figma.contents/images/CheckboxCheckLabel@2x.png", type: "image"}, file3x: {src: "assets/WihuriDesignSystemTokens.figma.contents/images/CheckboxCheckLabel@3x.png", type: "image"}, size: {width: 60, height: 21}},
    fontAwesomeSolid = {file: {src: "assets/WihuriDesignSystemTokens.figma.contents/images/FontAwesomeSolid.png", type: "image"}, file2x: {src: "assets/WihuriDesignSystemTokens.figma.contents/images/FontAwesomeSolid@2x.png", type: "image"}, file3x: {src: "assets/WihuriDesignSystemTokens.figma.contents/images/FontAwesomeSolid@3x.png", type: "image"}, size: {width: 16, height: 16}},
    messageErrorMessage = {file: {src: "assets/WihuriDesignSystemTokens.figma.contents/images/MessageErrorMessage.png", type: "image"}, file2x: {src: "assets/WihuriDesignSystemTokens.figma.contents/images/MessageErrorMessage@2x.png", type: "image"}, file3x: {src: "assets/WihuriDesignSystemTokens.figma.contents/images/MessageErrorMessage@3x.png", type: "image"}, size: {width: 103, height: 18}},
    checkboxBase = {file: {src: "assets/WihuriDesignSystemTokens.figma.contents/images/CheckboxBase.png", type: "image"}, file2x: {src: "assets/WihuriDesignSystemTokens.figma.contents/images/CheckboxBase@2x.png", type: "image"}, file3x: {src: "assets/WihuriDesignSystemTokens.figma.contents/images/CheckboxBase@3x.png", type: "image"}, size: {width: 60, height: 21}},
    checkboxDefaultUnchecked = {file: {src: "assets/WihuriDesignSystemTokens.figma.contents/images/CheckboxDefaultUnchecked.png", type: "image"}, file2x: {src: "assets/WihuriDesignSystemTokens.figma.contents/images/CheckboxDefaultUnchecked@2x.png", type: "image"}, file3x: {src: "assets/WihuriDesignSystemTokens.figma.contents/images/CheckboxDefaultUnchecked@3x.png", type: "image"}, size: {width: 60, height: 21}},
    checkboxDefaultChecked = {file: {src: "assets/WihuriDesignSystemTokens.figma.contents/images/CheckboxDefaultChecked.png", type: "image"}, file2x: {src: "assets/WihuriDesignSystemTokens.figma.contents/images/CheckboxDefaultChecked@2x.png", type: "image"}, file3x: {src: "assets/WihuriDesignSystemTokens.figma.contents/images/CheckboxDefaultChecked@3x.png", type: "image"}, size: {width: 63, height: 21}},
    logo = {file: {src: "assets/WihuriDesignSystemTokens.figma.contents/images/Logo.png", type: "image"}, file2x: {src: "assets/WihuriDesignSystemTokens.figma.contents/images/Logo@2x.png", type: "image"}, file3x: {src: "assets/WihuriDesignSystemTokens.figma.contents/images/Logo@3x.png", type: "image"}, size: {width: 52, height: 27}},
    iconFilter = {file: {src: "assets/WihuriDesignSystemTokens.figma.contents/images/IconFilter.png", type: "image"}, file2x: {src: "assets/WihuriDesignSystemTokens.figma.contents/images/IconFilter@2x.png", type: "image"}, file3x: {src: "assets/WihuriDesignSystemTokens.figma.contents/images/IconFilter@3x.png", type: "image"}, size: {width: 30, height: 30}},
    iconSearch = {file: {src: "assets/WihuriDesignSystemTokens.figma.contents/images/IconSearch.png", type: "image"}, file2x: {src: "assets/WihuriDesignSystemTokens.figma.contents/images/IconSearch@2x.png", type: "image"}, file3x: {src: "assets/WihuriDesignSystemTokens.figma.contents/images/IconSearch@3x.png", type: "image"}, size: {width: 30, height: 30}},
    iconDownload = {file: {src: "assets/WihuriDesignSystemTokens.figma.contents/images/IconDownload.png", type: "image"}, file2x: {src: "assets/WihuriDesignSystemTokens.figma.contents/images/IconDownload@2x.png", type: "image"}, file3x: {src: "assets/WihuriDesignSystemTokens.figma.contents/images/IconDownload@3x.png", type: "image"}, size: {width: 30, height: 30}},
    iconBulletList = {file: {src: "assets/WihuriDesignSystemTokens.figma.contents/images/IconBulletList.png", type: "image"}, file2x: {src: "assets/WihuriDesignSystemTokens.figma.contents/images/IconBulletList@2x.png", type: "image"}, file3x: {src: "assets/WihuriDesignSystemTokens.figma.contents/images/IconBulletList@3x.png", type: "image"}, size: {width: 30, height: 30}},
    iconUpload = {file: {src: "assets/WihuriDesignSystemTokens.figma.contents/images/IconUpload.png", type: "image"}, file2x: {src: "assets/WihuriDesignSystemTokens.figma.contents/images/IconUpload@2x.png", type: "image"}, file3x: {src: "assets/WihuriDesignSystemTokens.figma.contents/images/IconUpload@3x.png", type: "image"}, size: {width: 30, height: 30}}
  } = {}) {
    /**
     * assets/WihuriDesignSystemTokens.figma.contents/images/ColorDocumentation.png (214 x 65)
     */
    this.colorDocumentation = new Image(colorDocumentation);
    /**
     * assets/WihuriDesignSystemTokens.figma.contents/images/H1.png (238 x 67)
     */
    this.h1 = new Image(h1);
    /**
     * assets/WihuriDesignSystemTokens.figma.contents/images/H2.png (179 x 50)
     */
    this.h2 = new Image(h2);
    /**
     * assets/WihuriDesignSystemTokens.figma.contents/images/H3.png (119 x 36)
     */
    this.h3 = new Image(h3);
    /**
     * assets/WihuriDesignSystemTokens.figma.contents/images/H4.png (100 x 30)
     */
    this.h4 = new Image(h4);
    /**
     * assets/WihuriDesignSystemTokens.figma.contents/images/H5.png (80 x 24)
     */
    this.h5 = new Image(h5);
    /**
     * assets/WihuriDesignSystemTokens.figma.contents/images/H6.png (70 x 21)
     */
    this.h6 = new Image(h6);
    /**
     * assets/WihuriDesignSystemTokens.figma.contents/images/ButtonsPrimaryDefault.png (171 x 49)
     */
    this.buttonsPrimaryDefault = new Image(buttonsPrimaryDefault);
    /**
     * assets/WihuriDesignSystemTokens.figma.contents/images/ButtonsSecondaryDefault.png (171 x 49)
     */
    this.buttonsSecondaryDefault = new Image(buttonsSecondaryDefault);
    /**
     * assets/WihuriDesignSystemTokens.figma.contents/images/ButtonsSecondaryDisabled.png (171 x 49)
     */
    this.buttonsSecondaryDisabled = new Image(buttonsSecondaryDisabled);
    /**
     * assets/WihuriDesignSystemTokens.figma.contents/images/ButtonsPrimaryHover.png (171 x 49)
     */
    this.buttonsPrimaryHover = new Image(buttonsPrimaryHover);
    /**
     * assets/WihuriDesignSystemTokens.figma.contents/images/ButtonsPrimaryDisabled.png (171 x 49)
     */
    this.buttonsPrimaryDisabled = new Image(buttonsPrimaryDisabled);
    /**
     * assets/WihuriDesignSystemTokens.figma.contents/images/ButtonsSecondaryDisabled1.png (171 x 49)
     */
    this.buttonsSecondaryDisabled1 = new Image(buttonsSecondaryDisabled1);
    /**
     * assets/WihuriDesignSystemTokens.figma.contents/images/InputTextDefault.png (317 x 37.0099983215332)
     */
    this.inputTextDefault = new Image(inputTextDefault);
    /**
     * assets/WihuriDesignSystemTokens.figma.contents/images/InputTextareaDefault.png (320 x 72.5)
     */
    this.inputTextareaDefault = new Image(inputTextareaDefault);
    /**
     * assets/WihuriDesignSystemTokens.figma.contents/images/IonIconRadioButtonOffSharp.png (23.9999942779541 x 23.9999942779541)
     */
    this.ionIconRadioButtonOffSharp = new Image(ionIconRadioButtonOffSharp);
    /**
     * assets/WihuriDesignSystemTokens.figma.contents/images/InputRadiobuttonDefault.png (79 x 23.9999942779541)
     */
    this.inputRadiobuttonDefault = new Image(inputRadiobuttonDefault);
    /**
     * assets/WihuriDesignSystemTokens.figma.contents/images/InputRadiobuttonActive.png (69 x 23.9999942779541)
     */
    this.inputRadiobuttonActive = new Image(inputRadiobuttonActive);
    /**
     * assets/WihuriDesignSystemTokens.figma.contents/images/InputTextDisabled.png (303 x 37.0099983215332)
     */
    this.inputTextDisabled = new Image(inputTextDisabled);
    /**
     * assets/WihuriDesignSystemTokens.figma.contents/images/InputTextareaDisabled.png (287 x 72.5)
     */
    this.inputTextareaDisabled = new Image(inputTextareaDisabled);
    /**
     * assets/WihuriDesignSystemTokens.figma.contents/images/CheckboxOnlyCheck.png (14 x 14)
     */
    this.checkboxOnlyCheck = new Image(checkboxOnlyCheck);
    /**
     * assets/WihuriDesignSystemTokens.figma.contents/images/CheckboxCheckLabel.png (60 x 21)
     */
    this.checkboxCheckLabel = new Image(checkboxCheckLabel);
    /**
     * assets/WihuriDesignSystemTokens.figma.contents/images/FontAwesomeSolid.png (16 x 16)
     */
    this.fontAwesomeSolid = new Image(fontAwesomeSolid);
    /**
     * assets/WihuriDesignSystemTokens.figma.contents/images/MessageErrorMessage.png (103 x 18)
     */
    this.messageErrorMessage = new Image(messageErrorMessage);
    /**
     * assets/WihuriDesignSystemTokens.figma.contents/images/CheckboxBase.png (60 x 21)
     */
    this.checkboxBase = new Image(checkboxBase);
    /**
     * assets/WihuriDesignSystemTokens.figma.contents/images/CheckboxDefaultUnchecked.png (60 x 21)
     */
    this.checkboxDefaultUnchecked = new Image(checkboxDefaultUnchecked);
    /**
     * assets/WihuriDesignSystemTokens.figma.contents/images/CheckboxDefaultChecked.png (63 x 21)
     */
    this.checkboxDefaultChecked = new Image(checkboxDefaultChecked);
    /**
     * assets/WihuriDesignSystemTokens.figma.contents/images/Logo.png (52 x 27)
     */
    this.logo = new Image(logo);
    /**
     * assets/WihuriDesignSystemTokens.figma.contents/images/IconFilter.png (30 x 30)
     */
    this.iconFilter = new Image(iconFilter);
    /**
     * assets/WihuriDesignSystemTokens.figma.contents/images/IconSearch.png (30 x 30)
     */
    this.iconSearch = new Image(iconSearch);
    /**
     * assets/WihuriDesignSystemTokens.figma.contents/images/IconDownload.png (30 x 30)
     */
    this.iconDownload = new Image(iconDownload);
    /**
     * assets/WihuriDesignSystemTokens.figma.contents/images/IconBulletList.png (30 x 30)
     */
    this.iconBulletList = new Image(iconBulletList);
    /**
     * assets/WihuriDesignSystemTokens.figma.contents/images/IconUpload.png (30 x 30)
     */
    this.iconUpload = new Image(iconUpload);
  }
}


module.exports.WihuriDesignSystemTokensImages = WihuriDesignSystemTokensImages;

class Icons {
  constructor({
    bulletList = {src: "assets/icons/BulletList.svg", type: "raw"},
    filter = {src: "assets/icons/Filter.svg", type: "raw"},
    search = {src: "assets/icons/Search.svg", type: "raw"},
    upload = {src: "assets/icons/Upload.svg", type: "raw"},
    download = {src: "assets/icons/Download.svg", type: "raw"}
  } = {}) {
    /**
     * assets/icons/BulletList.svg
     */
    this.bulletList = new File(bulletList);
    /**
     * assets/icons/Filter.svg
     */
    this.filter = new File(filter);
    /**
     * assets/icons/Search.svg
     */
    this.search = new File(search);
    /**
     * assets/icons/Upload.svg
     */
    this.upload = new File(upload);
    /**
     * assets/icons/Download.svg
     */
    this.download = new File(download);
  }
}


module.exports.Icons = Icons;

/**
 * Here we create a custom reusable component for describing layout margins.
 *
 */
class Margin {
  constructor({
    top,
    bottom,
    left,
    right
  }) {
    /**
     * Defining the interface of your component's data enables you to instantiate your own
     * reusable components.
     *
     * 40
     */
    this.top = top;
    /**
     * Defining the interface of your component's data enables you to instantiate your own
     * reusable components.
     *
     * 10
     */
    this.bottom = bottom;
    /**
     * Defining the interface of your component's data enables you to instantiate your own
     * reusable components.
     *
     * 10
     */
    this.left = left;
    /**
     * Defining the interface of your component's data enables you to instantiate your own
     * reusable components.
     *
     * 10
     */
    this.right = right;
  }
}


module.exports.Margin = Margin;

/**
 * You can even collect your own custom components.
 *
 */
class LayoutValues {
  constructor({
    spacingSmall = 5,
    spacingMedium = 25,
    spacingLarge = 40,
    contentMargin = {top: 40, bottom: 10, left: 10, right: 10}
  } = {}) {
    /**
     * 5
     */
    this.spacingSmall = spacingSmall;
    /**
     * 25
     */
    this.spacingMedium = spacingMedium;
    /**
     * 40
     */
    this.spacingLarge = spacingLarge;
    /**
     * - top: `40`
     * - bottom: `10`
     * - left: `10`
     * - right: `10`
     */
    this.contentMargin = new Margin(contentMargin);
  }
}


module.exports.LayoutValues = LayoutValues;

/**
 * Provides a two dimensional point.
 * 
 * Taken alone, points are designated in an abstract space with no inherit dimensions or directionality. In the
 * context of other prefabs like [[LinearGradient]], points typically should use the standard two dimensional graphics
 * space, often normalized in the unit square, where x increases from left to right and y increases from top to bottom.
 * 
 * Usage: `point = Point2D.make(0.5, 0.5);`.
 *
 */
class Point2D {
  constructor({
    x,
    y
  }) {
    /**
     * Point data.
     *
     * 0
     */
    this.x = x;
    /**
     * Point data.
     *
     * 8
     */
    this.y = y;
  }
}


module.exports.Point2D = Point2D;

/**
 * Provides a drop shadow.
 *
 */
class DropShadow {
  constructor({
    offset,
    radius,
    color
  }) {
    /**
     * DropShadow data.
     *
     * [0, 8]
     */
    this.offset = new Point2D(offset);
    /**
     * DropShadow data.
     *
     * 8
     */
    this.radius = radius;
    /**
     * DropShadow data.
     *
     * hsla(0, 0, 0, 0.25)
     */
    this.color = new Color(color);
  }
}


module.exports.DropShadow = DropShadow;

const {dropShadowToCss, dropShadowToFilterCss} = require('@diez/web-sdk-common');

Object.defineProperties(DropShadow.prototype, {
  boxShadow: {
    get () {
      return dropShadowToCss(this);
    },
  },
  textShadow: {
    get () {
      return dropShadowToCss(this);
    },
  },
  filter: {
    get () {
      return dropShadowToFilterCss(this);
    },
  },
  boxShadowStyle: {
    get () {
      return {
        boxShadow: this.boxShadow,
      };
    },
  },
  textShadowStyle: {
    get () {
      return {
        textShadow: this.textShadow,
      };
    },
  },
  filterStyle: {
    get () {
      return {
        filter: this.filter,
      };
    },
  },
});

class WihuriDesignSystemTokensShadows {
  constructor({
    shadowXl = {offset: {x: 0, y: 8}, radius: 8, color: {h: 0, s: 0, l: 0, a: 0.25}},
    shadowLg = {offset: {x: 0, y: 4}, radius: 4, color: {h: 0, s: 0, l: 0, a: 0.25}},
    shadowMd = {offset: {x: 0, y: 4}, radius: 4, color: {h: 0, s: 0, l: 0.592156862745098, a: 0.25}},
    shadowSm = {offset: {x: 0, y: 4}, radius: 2, color: {h: 0, s: 0, l: 0.8745098039215686, a: 0.25}}
  } = {}) {
    /**
     * - offset: `[0, 8]`
     * - radius: `8`
     * - color: `hsla(0, 0, 0, 0.25)`
     */
    this.shadowXl = new DropShadow(shadowXl);
    /**
     * - offset: `[0, 4]`
     * - radius: `4`
     * - color: `hsla(0, 0, 0, 0.25)`
     */
    this.shadowLg = new DropShadow(shadowLg);
    /**
     * - offset: `[0, 4]`
     * - radius: `4`
     * - color: `hsla(0, 0, 0.59, 0.25)`
     */
    this.shadowMd = new DropShadow(shadowMd);
    /**
     * - offset: `[0, 4]`
     * - radius: `2`
     * - color: `hsla(0, 0, 0.87, 0.25)`
     */
    this.shadowSm = new DropShadow(shadowSm);
  }
}


module.exports.WihuriDesignSystemTokensShadows = WihuriDesignSystemTokensShadows;

/**
 * Provides an abstraction for [Lottie](https://airbnb.io/lottie/#/) animations.
 *
 */
class Lottie {
  constructor({
    file,
    loop,
    autoplay
  }) {
    /**
     * Lottie data.
     *
     * assets/loadingAnimation.json
     */
    this.file = new File(file);
    /**
     * Lottie data.
     *
     * false
     */
    this.loop = loop;
    /**
     * Lottie data.
     *
     * true
     */
    this.autoplay = autoplay;
  }
}


module.exports.Lottie = Lottie;

const lottie = require('lottie-web');

Object.defineProperties(Lottie.prototype, {
  url: {
    get () {
      return this.file.url;
    },
  },
});

Lottie.prototype.mount = function (ref) {
  lottie.loadAnimation({
    container: ref,
    path: this.url,
    autoplay: this.autoplay,
    loop: this.loop,
  });
};

diezHTMLExtensions.push(() => {
  HTMLElement.prototype.mountLottie = function (lottieComponent) {
    lottieComponent.mount(this);
  };
});

/**
 * Note how this component is exported from `index.ts`. Diez compiles these
 * exported components for your apps' codebases.
 * 
 * For example:
 *    - If you run `yarn start web` or `npm run start web`, Diez will create a Node package called
 *      `diez-diez-web`. Look for `App.jsx` inside `examples/web` to see
 *      how you can use Diez in a web codebase.
 *    - If you run `yarn start ios` or `npm run start ios`, Diez will create a CocoaPods dependency
 *      called `DiezDiez`. Look for `ViewController.swift` inside
 *      `examples/ios` to see how you can use Diez in an iOS codebase.
 *    - If you run `yarn start android` or `npm run start android`, Diez will create an Android library.
 *      Look for `MainActivity.kt` inside `examples/android` to see how you can
 *      use Diez in an Android codebase.
 *   - If you run `yarn start web` or `npm run start web`, Diez will create a Web App with your tokens.
 *
 */
class DesignLanguage {
  constructor({
    colors = {},
    palette = {},
    fontSizes = {},
    sizes = {},
    fonts = {},
    radius = {},
    spacing = {},
    images = {},
    imagesBitmapResponsive = {},
    icons = {},
    layoutValues = {},
    shadows = {},
    loadingAnimation = {file: {src: "assets/loadingAnimation.json", type: "raw"}, loop: false, autoplay: true}
  } = {}) {
    this.colors = new WihuriDesignSystemTokensColors(colors);
    this.palette = new Palette(palette);
    this.fontSizes = new FontSizes(fontSizes);
    this.sizes = new Sizes(sizes);
    this.fonts = new Fonts(fonts);
    this.radius = new Radius(radius);
    this.spacing = new Spacing(spacing);
    this.images = new Images(images);
    this.imagesBitmapResponsive = new WihuriDesignSystemTokensImages(imagesBitmapResponsive);
    this.icons = new Icons(icons);
    this.layoutValues = new LayoutValues(layoutValues);
    this.shadows = new WihuriDesignSystemTokensShadows(shadows);
    /**
     * - file: `assets/loadingAnimation.json`
     * - loop: `false`
     * - autoplay: `true`
     */
    this.loadingAnimation = new Lottie(loadingAnimation);
  }
}

Object.defineProperty(DesignLanguage, 'name', {value: 'DesignLanguage'});

module.exports.DesignLanguage = DesignLanguage;

