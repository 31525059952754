import React, { useContext } from "react";
import { Product, Status } from "@pravda/gdsn-admin-backend/lib/types/product";
import { useApolloClient } from "@apollo/client";
import GlobalStateContext from "globalStateContext";
import {
  UpdateProductAuthorResult,
  UPDATE_PRODUCT_AUTHOR,
} from "./productQueries";
import { Box, Flex, Text, Grid, Heading } from "@chakra-ui/core";
import { PrimaryButton, AlertButton } from "components/primitives/Button";
import { FormattedMessage, useIntl } from "react-intl";
import ProductUtil from "utils/ProductUtil";
import { CardHeader, CardSubHeader } from "components/primitives/Card";
import { CloseIconButton } from "components/primitives/IconButton";
import { Deadline } from "components/primitives/Deadline";

export type ProductModificationHeaderProps = {
  product: Product;
  onAuthorChange: (author?: string) => void;
  onAuthorChangeError: (error: string) => void;
  onCloseForm: () => void;
  onSaveDraft: (product: Product) => void;
  onSaveComment: (product: Product, overrideComment: string|null) => void;
};

const ProductModificationHeader = ({
  product,
  onAuthorChange,
  onAuthorChangeError,
  onCloseForm,
  onSaveDraft,
  onSaveComment,
}: ProductModificationHeaderProps) => {
  const globalState = useContext(GlobalStateContext);
  const client = useApolloClient();
  const intl = useIntl();

  const updateProductAuthor = async (id: string, author?: string) => {
    try {
      await client.mutate<UpdateProductAuthorResult>({
        mutation: UPDATE_PRODUCT_AUTHOR,
        variables: { id, author },
      });
    } catch (error) {
      onAuthorChangeError(error);
    }
  };
  const changeProductAuthor = (author?: string) => {
    // Handle UI author change before updating to database for efficient handling
    onAuthorChange(author);
    updateProductAuthor(product.id, author);
  };

  const isCurrentUserProcessing = (): boolean => {
    return product.author === globalState.currentUser;
  };

  const hasDatamissing = (): boolean => {
    return (
      product.status !== Status.DataMissingFromForm &&
      product.status !== Status.DataMissingFromSynkka
    );
  };

  // Render processing information depending on author and status
  const renderProcessingHeader = () => {
    if (product.status === Status.Todo) {
      return (
        <Box gridColumn="4">
          <PrimaryButton
            float="right"
            onClick={() => changeProductAuthor(globalState.currentUser)}
          >
            <FormattedMessage
              id="productsModification.takeIntoProcessing"
              defaultMessage="Take into processing"
            />
          </PrimaryButton>
        </Box>
      );
    }
    if (hasDatamissing()) {
      return (
        <>
          <Box
            gridRow="1"
            gridColumn={{ base: "3/5", md: "3" }}
            mb={{ base: "1", md: "0" }}
          >
            <Flex
              direction={{ base: "row", md: "column" }}
              width="auto"
              pb="1"
              mr="2"
            >
              <Text pr="2">
                <FormattedMessage
                  tagName="span"
                  id="productsModification.processingTitle"
                  defaultMessage="Processing"
                />
              </Text>
              <Text fontWeight="bold">
                {product.author ? (
                  isCurrentUserProcessing() ? (
                    <Text as="span" color="primary.regular">
                      <FormattedMessage
                        tagName="span"
                        id="productsModification.inYourProcessing"
                        defaultMessage="You"
                      />
                    </Text>
                  ) : (
                    product.author
                  )
                ) : (
                  <FormattedMessage
                    tagName="span"
                    id="productsModification.noAuthor"
                    defaultMessage="No author"
                  />
                )}
              </Text>
            </Flex>
          </Box>
          {ProductUtil.shouldShowFormActions(product) && (
            <Box
              gridRow={{ base: "2", md: "1" }}
              gridColumn={{ base: "3/5", md: "4" }}
            >
              {isCurrentUserProcessing() ? (
                <AlertButton
                  float={{ base: "none", md: "right" }}
                  onClick={() => changeProductAuthor(undefined)}
                >
                  <FormattedMessage
                    id="productsModification.removeFromProcessing"
                    defaultMessage="Remove from processing"
                  />
                </AlertButton>
              ) : (
                <AlertButton
                  float={{ base: "none", md: "right" }}
                  onClick={() => changeProductAuthor(globalState.currentUser)}
                >
                  <FormattedMessage
                    tagName="span"
                    id="productsModification.takeControl"
                    defaultMessage="Take Control"
                  />
                </AlertButton>
              )}
            </Box>
          )}
        </>
      );
    }
    return null;
  };

  return (
    <>
      <CardHeader active={isCurrentUserProcessing()}>
        <Grid
          templateColumns="repeat(8, 1fr)"
          templateRows="2"
          alignItems="center"
          rowGap="0"
          gap={2}
        >
          <Box gridColumn={["1/7", "1/7", "1/6"]} gridRow={["1"]}>
            <Heading
              as="h1"
              my="0"
              fontSize="sm"
              color={isCurrentUserProcessing() ? "white" : "primary.dark"}
            >
              {product.name}
              {ProductUtil.isProductDraft(product) && (
                <Text as="span" ml="2">
                  <FormattedMessage
                    tagName="span"
                    id="productsModification.draft"
                    defaultMessage="(draft)"
                  />
                </Text>
              )}
            </Heading>
          </Box>
          <Box
            gridColumn={["1/7", "1/7", "6/8"]}
            gridRow={["2", "2", "1"]}
            justifySelf={["left", "left", "right"]}
          >
            <Text fontSize="xs">{`GTIN:	${product.gtin}`}</Text>
          </Box>
          <Flex gridColumn={["8/8"]} gridRow={["1/2"]} justifySelf="right">
            <CloseIconButton
              icon="cross"
              m="0"
              p="0"
              aria-label="close"
              onClick={onCloseForm}
            ></CloseIconButton>
          </Flex>
        </Grid>
      </CardHeader>
      <CardSubHeader>
        <Grid
          templateColumns="repeat(5, 1fr)"
          templateRows="2"
          alignItems={{ base: "start", xl: "center" }}
          justifyContent={{ base: "start", xl: "center" }}
          rowGap="0"
          gap={4}
        >
          <Box
            gridRow="1"
            gridColumn={{ base: "1/3", md: "1" }}
            borderRightColor="gray.200"
            borderRightWidth="1px"
            mb={{ base: "1", md: "0" }}
          >
            <FormattedMessage
              tagName="span"
              id="productsModification.amountOfChangesToProcess"
              defaultMessage="Amount of modifications: <b>{amountOfChanges}</b>"
              values={{
                b: (chunks: string) => <b>{chunks}</b>,
                amountOfChanges: product.amountOfChanges,
              }}
            />
          </Box>
          <Box
            gridColumn={{ base: "1/3", md: "2" }}
            gridRow={{ base: "2", md: "1" }}
            borderColor="gray.200"
            borderLeftWidth={!hasDatamissing() ? "1px" : "0"}
          >
            <Flex width="100%" wrap="wrap">
              <Flex>
                <Text mr="1">
                  <FormattedMessage
                    tagName="span"
                    id="productsModification.deadlineTitle"
                    defaultMessage="Deadline"
                  />
                </Text>
              </Flex>
              <Flex>
                {product.deadline && (
                  <Deadline date={new Date(product.deadline)} />
                )}
              </Flex>
            </Flex>
          </Box>
          <Box>
            <PrimaryButton
              onClick={() => onSaveDraft(product)}
              isDisabled={
                !ProductUtil.isCurrentUser(product, globalState.currentUser)
              }
              mr="2"
            >
              {intl.formatMessage({
                id: "productsModification.saveDraft",
                defaultMessage: "Save draft",
              })}
            </PrimaryButton>
          </Box>
          {renderProcessingHeader()}
        </Grid>
      </CardSubHeader>
    </>
  );
};

export default ProductModificationHeader;
