import React from "react";
import {
  Modal,
  ModalOverlay,
  ModalHeader,
  ModalBody,
  ModalFooter,
  ModalContent,
  ModalCloseButton,
} from "@chakra-ui/core";
import { useIntl } from "react-intl";
import { PrimaryButton, TertiaryButton } from "./primitives/Button";
import messages from "translations/commonMessages";

export type ModalProps = {
  isOpen: boolean;
  onClose: () => void;
  children: React.ReactNode;
  title: string | React.ReactNode;
  onAcceptAction?: () => void;
  actionButtonLabel?: string;
  closeButtonLabel?: string | React.ReactNode;
};

const CustomModal = ({
  isOpen,
  onClose,
  children,
  title,
  onAcceptAction,
  actionButtonLabel,
  closeButtonLabel,
}: ModalProps) => {
  const intl = useIntl();
  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>{title}</ModalHeader>
        <ModalCloseButton />
        <ModalBody>{children}</ModalBody>
        <ModalFooter>
          {onAcceptAction && (
            <PrimaryButton
              onClick={() => {
                onAcceptAction();
                onClose();
              }}
            >
              {actionButtonLabel
                ? actionButtonLabel
                : intl.formatMessage(messages.yes)}
            </PrimaryButton>
          )}
          <TertiaryButton mr={3} onClick={onClose}>
            {closeButtonLabel
              ? closeButtonLabel
              : intl.formatMessage(messages.no)}
          </TertiaryButton>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default CustomModal;
