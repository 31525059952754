import styled from "styled-components/macro";
import { Radio, RadioProps } from "@chakra-ui/core";

const PrimaryRadio = styled(Radio)<RadioProps>`
  && > * {
    font-size: inherit;
  }
`;
const AlertRadio = styled(Radio)<RadioProps>`
  && > * {
    font-size: inherit;
  }
`;

const BaseRadioProps: RadioProps = {
  fontSize: "sm",
};

PrimaryRadio.defaultProps = {
  ...BaseRadioProps,
  variantColor: "green",
};

AlertRadio.defaultProps = {
  ...BaseRadioProps,
  variantColor: "red",
};

export { PrimaryRadio, AlertRadio };
