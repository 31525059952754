import {
  Product,
  Status,
  Field,
  FieldType,
  StringFieldData,
  TextFieldData,
  IntFieldData,
  DecimalFieldData,
  DateFieldData,
  BoolFieldData,
  SelectFieldData,
  MultiSelectFieldData,
  MetricInputFieldData,
} from "@pravda/gdsn-admin-backend/lib/types/product";

export default class ProductUtil {
  public static isProductDraft(product: Product) {
    return (
      product.status === Status.ErrorInErp ||
      product.status === Status.InProgress
    );
  }

  public static shouldShowFormActions(product: Product) {
    return (
      product.status === Status.ErrorInErp ||
      product.status === Status.InProgress ||
      product.status === Status.Todo
    );
  }

  public static isCurrentUser(product: Product, author?: string) {
    return product.author === author;
  }

  public static getProcessedFieldsAmount(product: Product) {
    return product.fields.filter(
      (field) => field.approved != null || field.requiresApproval === false
    ).length;
  }

  public static areAllFieldsProcessed(product: Product) {
    return product.fields.every(
      (field) => field.requiresApproval === false || field.approved != null
    );
  }

  public static getFieldDataValue(
    field: Field,
    newOrOld: "new" | "old" = "new"
  ): any {
    switch (field.type) {
      case FieldType.String: {
        const fieldData: StringFieldData = field.data as StringFieldData;
        if (newOrOld === "new") return fieldData.stringNewValue;
        return fieldData.stringOldValue;
      }
      case FieldType.Text: {
        const fieldData: TextFieldData = field.data as TextFieldData;
        if (newOrOld === "new") return fieldData.textNewValue;
        return fieldData.textOldValue;
      }
      case FieldType.Int: {
        const fieldData: IntFieldData = field.data as IntFieldData;
        if (newOrOld === "new") return fieldData.intNewValue;
        return fieldData.intOldValue;
      }
      case FieldType.Decimal: {
        const fieldData: DecimalFieldData = field.data as DecimalFieldData;
        if (newOrOld === "new") return fieldData.decimalNewValue;
        return fieldData.decimalOldValue;
      }

      case FieldType.Date: {
        const fieldData: DateFieldData = field.data as DateFieldData;
        if (newOrOld === "new") return fieldData.dateNewValue;
        return fieldData.dateOldValue;
      }

      case FieldType.Bool: {
        const fieldData: BoolFieldData = field.data as BoolFieldData;
        if (newOrOld === "new") return fieldData.boolNewValue;
        return fieldData.boolOldValue;
      }

      case FieldType.Select: {
        const fieldData: SelectFieldData = field.data as SelectFieldData;
        if (newOrOld === "new") return fieldData.selectNewValue;
        return fieldData.selectOldValue;
      }

      case FieldType.MultiSelect: {
        const fieldData: MultiSelectFieldData = field.data as MultiSelectFieldData;
        if (newOrOld === "new") return fieldData.multiselectNewValue;
        return fieldData.multiselectOldValue;
      }

      case FieldType.Metric: {
        const fieldData: MetricInputFieldData = field.data as MetricInputFieldData;
        if (newOrOld === "new") return fieldData.metricInputNewValue;
        return fieldData.metricInputOldValue;
      }
    }
  }

  public static setFieldDataNewValue(field: Field, newValue: any) {
    switch (field.type) {
      case FieldType.String: {
        const fieldData: StringFieldData = field.data as StringFieldData;
        fieldData.stringNewValue = newValue;
        break;
      }
      case FieldType.Text: {
        const fieldData: TextFieldData = field.data as TextFieldData;
        fieldData.textNewValue = newValue;
        break;
      }
      case FieldType.Int: {
        const fieldData: IntFieldData = field.data as IntFieldData;
        fieldData.intNewValue = newValue;
        break;
      }
      case FieldType.Decimal: {
        const fieldData: DecimalFieldData = field.data as DecimalFieldData;
        fieldData.decimalNewValue = newValue;
        break;
      }

      case FieldType.Date: {
        const fieldData: DateFieldData = field.data as DateFieldData;
        fieldData.dateNewValue = newValue;
        break;
      }

      case FieldType.Bool: {
        const fieldData: BoolFieldData = field.data as BoolFieldData;
        fieldData.boolNewValue = newValue;
        break;
      }

      case FieldType.Select: {
        const fieldData: SelectFieldData = field.data as SelectFieldData;
        fieldData.selectNewValue = newValue;
        break;
      }

      case FieldType.MultiSelect: {
        const fieldData: MultiSelectFieldData = field.data as MultiSelectFieldData;
        fieldData.multiselectNewValue = newValue;
        break;
      }

      case FieldType.Metric: {
        const fieldData: MetricInputFieldData = field.data as MetricInputFieldData;
        fieldData.metricInputNewValue = newValue;
        break;
      }
    }
  }
}
