import styled from "styled-components/macro";
import { IconButton, IconButtonProps } from "@chakra-ui/core";

const PrimaryIconButton = styled(IconButton)<IconButtonProps>`
  & svg {
    transform: scale(1.5);
  }
`;
const CloseIconButton = styled(IconButton)<IconButtonProps>``;

PrimaryIconButton.defaultProps = {
  color: "white",
  bg: "primary.regular",
  borderRadius: "sm",
  _hover: {
    opacity: 0.7,
  },
  _focus: {
    opacity: 0.7,
  },
};

CloseIconButton.defaultProps = {
  variant: "ghost",
  size: "sm",
  icon: "cross",
  borderRadius: "sm",
  _hover: {
    background: "transparent",
  },
  _focus: {
    background: "transparent",
  },
};

export { PrimaryIconButton, CloseIconButton };
