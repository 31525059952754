import React from "react";
import {
  Box,
  Grid,
  RadioGroup,
  FormControl,
  GridProps,
  Text,
} from "@chakra-ui/core";

import {
  Field,
  FieldType,
  StringFieldData,
} from "@pravda/gdsn-admin-backend/lib/types/product";
import { useIntl, FormattedMessage } from "react-intl";

import { AllowedLanguagesType } from "../../translations/locales";
import FormField from "components/FormField";
import { PrimaryRadio } from "components/primitives/Radio";

import messages from "../../translations/commonMessages";
import ProductFormInput from "./ProductFormInput";
import styled from "styled-components/macro";
import ProductUtil from "utils/ProductUtil";

export type ProductFormProps = {
  fields: Field[];
  disabled?: boolean;
  isUpdate: boolean;
  isCommentFieldOnly: boolean;
  onFieldChange: (
    field: Field,
    newValue: any,
    isApproveChange?: boolean
  ) => void;
};

const ProductForm = ({
  fields,
  disabled,
  isUpdate,
  onFieldChange,
  isCommentFieldOnly
}: ProductFormProps): JSX.Element => {
  const intl = useIntl();
  const locale: AllowedLanguagesType = intl.locale as AllowedLanguagesType;

  const onFieldApproveChange = (field: Field, newValue: any) => {
    onFieldChange(field, newValue === "true" ? true : false, true);
  };

  const formRows = fields.map((field) => {
    const showError = !!field.error && field.approved !== false;
    const helpText = field.helpText && field.helpText[locale];
    const fieldData: StringFieldData = field.data as StringFieldData;
    if (isCommentFieldOnly == false && field.path === '$.customtuoteryhmapaallikonkommentti') {
      // No operation 
    }
    else {
      return (
        <FormRow
          key={field.path}
          templateColumns="repeat(3, 1fr)"
          templateRows="2"
          alignItems="start"
          rowGap="0"
          gap={6}
          py={4}
          borderBottomWidth="1px"
          borderBottomColor="gray.200"
        >
          {isUpdate && (
            <FormField
              gridColumn={{ base: "1/4", xl: "1" }}
              gridRow="1"
              pb={{ base: "3", xl: "0" }}
              label={`${field.label[locale] || field.label.fi}${field.requiresApproval ? " *" : ""
                }`}
              customLabelProps={{
                color: showError ? "red.600" : "black",
                minHeight: { base: "none", xl: "30px" },
              }}
              renderInput={() => (
                <ProductFormInput field={field} renderOldValue={true} />
              )}
            />
          )}
          <FormField
            gridColumn={
              isUpdate ? { base: "1/4", xl: "2" } : { base: "1/4", xl: "1" }
            }
            gridRow={isUpdate ? { base: "2", xl: "1" } : "1"}
            pb={{ base: "3", xl: "0" }}
            display="flex"
            flexDirection="column"
            height="100%"
            justifyContent="space-between"
            label={
              isUpdate
                ? intl.formatMessage({
                  id: "productsModification.newValue",
                  defaultMessage: "New value",
                })
                : `${field.label[locale] || field.label.fi}${field.requiresApproval ? " *" : ""
                }`
            }
            customLabelProps={{
              fontWeight: isUpdate ? "normal" : "bold",
              fontSize: isUpdate ? "sm" : "md",
              minHeight: { base: "none", xl: "30px" },
            }}
            error={showError ? field.error : null}
            renderInput={() => (
              <ProductFormInput field={field} onDataChange={onFieldChange} />
            )}
            helpText={
              field.label.fi.includes("Tuotteen nimi") &&
                fieldData.stringNewValue !== undefined &&
                fieldData.stringNewValue !== null
                ? fieldData.stringNewValue.length + "/40. " + helpText
                : helpText
            }
          />
          {field.type !== FieldType.PackageHierarchies &&
            field.requiresApproval && (
              <FormControl
                gridColumn={{ base: "1/4", xl: "3" }}
                gridRow={
                  isUpdate ? { base: "3", xl: "1" } : { base: "2", xl: "1" }
                }
                alignSelf="center"
              >
                <RadioGroup
                  onChange={(event: React.FormEvent<HTMLInputElement>) =>
                    onFieldApproveChange(field, event.currentTarget.value)
                  }
                  value={
                    field.approved != null
                      ? field.approved.toString()
                      : "none"
                  }
                  isInline
                  float={{ base: "none", xl: "right" }}
                >
                  <PrimaryRadio value="true">
                    {intl.formatMessage(messages.accept)}
                  </PrimaryRadio>
                  <PrimaryRadio value="false" variantColor="red">
                    {intl.formatMessage(messages.deny)}
                  </PrimaryRadio>
                </RadioGroup>
              </FormControl>
            )}
        </FormRow>
      );
    }
  });

  const firstProductNameIndex =
    fields.findIndex((field) => field.path.includes("ProductName"));

  const isProductNamingGuidelineVisible =
    !isCommentFieldOnly && firstProductNameIndex !== -1;

  if (isProductNamingGuidelineVisible) {
    formRows.splice(firstProductNameIndex, 0, (
      <FormRow>
        <Text
          color="black"
          fontWeight="bold"
          fontSize="md"
          mt="3"
        >
          <FormattedMessage
            id="productsModification.productNamingGuidelineLabel"
            defaultMessage="Product naming guideline"
          />
        </Text>
        <Text fontSize="sm" mt="3">
          <FormattedMessage
            id="productsModification.productNamingGuidelineText"
            defaultMessage="Brändi/kauppanimi MSC/ASC/luomu variantti yleisnimi alkoholipitoisuus koko erityisominaisuudet"
          />
        </Text>
        <Text fontSize="sm" mt="3">
          <FormattedMessage
            id="productsModification.productNamingGuidelineExample"
            defaultMessage="e.g. Boff watermelon long drink 5,5% 6x0,33l can"
          />
        </Text>
      </FormRow>
    ));
  }

  return (
    <Box
      opacity={disabled ? 0.7 : 1}
      pointerEvents={disabled ? "none" : "auto"}
    >
      {formRows}
            
      {isCommentFieldOnly == false && (<Text mt="3">
        <FormattedMessage
          id="productForm.requiresApprovalExplanation"
          defaultMessage="* = change requires approval"
        />
      </Text>)}

      {fields.length === 0 && (
        <FormattedMessage
          id="productForm.noFieldsToProcess"
          defaultMessage="No fields to process"
        />
      )}
      
    </Box>
  );
};

const FormRow = styled(Grid)<GridProps>`
  && {
    &:first-child {
      padding-top: 0;
    }
  }
`;

export default ProductForm;
