import React from "react";
import styled from "styled-components/macro";
import { Button, ButtonProps, Box, BoxProps } from "@chakra-ui/core";
import { SizeProps } from "styled-system";
interface NavigationButtonProps extends ButtonProps {
  active?: boolean;
  iconSize?: SizeProps;
}

const NavigationButtonText = styled(Box)<BoxProps>``;

const NavigationButton = (props: NavigationButtonProps) => {
  let someProps = {};
  if (props.active) {
    someProps = {
      bg: "primary.dark",
      color: "white",
      _hover: {
        color: "white",
      },
    };
  }
  const changedProps = { ...props, ...someProps };
  return <StyledNavigationButton {...changedProps} />;
};

const StyledNavigationButton = styled(Button)<
  NavigationButtonProps & { active?: boolean }
>`
  & svg {
    transform: scale(1.6);
    fill: ${({ theme }) => theme.colors.primary.regular};
  }
  & {
    border-left: 0;
    ${({ active }) =>
      active &&
      `
    svg {
      fill: currentColor
    }
  `}
  }
`;

NavigationButton.defaultProps = {
  color: "gray.900",
  borderRadius: "0",
  variant: "ghost",
  size: "sm",
  fontSize: "sm",
  fontWeight: "bold",
  borderLeftWidth: { base: "1px", md: "0" },
  borderLeftColor: "gray.100",
  borderLeftStyle: "solid",
  display: "flex",
  iconSpacing: { base: "0", md: "4" },
  height: "headerHeight",
  px: { base: "6", md: "8" },

  _hover: {
    color: "primary.dark",
  },
  _disabled: {
    cursor: "not-allowed",
    color: "gray.200",
  },
};

NavigationButtonText.defaultProps = {
  display: { base: "none", md: "block" },
};

export { NavigationButton, NavigationButtonText };
