import React, { useContext } from "react";
import { Box, Flex, Text } from "@chakra-ui/core";
import { PrimaryButton } from "components/primitives/Button";
import GlobalStateContext from "globalStateContext";
import { FormattedMessage } from "react-intl";

const Login = () => {
  const globalState = useContext(GlobalStateContext);

  return (
    <Box height="100vh" width="100%" position="relative">
      <Flex
        direction="column"
        justify="center"
        position="absolute"
        left="50%"
        top="50%"
        transform="translate(-50%, -50%)"
        width={{ base: "auto", sm: "300px" }}
      >
        {globalState.loginError && (
          <Text fontWeight="bold" fontSize="md" color="red.600" mb="3">
            {globalState.loginError}
          </Text>
        )}
        <PrimaryButton onClick={globalState.login} size="lg">
          <FormattedMessage id="login.login" defaultMessage="Login" />
        </PrimaryButton>
      </Flex>
    </Box>
  );
};

export default Login;
