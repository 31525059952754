import React, { useContext } from "react";
import { Link, useHistory, useLocation } from "react-router-dom";
import { Box, ButtonGroup, Divider, Image, Flex, Text } from "@chakra-ui/core";
import GlobalStateContext from "globalStateContext";
import {
  NavigationButton,
  NavigationButtonText,
} from "./primitives/NavigationButton";
import { FormattedMessage, useIntl } from "react-intl";

import customTheme from "../theme";
import routes from "../routes/routes.json";
import { isMobileViewport } from "utils/viewportCalculator";

const Navigation = (props: any) => {
  const globalState = useContext(GlobalStateContext);
  const history = useHistory();
  const intl = useIntl();
  const location = useLocation();

  const redirectToProcessingQueue = () => {
    if (existsInCurrentPath(routes.products)) {
      if (isMobileViewport(window) && !globalState.showSidebar) {
        globalState.setGlobalState({
          showSidebar: true,
        });
        history.replace(`/${intl.locale}${routes.products}`);
      } else {
        globalState.setGlobalState({
          showSidebar: !globalState.showSidebar,
        });
      }
    } else {
      globalState.setGlobalState({
        showSidebar: true,
      });
      history.replace(`/${intl.locale}${routes.products}`);
    }
  };

  const logout = () => {
    globalState.logout();
  };

  const existsInCurrentPath = (route: string): boolean => {
    if (location.pathname.includes(route)) return true;
    return false;
  };

  const redirectToFileImport = () => {
    history.replace(`/${intl.locale}${routes["file-import"]}`);
  };

  return (
    <Flex
      bg="#fff"
      justifyContent="space-between"
      alignItems={["start", "start", "start", "center"]}
      boxShadow="sm"
      zIndex={100}
      position="sticky"
      height="headerHeight"
      borderBottomWidth="2px"
      borderBottomColor="gray.100"
      top="0px"
      {...props}
    >
      <Link to={`/${intl.locale}`}>
        <Flex>
          <Image m="3" src={customTheme.logo.url} alt="logo" />
          <Divider ml="0" mr="4" orientation="vertical" />
          <Text
            py="3"
            alignSelf="center"
            fontSize={["sm", "sm", "md", "md"]}
            fontWeight="bold"
            color="primary.dark"
          >
            {"GDSN Admin"}
          </Text>
        </Flex>
      </Link>
      <Box>
        <ButtonGroup>
          <NavigationButton
            onClick={redirectToProcessingQueue}
            active={
              globalState.showSidebar && existsInCurrentPath(routes.products)
            }
            leftIcon="bulletList"
          >
            <NavigationButtonText>
              <FormattedMessage
                id="navigation.processingQueue"
                defaultMessage="Processing queue"
              />
            </NavigationButtonText>
          </NavigationButton>
        </ButtonGroup>
        <ButtonGroup>
          <NavigationButton
            active={existsInCurrentPath(routes["file-import"])}
            onClick={redirectToFileImport}
            leftIcon="download"
          >
            <NavigationButtonText>
              <FormattedMessage
                id="navigation.bringFromFile"
                defaultMessage="Bring from file"
              />
            </NavigationButtonText>
          </NavigationButton>
        </ButtonGroup>
        <ButtonGroup>
          <NavigationButton onClick={logout} leftIcon="settings">
            <NavigationButtonText>
              <FormattedMessage
                id="navigation.logout"
                defaultMessage="Logout"
              />
            </NavigationButtonText>
          </NavigationButton>
        </ButtonGroup>
      </Box>
    </Flex>
  );
};

export default Navigation;
