import React from "react";
import {
  FormControl,
  FormLabel,
  Text,
  BoxProps,
  FormHelperText,
} from "@chakra-ui/core";

export type FormFieldProps = BoxProps & {
  label: string;
  renderInput: () => JSX.Element | null | undefined;
  error?: string | null;
  customLabelProps?: BoxProps;
  helpText?: string | null;
};

const FormField = ({
  label,
  renderInput,
  error,
  customLabelProps,
  helpText,
  ...rest
}: FormFieldProps) => {
  return (
    <FormControl {...rest}>
      <FormLabel
        color={error ? "red.600" : "black"}
        fontWeight="bold"
        fontSize="md"
        mb="2"
        {...customLabelProps}
      >
        {label}
      </FormLabel>
      {renderInput()}
      {helpText && <FormHelperText>{helpText}</FormHelperText>}
      {error && (
        <Text color="red.600" fontSize="sm">
          {error}
        </Text>
      )}
    </FormControl>
  );
};

export default FormField;
