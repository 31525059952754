import React from "react";

export interface IGlobalState {
  currentUser?: string;
  loginError?: string;
  showSidebar: boolean;
  readonly setGlobalState: (newState: Partial<IGlobalState>) => void;
  readonly login: () => void;
  readonly logout: () => void;
}

const state: IGlobalState = {
  showSidebar: false,
  setGlobalState: () => {},
  login: () => {},
  logout: () => {},
};

const GlobalStateContext = React.createContext(state);
export default GlobalStateContext;
