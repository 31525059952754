import styled from "styled-components/macro";
import React, { useContext } from "react";
import { Flex, FlexProps } from "@chakra-ui/core";
import globalStateContext from "../globalStateContext";

const SidebarHeader = styled(Flex)<FlexProps>``;

SidebarHeader.defaultProps = {
  bg: "primary.dark",
  color: "white",
  py: "4",
  position: "sticky",
  top: "0",
  px: "8",
  zIndex: 101,
};

const StyledSidebar = styled(Flex)<FlexProps>``;

StyledSidebar.defaultProps = {
  bg: "#fff",
  top: "headerHeight",
  zIndex: 100,
  marginTop: "-2px",
  fontWeight: "bold",
  width: "auto",
  minWidth: { base: "100%", lg: "400px", xl: "500px" },
  maxWidth: { base: "100%", lg: "400px", xl: "500px" },
  fontSize: "sm",
  height: "100%",
  flexDirection: { base: "row", lg: "column" },
};

const SidebarInner = styled(Flex)<FlexProps>`
  height: calc(100vh - ${({ theme }) => theme.sizes.headerHeight});
`;

SidebarInner.defaultProps = {
  flexDirection: "column",
  position: "fixed",
  zIndex: 99,
  background: "white",
  boxShadow: "md",
  overflow: "auto",
  minWidth: { base: "100%", lg: "400px", xl: "500px" },
  maxWidth: { base: "100%", lg: "400px", xl: "500px" },
};

const Sidebar = ({ children }: { children: React.ReactNode }) => {
  const state = useContext(globalStateContext);
  if (!state.showSidebar) {
    return <span />;
  }
  return <StyledSidebar> {children}</StyledSidebar>;
};

export { Sidebar, SidebarHeader, SidebarInner };
