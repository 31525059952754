import React, { useEffect, useState, useContext, useCallback } from "react";
import {
  useApolloClient,
  ApolloError,
  ApolloQueryResult,
} from "@apollo/client";
import {
  Text,
  ButtonGroup,
  Icon,
  useTheme,
  Flex,
  CircularProgress,
  Box,
} from "@chakra-ui/core";
import { FormattedMessage, useIntl } from "react-intl";
import { useHistory, Prompt } from "react-router-dom";

import { Card, CardContent, CardFooter } from "components/primitives/Card";
import {
  PrimaryButton,
  TertiaryButton,
  AlertButton,
} from "components/primitives/Button";
import HandleQuery from "components/HandleQuery";
import ProductForm from "./ProductForm";

import {
  GET_PRODUCT,
  GetProductQueryResult,
  UPDATE_PRODUCT_DRAFT,
  UpdateProductDraftResult,
  DELETE_PRODUCT,
  DeleteProductResult,
  UpdateProductStatusResult,
  UPDATE_PRODUCT_STATUS,
  UPDATE_PRODUCT_COMMENT,
  SaveProductResult,
  SAVE_PRODUCT,
} from "./productQueries";
import routes from "../../routes/routes.json";
import messages from "translations/commonMessages";
import {
  Product,
  Status,
  Field,
  FieldInput,
  FieldType,
  SelectFieldData,
  MultiSelectFieldData,
  StringFieldData,
} from "@pravda/gdsn-admin-backend/lib/types/product";
import ProductUtil from "utils/ProductUtil";
import ProductModificationHeader from "./ProductModificationHeader";
import GlobalStateContext from "globalStateContext";
import Notification, { GeneralMessage } from "components/Notification";
import Modal from "components/Modal";
import { CodeListResult } from "@pravda/gdsn-admin-backend/lib/types/code-list";
import { GetCodeListItemsResult, GET_CODE_LIST_ITEMS } from "generalQueries";
import { SelectOption } from "components/primitives/Select";
import { isNullOrUndefined } from "util";
import { AllowedLanguagesType } from "translations/locales";

export type ProductModificationProps = {
  productId: string;
  updateProductList?: () => void;
};

const ProductModification = ({
  productId,
  updateProductList,
}: ProductModificationProps) => {
  const intl = useIntl();
  const locale = intl.locale as AllowedLanguagesType;
  const history = useHistory();
  const client = useApolloClient();
  const theme = useTheme();
  const globalState = useContext(GlobalStateContext);
  const [unsavedChanges, setUnsavedChanges] = useState(false);
  const [loading, setLoading] = useState(false);
  const [generalMessage, setGeneralMessage] = useState<GeneralMessage>();
  const [apolloError, setApolloError] = useState<ApolloError>();
  const [product, setProduct] = useState<Product | null>();
  const [currentProductId, setCurrentProductId] = useState<string>();
  const [topBannerHeight, setTopBannerHeight] = useState(0);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [isCancelModalOpen, setIsCancelModalOpen] = useState(false);

  const getProduct = useCallback(
    async (useNoCache: boolean = false) => {
      setLoading(true);
      try {
        const { data } = await client.query<GetProductQueryResult>({
          query: GET_PRODUCT,
          variables: { id: productId },
          fetchPolicy: useNoCache ? "no-cache" : undefined,
        });

        let prod = data?.getProduct;
        if (prod) {
          let field: Field = {
            approved: true,
            editable: true,
            label: {
              en: intl.formatMessage({
                    id: "productsModification.customtuoteryhmapaallikonkommenttiTitle",
                    defaultMessage: "Product group manager's comment",
                  }),
              fi: intl.formatMessage({
                    id: "productsModification.customtuoteryhmapaallikonkommenttiTitle",
                    defaultMessage: "Product group manager's comment",
                  }),
            },
            data: {
              textNewValue: isNullOrUndefined(prod.customtuoteryhmapaallikonkommentti) ? "" : prod.customtuoteryhmapaallikonkommentti,
              textOldValue: isNullOrUndefined(prod.customtuoteryhmapaallikonkommentti) ? "" : prod.customtuoteryhmapaallikonkommentti,
            },
            path: "$.customtuoteryhmapaallikonkommentti",
            requiresApproval: false,
            type: FieldType.Text,
          };
          prod.fields.splice(0, 0, field);

          if (prod.status !== "DATA_SENT") {
            const productFields = JSON.parse(
              JSON.stringify(prod.fields)
            ) as Field[];
            setCnCodeField(prod, productFields);
            await setSecondaryMeasurementFields(prod, productFields);
          }
          
        }
        

        setProduct(prod);

        if (data?.getProduct.error) {
          setGeneralMessage({
            type: "error",
            message: data.getProduct.error,
          });
        }
      } catch (error) {
        setProduct(null);
        console.log('getProduct - catch error: ', error);
        setApolloError(error);
      }
      setLoading(false);
    },
    [client, productId]
  );

  useEffect(() => {
    // Clear errors on route change
    if (currentProductId !== productId) {
      clearErrors();
      // If not already fetching, refetch product
      if (!loading) {
        getProduct(true);
        setCurrentProductId(productId);
      }
    }
    if (product === undefined && !loading) {
      getProduct(true);
      setCurrentProductId(productId);
    }

    // Update top banner height if needed
    const currentTopBannerHeight = getTopBannerHeight();
    if (currentTopBannerHeight !== topBannerHeight) {
      setTopBannerHeight(currentTopBannerHeight);
    }

    // Open Prompt if user tries to change route before saving
    if (unsavedChanges) {
      window.onbeforeunload = () => true;
    } else {
      window.onbeforeunload = null;
    }
  });

  const handleAuthorChange = (author?: string) => {
    if (product) {
      const newProduct: Product = { ...product };
      if (product.status === Status.Todo) {
        newProduct.status = Status.InProgress;
      }
      setProduct({
        ...newProduct,
        author,
      });
    } else {
      getProduct();
    }
  };

  const handleAuthorChangeError = (error: string | Error) => {
    setGeneralError(error);
    getProduct();
  };

  const getCustomTuotepaalikonKommentti = (product: Product) => {
    let field: Field | null = null;
    product.fields.forEach((element) => {
      if (element.path === '$.customtuoteryhmapaallikonkommentti') {
        field = {
          approved: true,
          editable: true,
          label: element.label,
          data: element.data,
          path: element.path,
          requiresApproval: false,
          type: FieldType.Text,
        };
      }
    });      
    return (field == null) ? "" : ProductUtil.getFieldDataValue(field, "new");
  }
  
  const setCnCodeField = (product: Product, productFields: Field[]) => {
    const matchCNCodeFieldIndex = productFields.findIndex(
      (cnCodeField) => cnCodeField.path === "$.CNCode"
    );
    if (matchCNCodeFieldIndex >= 0) {
      const field = productFields[matchCNCodeFieldIndex];
      if (field) {
        const fieldData: SelectFieldData = field.data as SelectFieldData;
        const cncodeValue = fieldData.selectNewValue;
        if (cncodeValue?.length === 8) {
          productFields[matchCNCodeFieldIndex].editable = false;
          setProduct({ ...product, fields: productFields });
        }
      }
    }
  };

  const setSecondaryMeasurementFields = async (
    product: Product,
    productFields: Field[]
  ) => {
    const matchCNCodeFieldIndex = productFields.findIndex(
      (cnCodeField) => cnCodeField.path === "$.CNCode"
    );
    if (matchCNCodeFieldIndex >= 0) {
      const field = productFields[matchCNCodeFieldIndex];
      const fieldData: SelectFieldData = field.data as SelectFieldData;
      const cncodeValue = fieldData.selectNewValue;
      const defaultLimit = 50;
      const keyword = cncodeValue || "";
      let resultSet: SelectOption[] = [];
      if (cncodeValue?.length === 8) {
        try {
          const result = await queryCodeListItems(
            "cn_codes",
            keyword,
            undefined,
            undefined,
            defaultLimit //+ excluded.length
          );
          resultSet = mapCodeListItems(result);
        } catch (error) {
          console.log('setSecondaryMeasurementFields - error', error.message);
        }
      }
      if (resultSet) {
        const matchSecondaryUnitFieldIndex = productFields.findIndex(
          (secondaryUnitField) =>
            secondaryUnitField.path === "$.SecondaryMeasurement.Unit"
        );
        const matchSecondaryValueFieldIndex = productFields.findIndex(
          (secondaryValueField) =>
            secondaryValueField.path === "$.SecondaryMeasurement.Value"
        );
        if (matchSecondaryUnitFieldIndex >= 0 && matchSecondaryValueFieldIndex >= 0) {
          ProductUtil.setFieldDataNewValue(
            productFields[matchSecondaryUnitFieldIndex],
            resultSet[0]?.SecondaryMeasurementUnit === "ZZZ"
              ? ""
              : resultSet[0]?.SecondaryMeasurementUnit
          );
        }
        if (
          resultSet[0]?.SecondaryMeasurementUnit === "ZZZ" ||
          resultSet.length === 0
        ) {
          if (productFields[matchSecondaryValueFieldIndex]) { // PRAV-1037: Sometimes this was undefined, added this check to avoid JavaScript-errors
            ProductUtil.setFieldDataNewValue(
              productFields[matchSecondaryValueFieldIndex],
              null
            );
            productFields[matchSecondaryValueFieldIndex].editable = false;
            productFields[matchSecondaryValueFieldIndex].requiresApproval = false;
            productFields[matchSecondaryValueFieldIndex].approved = false;
          }
        } else {
          if (productFields[matchSecondaryValueFieldIndex]) { // PRAV-1037: Sometimes this was undefined, added this check to avoid JavaScript-errors
            productFields[matchSecondaryValueFieldIndex].editable = true;
            productFields[matchSecondaryValueFieldIndex].requiresApproval = true;
            productFields[matchSecondaryValueFieldIndex].approved = null;
          }
        }
        setProduct({ ...product, fields: productFields });
      }
    }
  };

  const queryCodeListItems = useCallback(
    async (
      codeListId: string,
      keyword?: string,
      includedCodes?: string[],
      excludedCodes?: string[],
      limit?: number
    ): Promise<CodeListResult> => {
      const filters = {
        keyword,
        includedCodes,
        excludedCodes,
        locale: intl.locale,
      };
      try {
        const result: ApolloQueryResult<GetCodeListItemsResult> =
          await client.query({
            query: GET_CODE_LIST_ITEMS,
            variables: { codeListId, filters, limit },
          });
        const data = result.data?.getCodeListItems;
        if (data) {
          return data;
        } else {
          console.log('Something went wrong with apollo query');
          throw new Error("Something went wrong with apollo query");
        }
      } catch (error) {
        console.log('catch(error): ', error);
        return Promise.reject(error);
      }
    },
    [client]
  );

  const mapCodeListItems = (data: CodeListResult) => {
    const codeListItems: SelectOption[] = data.result.map<SelectOption>(
      (item) => ({
        value: item.code,
        // @ts-ignore
        label: item.label[intl.locale] || item.label.fi,
        emailAddress: item.emailAddress || "",
        SecondaryMeasurementUnit: item.SecondaryMeasurementUnit,
      })
    );
    return codeListItems;
  };

  const updateProductDraft = async (product: Product) => {
    try {
      await client.mutate<UpdateProductDraftResult>({
        mutation: UPDATE_PRODUCT_DRAFT,
        variables: {
          id: product.id,
          fields: mapProductFieldsInput(product.fields),
          customtuoteryhmapaallikonkommentti: getCustomTuotepaalikonKommentti(product),
        },
      });
      setGeneralMessage({
        type: "success",
        message: intl.formatMessage({
          id: "productsModification.draftSaveSuccess",
          defaultMessage: "Draft saved successfully",
        }),
      });
      setTopBannerHeight(getTopBannerHeight);
    } catch (error) {
      setGeneralError(error);
      getProduct();
    }
    setUnsavedChanges(false);
  };

  const updateProductComment = async (product: Product, overrideComment: string|null) => {
    try {
      let theComment = overrideComment;      
      if (overrideComment == null) { theComment = getCustomTuotepaalikonKommentti(product); }
      await client.mutate<UpdateProductDraftResult>({
        mutation: UPDATE_PRODUCT_COMMENT,
        variables: {
          id: product.id,
          customtuoteryhmapaallikonkommentti: theComment,
        },
      });      
      //console.log(theComment);
      setGeneralMessage({
        type: "success",
        message: intl.formatMessage({
          id: "productsModification.commentSaveSuccess",
          defaultMessage: "Comment saved successfully",
        }),
      });
      setTopBannerHeight(getTopBannerHeight);
    } catch (error) {
      setGeneralError(error);
      getProduct();
    }
    setUnsavedChanges(false);
  };

  const saveProduct = async (product: Product) => {
    setLoading(true);
    try {
      const result = await client.mutate<SaveProductResult>({
        mutation: SAVE_PRODUCT,
        variables: {
          id: product.id,
          fields: mapProductFieldsInput(product.fields),
          customtuoteryhmapaallikonkommentti: getCustomTuotepaalikonKommentti(product),
        },
      });
      const newProduct = result.data?.saveProduct;
      if (newProduct) {
        setProduct(newProduct);
        if (newProduct.error) {
          setGeneralError(newProduct.error);
        } else {
          setGeneralMessage({
            type: "success",
            message: intl.formatMessage({
              id: "productsModification.savedSuccesfully",
              defaultMessage: "Product saved and sent to be ERP successfully",
            }),
          });
        }
      }
      setTopBannerHeight(getTopBannerHeight);
      if (updateProductList) updateProductList();
      window.scrollTo(0, 0);
    } catch (error) {
      setGeneralError(error);
      getProduct();
    }
    setUnsavedChanges(false);
    setLoading(false);
  };

  const mapProductFieldsInput = (fields: Field[]): FieldInput[] => {
    return fields.map((field) => {
      const fieldInput: FieldInput = {
        path: field.path,
        approved: field.approved,
      };
      switch (field.type) {
        case FieldType.MultiSelect:
          const fieldData: MultiSelectFieldData =
            field.data as MultiSelectFieldData;
          fieldInput.newMultiSelectValue = fieldData.multiselectNewValue;

          break;
        default:
          const newValue = ProductUtil.getFieldDataValue(field);
          fieldInput.newValue = newValue != null ? newValue.toString() : "";
          break;
      }
      return fieldInput;
    });
  };

  const clearErrors = () => {
    setGeneralMessage(undefined);
    setApolloError(undefined);
    setUnsavedChanges(false);
  };

  const closeForm = () => {
    history.replace(`/${intl.locale}${routes.products}`);
  };

  const handleDeleteProduct = async () => {
    setLoading(true);
    if (product) {
      try {
        await client.mutate<DeleteProductResult>({
          mutation: DELETE_PRODUCT,
          variables: { id: product.id },
        });
        if (updateProductList) updateProductList();
        getProduct(true);
        setGeneralMessage({
          type: "success",
          message: intl.formatMessage({
            id: "productsModification.productDeleteSuccess",
            defaultMessage: "Product deleted succesfully",
          }),
        });
      } catch (error) {
        setGeneralError(error);
        getProduct();
      }
    }
    setLoading(false);
  };

  const handleProductCancel = async () => {
    setLoading(true);
    if (product) {
      try {
        await client.mutate<UpdateProductStatusResult>({
          mutation: UPDATE_PRODUCT_STATUS,
          variables: {
            id: product.id,
            status: Status.Cancelled,
            handled: new Date(),
          },
        });
        if (updateProductList) updateProductList();
        getProduct(true);
        setUnsavedChanges(false);
      } catch (error) {
        setGeneralError(error);
        getProduct();
      }
    }
    setLoading(false);
  };

  const setGeneralError = (error: Error | string) => {
    setGeneralMessage({
      type: "error",
      message: intl.formatMessage(messages.errorOccurred, {
        error: typeof error === "string" ? error : error.message,
      }),
    });
  };

  const onFieldChange = (
    field: Field,
    newValue: any,
    isApproveChange?: boolean
  ) => {
    if (product) {
      // Deep copy of product fields to be able to change field value
      const productFields = JSON.parse(
        JSON.stringify(product.fields)
      ) as Field[];
      const matchFieldIndex = productFields.findIndex(
        (oldField) => oldField.path === field.path
      );
      if (matchFieldIndex >= 0) {
        // Check if changing is to approved-field or the value of the field
        if (isApproveChange) {
          productFields[matchFieldIndex].approved = newValue;
          setProduct({ ...product, fields: productFields });
        } else {
          ProductUtil.setFieldDataNewValue(
            productFields[matchFieldIndex],
            newValue
          );
          if (field.path === "$.CNCode") {
            setSecondaryMeasurementFields(product, productFields);
          }
          setProduct({ ...product, fields: productFields }); // Update product fields
        }
        setUnsavedChanges(true);
      }
    }
  };

  const updateProductCommentOnChange = (
    field: Field,
    newValue: any,
    isApproveChange?: boolean
  ) => {
    if (product) {
      updateProductComment(product, newValue);
    }
  };

  const getTopBannerHeight = () => {
    let topBannerHeight = 0;
    const topBanner = document.getElementById("product-top-banner");
    if (topBanner) {
      topBannerHeight = topBanner.clientHeight;
    }
    return topBannerHeight;
  };

  const getCardMarginTop = (topBannerHeight: number) => {
    const baseFontSize = parseInt(theme.fontSizes.baseFontSize);
    return {
      base: `${topBannerHeight + baseFontSize * 0.5}px`,
      md: `${topBannerHeight + baseFontSize}px`,
      lg: `${topBannerHeight + baseFontSize * 1.5}px`,
    };
  };

  const renderProductContent = (product: Product) => {
    if (product.status === Status.DataMissingFromForm) {
      return (
        <>
          <FormattedMessage
            id="productsModification.dataMissingFromForm"
            defaultMessage="There is some data missing from this product's form message."
          />
          <p>&nbsp;</p>
          <ProductForm
            fields={[product.fields[0]]}
            onFieldChange={updateProductCommentOnChange}
            disabled={false}
            isUpdate={!!product.isUpdate}
            isCommentFieldOnly={true}
          />
          <p>&nbsp;</p>
          <p>
            <FormattedMessage id="productsModification.productGtinLabel" defaultMessage="GTIN:" />
            &nbsp;
            {product.gtin}
          </p>
        </>
      );
    }
    if (product.status === Status.DataMissingFromSynkka) {
      return (
        <>
          <FormattedMessage
            id="productsModification.dataMissingFromSynkka"
            defaultMessage="There is some data missing from this product's Synkka message."            
          />
          <p>&nbsp;</p>
          <ProductForm
            fields={[product.fields[0]]}
            onFieldChange={updateProductCommentOnChange}
            disabled={false}
            isUpdate={!!product.isUpdate}
            isCommentFieldOnly={true}
          />
          <p>&nbsp;</p>          
          <p>
            <FormattedMessage id="productsModification.productGtinLabel" defaultMessage="GTIN:" />
            &nbsp;
            {product.gtin}
          </p>
        </>
      );
    }
    if (product.status === Status.DataSent) {
      return (
        <FormattedMessage
          id="productsModification.dataSentToErp"
          defaultMessage="The data of this product is sent to be processed in ERP."
        />
      );
    }
    if (product.status === Status.Done) {
      // PRAV-845
      // We have to display three things:
      //  1. Comment
      //  2. Substituted product
      //  3. Material group
      // What we can do here is to find what we want from the product fields.
      // This approach does not require any modifications to the GraphQL schema or database models.
      
      const substitutedProductField= product.fields.find(f => f.path === "$.SubstitutedProduct");
      const substitutedProduct = substitutedProductField !== undefined
        ? (substitutedProductField.data as StringFieldData).stringNewValue
        : undefined;

      const materialGroupField = product.fields.find(f => f.path === "$.MaterialGroup");
      const materialGroup = materialGroupField !== undefined
        ? (materialGroupField.data as SelectFieldData).selectNewValue
        : undefined;
        
      const label = (field: Field) => field.label[locale] || field.label.fi;
      
      return (
        <>
          {product.customtuoteryhmapaallikonkommentti && (
            <>
              <Text fontWeight="bold" fontSize="md">
                <FormattedMessage
                  id="productsModification.customtuoteryhmapaallikonkommenttiTitle"
                  defaultMessage="Product group manager's comment"
                />
              </Text>
              <Text>{product.customtuoteryhmapaallikonkommentti}</Text>
            </>
          )}
          {substitutedProduct && (
            <>
              <Text fontWeight="bold" fontSize="md" mt="3">{label(substitutedProductField!!)}</Text>
              <Text>{substitutedProduct}</Text>
            </>
          )}
          {materialGroup && (
            <>
              <Text fontWeight="bold" fontSize="md" mt="3">{label(materialGroupField!!)}</Text>
              <Text>{materialGroup}</Text>
            </>
          )}
        </>
      );
    }
    if (product.status === Status.Cancelled) {
      return (
        <FormattedMessage
          id="productsModification.dataProcessingCancelled"
          defaultMessage="The data processing of this product is cancelled."
        />
      );
    }
    return (
      <>
        <ProductForm
          fields={[product.fields[0]]}
          onFieldChange={updateProductCommentOnChange}
          disabled={false}
          isUpdate={!!product.isUpdate}
          isCommentFieldOnly={true}
        />      
        <ProductForm
          fields={product.fields}
          onFieldChange={onFieldChange}
          disabled={!ProductUtil.isCurrentUser(product, globalState.currentUser)}
          isUpdate={!!product.isUpdate}          
          isCommentFieldOnly={false}
        />
      </>
    );
  };

  return (
    <>
      {generalMessage && (
        <Notification
          notification={generalMessage}
          id="product-top-banner"
          zIndex={102}
          position={{ base: "relative", md: "fixed" }}
          left={{
            base: "0",
            md: globalState.showSidebar ? "400px" : 0,
            xl: globalState.showSidebar ? "500px" : 0,
          }}
          right="0"
        />
      )}
      <Card
        margin={{ base: "2", md: "4", lg: "6" }}
        marginTop={getCardMarginTop(topBannerHeight)}
      >
        <Prompt
          when={unsavedChanges}
          message={intl.formatMessage({
            id: "productsModification.unsavedChanges",
            defaultMessage:
              "You have unsaved changes, are you sure you want to leave?",
          })}
        />
        <HandleQuery loading={loading} error={apolloError}>
          {product ? (
            <>
              <ProductModificationHeader
                product={product}
                onAuthorChange={handleAuthorChange}
                onAuthorChangeError={handleAuthorChangeError}
                onCloseForm={closeForm}
                onSaveDraft={updateProductDraft}
                onSaveComment={updateProductComment}
              />
              <CardContent>{renderProductContent(product)}</CardContent>
              <CardFooter>
                {ProductUtil.shouldShowFormActions(product) && (
                  <Flex wrap="wrap" alignItems="center" float="left">
                    <CircularProgress
                      trackColor="green"
                      value={
                        Math.floor(
                          (ProductUtil.getProcessedFieldsAmount(product) /
                            product.amountOfChanges) *
                          100
                        ) || 0
                      }
                      color="green"
                      mr="2"
                      thickness={0.3}
                    />
                    <Text fontSize="sm" fontWeight="bold" color="primary.dark">
                      <FormattedMessage
                        id="productsModification.amountOfChangesProcessed"
                        defaultMessage="{amountOfChangesProcessed} processed"
                        values={{
                          amountOfChangesProcessed: `${ProductUtil.getProcessedFieldsAmount(
                            product
                          )}/${product.amountOfChanges}`,
                        }}
                      />
                    </Text>
                  </Flex>
                )}

                <Flex direction="column" float="right">
                  <ButtonGroup>
                    <TertiaryButton onClick={closeForm}>
                      {intl.formatMessage(messages.cancel)}
                    </TertiaryButton>
                    {(product.status === Status.Done ||
                      product.status === Status.Cancelled) && (
                        <>
                          <Modal
                            isOpen={isDeleteModalOpen}
                            onAcceptAction={handleDeleteProduct}
                            onClose={() => setIsDeleteModalOpen(false)}
                            title={
                              <FormattedMessage
                                id="productsModification.deleteProductModalTitle"
                                defaultMessage="Delete product?"
                              />
                            }
                          >
                            <FormattedMessage
                              id="productsModification.deleteProductModalBody"
                              defaultMessage={
                                'Are you sure you want to delete product with id: "{id}"?'
                              }
                              values={{ id: productId }}
                            />
                          </Modal>
                          <AlertButton
                            onClick={() => setIsDeleteModalOpen(true)}
                            isDisabled={
                              !ProductUtil.isCurrentUser(
                                product,
                                globalState.currentUser
                              )
                            }
                          >
                            {intl.formatMessage({
                              id: "productsModification.deleteFromQueue",
                              defaultMessage: "Delete from processing queue",
                            })}
                          </AlertButton>
                        </>
                      )}
                    {ProductUtil.shouldShowFormActions(product) && (
                      <>
                        <Modal
                          isOpen={isCancelModalOpen}
                          onAcceptAction={handleProductCancel}
                          onClose={() => setIsCancelModalOpen(false)}
                          title={
                            <FormattedMessage
                              id="productsModification.cancelProductModalTitle"
                              defaultMessage="Cancel product processing?"
                            />
                          }
                        >
                          <FormattedMessage
                            id="productsModification.cancelProductModalBody"
                            defaultMessage={
                              'Are you sure you want to cancel processing of product with id: "{id}"'
                            }
                            values={{ id: productId }}
                          />
                        </Modal>
                        <AlertButton
                          onClick={() => setIsCancelModalOpen(true)}
                          isDisabled={
                            !ProductUtil.isCurrentUser(
                              product,
                              globalState.currentUser
                            )
                          }
                          mr="2"
                        >
                          {intl.formatMessage({
                            id: "productsModification.cancelProcessing",
                            defaultMessage: "Cancel processing",
                          })}
                        </AlertButton>
                        <PrimaryButton
                          onClick={() => updateProductDraft(product)}
                          isDisabled={
                            !ProductUtil.isCurrentUser(
                              product,
                              globalState.currentUser
                            )
                          }
                          mr="2"
                        >
                          {intl.formatMessage({
                            id: "productsModification.saveDraft",
                            defaultMessage: "Save draft",
                          })}
                        </PrimaryButton>
                        <PrimaryButton
                          onClick={() => saveProduct(product)}
                          isDisabled={
                            !ProductUtil.isCurrentUser(
                              product,
                              globalState.currentUser
                            ) || !ProductUtil.areAllFieldsProcessed(product)
                          }
                        >
                          {intl.formatMessage(messages.save)}
                        </PrimaryButton>
                      </>
                    )}
                  </ButtonGroup>
                  {product.fields.some(
                    (field) => !!field.error && field.approved !== false
                  ) && (
                      <Flex color="red.600" alignItems="center" mt="2">
                        <Icon name="warning-2" mr="2" />
                        <Text fontSize="sm" as="span">
                          <FormattedMessage
                            tagName="span"
                            id="productsModification.checkRedFields"
                            defaultMessage="Check the fields that are marked in red"
                          />
                        </Text>
                      </Flex>
                    )}
                </Flex>
              </CardFooter>
            </>
          ) : (
            <Box p="3">
              <FormattedMessage
                id="product.noProductFound"
                defaultMessage={'Product with id "{id}"  wasnt found'}
                values={{ id: productId }}
              />
            </Box>
          )}
        </HandleQuery>
      </Card>
    </>
  );
};

export default ProductModification;
