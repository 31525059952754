import React, { useState, useContext, useEffect } from "react";
import { ValueType } from "react-select";
import { CodeListItem } from "@pravda/gdsn-admin-backend/lib/types/code-list";
// eslint-disable-next-line
import { isEqual } from "lodash";

import { FormControl, FormLabel } from "components/primitives/FormControl";

import { SelectOption } from "components/primitives/Select";
import { useIntl, FormattedMessage } from "react-intl";
import messages from "translations/commonMessages";
import { Box, Heading, Checkbox } from "@chakra-ui/core";
import CodeListSelect from "components/CodeListSelect";
import GlobalStateContext from "globalStateContext";

export type SelectedFilters = {
  productGroups: CodeListItem[];
  purchaseGroups: CodeListItem[];
};

export type ProductFiltersProps = {
  onChange: (filters: SelectedFilters | undefined, hideDataMissingProducts: boolean) => void;
  initialFilters?: SelectedFilters;
};

const codeListIds = {
  productGroupCodeListId: "product_groups",
  purchaseGroupCodeListId: "purchasing_groups",
};

const ProductFilters = ({ onChange, initialFilters }: ProductFiltersProps) => {
  const intl = useIntl();
  const globalState = useContext(GlobalStateContext);

  const [selectedFilters, setSelectedFilters] = useState<SelectedFilters>();
  const [hideDataMissingProducts, setHideDataMissingProducts] = useState<boolean>(false);

  useEffect(() => {
    if (!isEqual(selectedFilters, initialFilters)) {
      setSelectedFilters(initialFilters);
    }
  }, [globalState.currentUser, initialFilters, onChange, selectedFilters]);

  const handleProductGroupChange = (value: ValueType<SelectOption>) => {
    const newFilters: SelectedFilters = {
      purchaseGroups: selectedFilters?.purchaseGroups || [],
      productGroups: Array.isArray(value)
        ? value.map((productGroup) => ({
            code: productGroup.value,
            label: productGroup.label,
          }))
        : [],
    };
    setSelectedFilters(newFilters);
    onChange(newFilters, hideDataMissingProducts);
  };

  const handlePurchaseGroupChange = (value: ValueType<SelectOption>) => {
    const newFilters: SelectedFilters = {
      productGroups: selectedFilters?.productGroups || [],
      purchaseGroups: Array.isArray(value)
        ? value.map((purchaseGroup) => ({
            code: purchaseGroup.value,
            label: purchaseGroup.label,
          }))
        : [],
    };
    setSelectedFilters(newFilters);
    onChange(newFilters, hideDataMissingProducts);
  };

  const handleHideDataMissingProductsChange = (value: boolean) => {
    setHideDataMissingProducts(value);
    onChange(selectedFilters, value);
  }

  return (
    <>
      <Box bg="green.50" p={["4", "6"]}>
        <Heading
          as="h3"
          size="sm"
          mb="2"
          color="primary.dark"
          fontWeight="normal"
        >
          <FormattedMessage
            id="filters.filterProcessingQueue"
            defaultMessage="Filter processing queue"
          />
        </Heading>
        <FormControl>
          <Checkbox 
          isChecked={hideDataMissingProducts}
          onChange={(e) => handleHideDataMissingProductsChange(e.target.checked)}>
          <FormattedMessage
            id="filters.hideDataMissingProducts"
            defaultMessage="Hide gray products"
          />
          </Checkbox>
        </FormControl>
        <FormControl>
          <FormLabel htmlFor="productGroup">
            <FormattedMessage
              id="filters.productGroup"
              defaultMessage="Product group:"
            />
          </FormLabel>
          <CodeListSelect
            codeListId={codeListIds.productGroupCodeListId}
            initialValue={(selectedFilters?.productGroups || []).map(
              (productGroup) => productGroup.code
            )}
            onChange={handleProductGroupChange}
            colorTheme="secondary"
            placeholder={intl.formatMessage(messages.selectOneOrMore)}
            isMulti
          />
        </FormControl>
        <FormControl>
          <FormLabel htmlFor="purchaseGroup">
            <FormattedMessage
              id="filters.purchaseGroup"
              defaultMessage="Purchase group:"
            />
          </FormLabel>
          <CodeListSelect
            codeListId={codeListIds.purchaseGroupCodeListId}
            initialValue={(selectedFilters?.purchaseGroups || []).map(
              (purchaseGroup) => purchaseGroup.code
            )}
            onChange={handlePurchaseGroupChange}
            colorTheme="secondary"
            placeholder={intl.formatMessage(messages.selectOneOrMore)}
            isMulti
          />
        </FormControl>
      </Box>
    </>
  );
};

export default ProductFilters;
