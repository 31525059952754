import React from "react";
import styled from "styled-components/macro";
import { Box, BoxProps } from "@chakra-ui/core";

interface CardProps extends BoxProps {
  active?: boolean;
}
const StyledCardHeader = styled(Box)<BoxProps>``;

const Card = styled(Box)<BoxProps>``;
const CardSubHeader = styled(Box)<BoxProps>``;
const CardContent = styled(Box)<BoxProps>``;
const CardFooter = styled(Box)<BoxProps>`
  &::after {
    content: "";
    display: block;
    clear: both;
  }
`;

const CardHeader = (props: CardProps) => {
  let someProps = {};
  if (props.active) {
    someProps = {
      bg: "primary.regular",
      color: "white",
    };
  }
  const changedProps = { ...props, ...someProps };
  return <StyledCardHeader {...changedProps} />;
};

CardHeader.defaultProps = {
  p: "2",
  paddingLeft: "6",
  paddingRight: "4",
  background: "white",
  borderBottomColor: "gray.100",
  borderBottomWidth: "1px",
  boxShadow: "md",
  borderTopLeftRadius: "sm",
  borderTopRightRadius: "sm",
  position: "sticky",
  top: "50px",
  zIndex: 101,
};

CardSubHeader.defaultProps = {
  py: "4",
  px: "6",
  bg: "gray.50",
  boxShadow: "md",
  lineHeight: "short",
  fontSize: "xs",
  position: "sticky",
  top: "99px",
  zIndex: 101,
};

CardFooter.defaultProps = {
  py: "6",
  mx: "6",
  my: "4",
  marginTop: "3",
  borderTopColor: "gray.100",
  borderTopWidth: "1px",
  borderBottomLeftRadius: "sm",
  borderBottomRightRadius: "sm",
};

Card.defaultProps = {
  borderRadius: "4px",
  boxShadow: "md",
  background: "white",
};

CardContent.defaultProps = {
  py: { base: "4", md: "6" },
  px: { base: "4", md: "6" },
  background: "white",
  borderBottomLeftRadius: "sm",
  borderBottomRightRadius: "sm",
};

export { Card, CardHeader, CardSubHeader, CardContent, CardFooter };
